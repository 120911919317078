import React from 'react';
import ppt_icon from 'images/ppt_icon.svg';
import word_icon from 'images/microsoft_word_icon_20x20.svg';
import PropTypes from 'prop-types';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import { ReactComponent as UploadIcon } from 'images/icons/arrow_upload-20x20-regular.svg';

export const ComputerImportCard = (props) => {
  const loadingClass = props.isLoading ? 'is-loading' : '';
  const isDoc = props.type && props.type === 'documents';
  const icon = isDoc ? word_icon : ppt_icon;
  const description = isDoc
    ? 'Select Word document from Microsoft 365 or upload from your computer'
    : 'Select PowerPoint from Microsoft 365 or upload from your computer';
  const filters = isDoc ? ['.doc', '.docx'] : ['.ppt', '.pptx'];
  const accept = isDoc ? '.doc,.docx' : '.ppt,.pptx';
  return (
    <div>
      <img src={icon}></img>
      <p>{description}</p>
      <span>
        <MicrosoftPicker
          className="inline-flex mrm"
          onPicked={props.handle365SelectFile}
          filters={filters}
          isLoading={props.is365Loading}
        />
        <label className={`button is-secondary ${loadingClass}`}>
          <UploadIcon className="import-icon" />
          Upload
          <input
            className="template-file-picker-input"
            type="file"
            name="file"
            onChange={props.handleSelectFile}
            accept={accept}
          />
        </label>
      </span>
    </div>
  );
};

ComputerImportCard.propTypes = {
  handleSelectFile: PropTypes.func,
  handle365SelectFile: PropTypes.func,
  isLoading: PropTypes.bool,
  is365Loading: PropTypes.bool,
  percentCompleted: PropTypes.number,
  processingStatus: PropTypes.string,
  type: PropTypes.string,
};
