import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TabHeader from '../../producer/templates/TabHeader';
import { ReactComponent as Desktop } from '../../../svg/desktop.svg';
import { ReactComponent as MobilePhone } from '../../../svg/mobile_phone.svg';
import { ReactComponent as TextT } from '../../../svg/text_t.svg';
import Button from '../../lib/Button';
import data from '../../../images/icons/data-20x20-regular.svg';
import img from '../../../images/icons/image-20x20-regular.svg';
import { UserContext } from '../../UserContext';

function HtmlPreview(props) {
  const [currentTab, setCurrentTab] = useState('desktop');

  const user = useContext(UserContext);

  const onTabClick = (e, tabName) => {
    e.preventDefault();
    setCurrentTab(tabName);
  };

  const tabs = [
    {
      icon: <Desktop />,
      label: 'Desktop',
      selected: currentTab === 'desktop',
      onClick: (e) => onTabClick(e, 'desktop'),
    },
    {
      icon: <MobilePhone />,
      label: 'Mobile',
      selected: currentTab === 'mobile',
      onClick: (e) => onTabClick(e, 'mobile'),
    },
    {
      icon: <TextT />,
      label: 'Plain Text',
      selected: currentTab === 'plainText',
      onClick: (e) => onTabClick(e, 'plainText'),
    },
  ];

  let previewWidth = '1000px';
  if (currentTab === 'mobile') {
    previewWidth = '500px';
  } else if (currentTab === 'plainText') {
    previewWidth = '800px';
  }

  const frameClass = currentTab === 'mobile' ? 'mobile' : '';

  const emailHtml = props.emailHtml;

  let sourceText;
  if (currentTab === 'plainText') {
    emailHtml.replace(/(<([^>]+)>)/g, '');
  } else {
    sourceText = emailHtml;
    const doc = new DOMParser().parseFromString(sourceText, 'text/html');
    const images = doc.querySelectorAll('img');
    for (let image of images) {
      let placeholderType;
      let placeholderIcon;
      if (image.parentNode.dataset?.moduleType === 'chart') {
        placeholderType = 'Chart';
        placeholderIcon = data;
      } else {
        placeholderType = 'Image';
        placeholderIcon = img;
      }
      const alignment = image.parentNode.style.textAlign;
      image.parentNode.style.display = 'flex';
      image.parentNode.style.justifyContent = alignment;

      if (placeholderType === 'Chart' || image.parentNode.dataset?.imageType === 'dynamic') {
        image.style.display = 'none';
        const placeholder = image.parentNode.appendChild(doc.createElement('div'));
        placeholder.style.width = image.width ? image.width + 'px' : image.style.width ? image.style.width : '50%';
        placeholder.style.height = image.height ? image.height + 'px' : '200px';
        placeholder.style.backgroundColor = '#EBFCF1';
        placeholder.style.textAlign = 'center';
        placeholder.style.textWrap = 'wrap';
        placeholder.style.padding = '10px';
        const phIcon = placeholder.appendChild(doc.createElement('img'));
        phIcon.src = placeholderIcon;
        phIcon.style.marginTop = '20%';
        const phText = placeholder.appendChild(doc.createElement('p'));
        phText.textContent = 'Dynamic ' + placeholderType;
        const phDCText = placeholder.appendChild(doc.createElement('p'));
        phDCText.style.overflow = 'hidden';
        phDCText.style.textOverflow = 'ellipsis';
        if (user?.user?.roles?.includes('producer')) {
          phDCText.textContent = image.getAttribute('src');
        } else {
          phDCText.textContent = `This ${placeholderType} will populate based on your input selections.`;
        }
      }
    }

    sourceText = doc.body.outerHTML;
  }

  return (
    <div className="html-preview-container">
      {props.hideTabs ? null : (
        <div className="html-preview-dimensions">
          <TabHeader tabs={tabs} />
          <div>
            <Button onClick={props.onTemplateTest} status={props.isPreviewLoading ? 'loading' : 'default'}>
              Preview Dynamic Content
            </Button>
          </div>
        </div>
      )}
      <div className="html-preview-view">
        <div style={{ height: props.previewHeight, width: previewWidth }}>
          <iframe
            sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            className={`html-iframe ${frameClass}`}
            srcDoc={sourceText}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

HtmlPreview.propTypes = {
  emailHtml: PropTypes.string,
  hideTabs: PropTypes.bool,
  onTemplateTest: PropTypes.func,
  previewHeight: PropTypes.string,
  isPreviewLoading: PropTypes.bool,
};

HtmlPreview.defaultProps = {
  previewHeight: '350px',
};

export default HtmlPreview;
