import React from 'react';
import PropTypes from 'prop-types';
import { AvatarSelect } from 'components/shared/FormSelect';
import team_icon from 'images/team.svg';
import { usePotentialAccessors } from 'lib/hooks/useAccess';

function AccessSelector(props) {
  const { data: accessors, isPending } = usePotentialAccessors(
    props.itemType,
    props.itemIds?.length === 1 ? props.itemIds[0] : null,
  );

  function toOption(accessor) {
    return {
      value: `${accessor.type}_${accessor.id}`,
      label: accessor.name ? accessor.name : accessor.email,
      photo_url: accessor.type == 'group' ? team_icon : accessor.photo_url,
    };
  }
  const userAccessOptions = accessors?.filter((accessor) => accessor.type === 'user')?.map(toOption);
  const groupAccessOptions = accessors
    ?.filter((accessor) => accessor.type === 'group' && accessor.id > 0)
    ?.map(toOption);
  const artificialGroupAccessOptions = accessors
    ?.filter((accessor) => accessor.type === 'group' && accessor.id <= 0)
    ?.map(toOption);

  const accessOptions = [
    { options: artificialGroupAccessOptions },
    { options: userAccessOptions },
    { options: groupAccessOptions },
  ];

  const optionSorter = (a, b) => a.label.localeCompare(b.label);
  accessOptions.forEach((opts) => opts.options?.sort(optionSorter));

  const selectedAccesses = [];
  for (let accessValue in props.accesses) {
    const accessLabel = props.accesses[accessValue];
    selectedAccesses.push({ label: accessLabel, value: accessValue });
  }
  return (
    <AvatarSelect
      className={props.className}
      options={accessOptions}
      onChange={props.onChange}
      menuPlacement={props.menuPlacement}
      styles={{
        group: (base) => ({
          ...base,
          borderBottom: '1px solid #E0E5EE', // grey-300
          marginBottom: '0',
          paddingTop: '1px',
          paddingBottom: '4px',
        }),
      }}
      isLoading={isPending}
      isDisabled={isPending}
      isMulti={true}
      placeholder={isPending ? 'Fetching User List...' : 'Enter Names or Groups to Give Access'}
      classNamePrefix="access-select"
      value={selectedAccesses}
    />
  );
}
AccessSelector.propTypes = {
  itemType: PropTypes.string,
  itemIds: PropTypes.arrayOf(PropTypes.number),
  accesses: PropTypes.object,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  producersOnly: PropTypes.bool,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default AccessSelector;
