import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bulma-components';
import Icon from '../lib/Icon';
import loading_animation from 'images/loading.gif';
import DropdownMenu from './DropdownMenu';
import TemplatesArchiveAction from '../producer/templates/TemplatesArchiveAction';
import TemplatesDeleteAction from '../producer/templates/TemplatesDeleteAction';
import Button from '../lib/Button';
import Constants from 'components/Constants';
import utils from 'lib/utils';

function TemplateCardActionDropdown(props) {
  const [active, setActive] = useState(false);
  const [linkIsLoading, setLinkIsLoading] = useState(false);

  const onTemplateDelete = (template) => {
    TemplatesDeleteAction(template, props.deleteTemplate);
  };

  const onTemplateArchive = (template) => {
    TemplatesArchiveAction(template, props.archiveTemplate);
  };

  const onShare = (template) => {
    props.openModal('accessModal', template);
  };

  const onTemplateDropdownAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.id ? e.target.id : e.target.parentNode.id;
    const value = id.split('_')[0];
    if (value === 'delete' && props.canEdit) {
      onTemplateDelete(props.currentTemplate);
    } else if (value === 'open') {
      if (
        props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        setLinkIsLoading(true);
        utils.openTemplateFileInNewTab(e, props.currentTemplate, () => {
          setLinkIsLoading(false);
        });
        return;
      }
      window.open(props.currentTemplate.url, '_blank', 'noopener,noreferrer');
    } else if (value === 'archive' && props.canEdit) {
      onTemplateArchive(props.currentTemplate);
    } else if (value === 'favorite') {
      props.onFavoriteUpdate(props.currentTemplate, !props.isFavorite);
    } else if (value === 'share') {
      onShare(props.currentTemplate);
    }
  };

  const onDropdownButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(!active);
  };

  const archiveText = props.currentTemplate.deleted ? 'Unarchive Template' : 'Archive Template';
  const archiveTip = props.currentTemplate.deleted
    ? 'Unarchive this template to show it to end users.'
    : 'Archive this template to hide it from end users.';

  return (
    <DropdownMenu
      dropdownTrigger={
        <Button category="tertiary" onClick={onDropdownButtonClick}>
          <Icon name="more_horizontal" size={20} theme="regular" />
        </Button>
      }
      isHoverable={false}
      isRight={true}
      active={active}
      onClose={() => setActive(false)}
    >
      {!props.currentTemplate.deleted && (
        <>
          <span id="favorite" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
            <Icon name="star" size={20} theme={props.isFavorite ? 'filled' : 'regular'} />
            <span className="ml-2">{props.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}</span>
          </span>
          <span id="share" href="" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
            <Icon name="share" size={20} />
            <span className="ml-2">Share Template</span>
          </span>
        </>
      )}
      {props.currentTemplate.source_type !== 'email' && (
        <span id="open" href="" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
          {linkIsLoading ? (
            <img className="w-5 h-5 m-0 p-0 border-0 bg-transparent" src={loading_animation}></img>
          ) : (
            <Icon name="open" size={20} theme="regular" />
          )}
          {props.currentTemplate.source_type === 'google_slides' ? (
            <span className="ml-2">Open in Google Slides</span>
          ) : props.currentTemplate.source_type === 'google_docs' ? (
            <span className="ml-2">Open in Google Docs</span>
          ) : props.currentTemplate.source_type === 'word_365' ? (
            <span className="ml-2">Open in Word</span>
          ) : props.currentTemplate.source_type === 'word' ? (
            <span className="ml-2">Download Word</span>
          ) : props.currentTemplate.source_type === 'powerpoint_365' ? (
            <span className="ml-2">Open in PowerPoint</span>
          ) : (
            <span className="ml-2">Download PowerPoint</span>
          )}
        </span>
      )}
      {props.canEdit && (
        <span
          id={`archive_${props.currentTemplate?.id}`}
          className="dropdown-item"
          onClick={onTemplateDropdownAction}
          data-tooltip-id="matik-tooltip"
          data-tooltip-content={archiveTip}
        >
          <Icon name="archive" size={20} />
          <span className="ml-2">{archiveText}</span>
        </span>
      )}
      {props.canEdit && (
        <>
          <Dropdown.Divider />
          <span id={`delete_${props.currentTemplate?.id}`} className="dropdown-item" onClick={onTemplateDropdownAction}>
            <Icon name="trash_can" size={20} />
            <span className="ml-2">Delete Template</span>
          </span>
        </>
      )}
    </DropdownMenu>
  );
}

TemplateCardActionDropdown.propTypes = {
  currentTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onFavoriteUpdate: PropTypes.func,
  deleteTemplate: PropTypes.func,
  archiveTemplate: PropTypes.func,
  openModal: PropTypes.func,
};

export default TemplateCardActionDropdown;
