import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from 'components/shared/modal/Modal';
import { useAccessMutator } from 'lib/hooks/useAccess';
import { closeModal } from 'redux/ui/action';
import AddAccessField from './AddAccessField';
import AccessElement from './AccessElement';

function AccessModal(props) {
  const dispatch = useDispatch();
  const { del: deleteAccesses, update: updateAccesses } = useAccessMutator();

  if (!props.item) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeModal());
  };
  const handleAdd = (accessors, permission) => {
    updateAccesses(props.item.type, [props.item.id], permission, accessors);
  };

  const canEdit = props.accesses && props.accesses.can('edit');
  const title = canEdit ? `Manage Access for '${props.item.name}'` : `'${props.item.name}' Access`;
  return (
    <Modal title={title} onClose={handleClose} show={props.show}>
      {canEdit && (
        <div className="pb-4">
          <AddAccessField itemType={props.item.type} itemIds={[props.item.id]} onAdd={handleAdd} />
        </div>
      )}

      <div>
        <div className="access-container">
          <div className="grid grid-cols-[1fr_auto_auto] items-center gap-y-2 pt-2">
            {props?.accesses?.accesses().map((access) => (
              <AccessElement
                key={access.id}
                accessor={access.accessor}
                itemType={access.item_type}
                itemIds={[access.item_id]}
                accesses={[access]}
                canEdit={canEdit}
                onUpdate={updateAccesses}
                onDelete={deleteAccesses}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
AccessModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  accesses: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default AccessModal;
