import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import InputText from './InputText';

// this component is useful for creating textboxes with embedded images
// eg a textbox with a search icon or a textbox with a clear icon
// this component can theoretically be used to embed any component in a textbox but that'll be in a future version (if needed)
function TextboxWithEmbeddedIcons({
  rightIcon,
  rightIconClickHandler,
  rightIconDisplayCondition,
  iconName,
  textboxLabel = '',
  textboxValue,
  readOnly = true,
}) {
  const rightIconExists = !!rightIcon;
  const shouldDisplayLeftIcon = !!iconName;
  const shouldDisplayRightIcon =
    (rightIconDisplayCondition === undefined || rightIconDisplayCondition === true) && rightIconExists;

  const rightIconContainer = (
    <button
      key="right-icon"
      type="button"
      className="flex items-center justify-center w-7 h-7"
      onClick={rightIconClickHandler}
    >
      {rightIcon}
    </button>
  );

  return (
    <Form.Field className="w-full is-flex flex-wrap m-0">
      <div className={`w-full is-flex ${shouldDisplayLeftIcon && 'flex-nowrap'}`}>
        <Form.Label htmlFor={textboxLabel} className="w-full">
          {textboxLabel}
        </Form.Label>
      </div>
      <div className="w-full">
        <InputText
          value={textboxValue}
          isReadOnly={readOnly}
          iconName={iconName}
          rightIcons={shouldDisplayRightIcon ? [rightIconContainer] : null}
        />
      </div>
    </Form.Field>
  );
}

TextboxWithEmbeddedIcons.propTypes = {
  rightIcon: PropTypes.object,
  rightIconClickHandler: PropTypes.func,
  rightIconDisplayCondition: PropTypes.bool,
  iconName: PropTypes.string,
  textboxLabel: PropTypes.string,
  textboxValue: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default TextboxWithEmbeddedIcons;
