import React, { Component, useState } from 'react';
import google_slides_icon from 'images/google_slides_icon.svg';
import google_docs_icon from 'images/google_docs_icon_24x24.svg';
import google_drive_logo from 'images/google_drive_logo.png';
import PropTypes from 'prop-types';
import { UserContext } from 'components/UserContext';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import API from 'lib/api';

class GoogleImportCard extends Component {
  render() {
    const loadingClass = this.props.isLoading ? 'is-loading' : '';
    const isDoc = this.props.type && this.props.type === 'documents';
    const icon = isDoc ? google_docs_icon : google_slides_icon;
    const description = isDoc
      ? 'Select Google Doc from your Google Drive'
      : 'Select Google Slides from your Google Drive';
    return (
      <div>
        <img src={icon}></img>
        <p>{description}</p>
        <AddButton
          className={`button is-secondary ${loadingClass}`}
          onTemplateAdd={this.props.onTemplateAdd}
          type={this.props.type}
        >
          <img src={google_drive_logo} className="import-icon"></img>
          Select from Google Drive
        </AddButton>
      </div>
    );
  }
}

GoogleImportCard.contextType = UserContext;

GoogleImportCard.propTypes = {
  onGoogleSlidesClick: PropTypes.func,
  auth: PropTypes.any,
  handleSelectGoogleSheet: PropTypes.func,
  isLoading: PropTypes.bool,
  oathFunction: PropTypes.func,
  percentCompleted: PropTypes.number,
  processingStatus: PropTypes.string,
  onTemplateAdd: PropTypes.func,
  type: PropTypes.string,
};

export default GoogleImportCard;

const AddButton = ({ className, children, onTemplateAdd, type }) => {
  const [isAdding, setAdding] = useState(false);
  const { create } = useTemplateMutator();

  const handleSelect = (docs) => {
    setAdding(true);

    const source_type = type === 'documents' ? 'google_docs' : 'google_slides';
    const file = docs[0];
    const templateData = {
      name: file.name,
      source_type: source_type,
      source: file,
      description: file.description,
    };

    create(templateData, file)
      .then((newTemplateData) => onTemplateAdd(newTemplateData))
      .catch(API.defaultError)
      .finally(() => setAdding(false));
  };

  if (isAdding) {
    className = `${className || ''} is-loading`;
  }
  return (
    <GoogleDrivePicker className={className} onPicked={handleSelect} view={type}>
      {children}
    </GoogleDrivePicker>
  );
};
AddButton.propTypes = {
  className: PropTypes.string,
  onTemplateAdd: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.any,
};
