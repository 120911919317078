import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import {
  ContentNameCell,
  DataSourceCell,
  DynamicContentTypeCell,
  getDataSourceName,
  DescriptionCell,
  ListShareCellFactory,
  DCTypeCell,
  DCMethodCell,
  DataSourceListCell,
  PresentationsGeneratedCell,
} from '../../shared/FullPageListCells';
import moment from 'moment';
import { UserContext } from '../../UserContext';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import AccessModal from '../../shared/AccessModal';
import AccessManager from '../../../lib/AccessManager';
import { Redirect } from 'react-router-dom';
import FullPageListWithBulkActions from '../bulkAction/FullPageListWithBulkActions';
import BulkAccessButton from '../accesses/BulkAccessButton';
import BulkTagButton from '../ItemTag/BulkTagButton';
import BulkUpdateDataSourceButton from './BulkUpdateDataSourceButton';
import BulkDeleteButton from './BulkDeleteButton';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps } from '../../../redux/ui/dispatchers';
import useAccesses from 'lib/hooks/useAccess';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PageWithFilterHeaderAndBulkActions = WithFilterListHeader(FullPageListWithBulkActions);

function DynamicContentFullPageList({
  accessesByItemId,
  dynamicContent,
  fetchItems,
  isPaginating,
  isSearching,
  openModal,
  pagination,
  searchAttributes,
  searchState,
  ui,
}) {
  const [redirectToContentId, setRedirectToContentId] = useState(null);
  const flags = useFlags();

  const userContext = useContext(UserContext);

  const onRowClick = (contentId) => {
    setRedirectToContentId(contentId);
  };

  const showAccessModal = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    openModal('accessModal', item);
  };

  const _fetchItems = (page, sort = null) => {
    const offset = page * Constants.DYNAMIC_CONTENT_PAGE_SIZE;
    fetchItems(offset, Constants.DYNAMIC_CONTENT_PAGE_SIZE, sort);
  };

  if (redirectToContentId) {
    return <Redirect push to={`/dynamic_content/${redirectToContentId}`} />;
  }

  // We need this getMethod for sorting, because React Table sorts by row.value. If we just have a cell for this
  // column, then React Table doesn't know how to sort it.
  const getMethod = (dc) => {
    if (dc.dynamic_content_type === 'conditional') {
      return '--';
    }

    return Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[dc.dynamic_content_method]?.text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('dynamic_content_type', {
      header: 'Type',
      cell: DynamicContentTypeCell,
      meta: {
        width: '250px',
      },
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ContentNameCell,
    }),
    columnHelper.accessor(getMethod, {
      id: 'dynamic_content_method',
      header: 'Method',
      meta: {
        width: '200px',
      },
    }),
    columnHelper.accessor(getDataSourceName, {
      id: 'data_source_name',
      header: 'Data Source',
      cell: DataSourceCell,
    }),
    columnHelper.accessor(
      (row) => {
        const accessObj = new AccessManager(row.id, accessesByItemId, userContext.user);
        return accessObj.isShared() ? accessObj.bigListSharedColumnAvatars().length : 0;
      },
      {
        id: 'user_id',
        header: 'Share Status',
        cell: (info) => {
          const cellInfo = ListShareCellFactory(accessesByItemId, showAccessModal, userContext.user, 32)(info);
          return cellInfo.cell;
        },
        meta: {
          width: '300px',
        },
      },
    ),
    columnHelper.accessor(
      (d) => {
        const locale = localeDateFormatterUtils.getUserLocale();
        const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
        return moment(d.updated_on).format(localeDateFormat);
      },
      {
        id: 'updated_on',
        header: 'Last Updated',
        meta: {
          width: '250px',
        },
      },
    ),
  ];

  // FEATURE FLAGGED: Data Dictionary Columns
  const columnsDataDictionary = [
    columnHelper.accessor('dynamic_content_method', {
      id: 'dynamic_content_method_icon',
      header: '',
      cell: DCMethodCell,
      size: 46,
      enablePinning: true,
      pin: 'left',
    }),
    columnHelper.accessor('dynamic_content_type', {
      id: 'dynamic_content_type_icon',
      header: '',
      cell: DCTypeCell,
      size: 46,
      enablePinning: true,
      pin: 'left',
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ContentNameCell,
      size: 300,
      enablePinning: true,
      pin: 'left',
    }),
    columnHelper.accessor(getDataSourceName, {
      id: 'data_source_name',
      header: 'Data Source',
      cell: DataSourceListCell,
      size: 200,
    }),
    columnHelper.accessor(
      (row) => {
        const accessObj = new AccessManager(row.id, accessesByItemId, userContext.user);
        return accessObj.isShared() ? accessObj.bigListSharedColumnAvatars().length : 0;
      },
      {
        id: 'user_id',
        header: 'Share Status',
        cell: (info) => {
          const cellInfo = ListShareCellFactory(accessesByItemId, showAccessModal, userContext.user, 32)(info);
          return cellInfo.cell;
        },
        size: 175,
      },
    ),
    columnHelper.accessor('connections', {
      id: 'connections',
      header: 'Connections',
      cell: <></>,
      size: 150,
    }),
    columnHelper.accessor(
      (d) => ({
        presentationsCreated: d.presentationsCreated,
        totalPresentations: d.totalPresentations,
      }),
      {
        id: 'presentations_generated',
        header: 'Presentations Generated',
        cell: PresentationsGeneratedCell,
        size: 200,
      },
    ),
    columnHelper.accessor('description', {
      id: 'description',
      header: 'Description',
      cell: DescriptionCell,
      size: 350,
    }),
    columnHelper.accessor('tags', {
      id: 'tags',
      header: 'Tags',
      cell: <></>,
      size: 250,
    }),
    columnHelper.accessor(getMethod, {
      id: 'dynamic_content_method',
      header: 'Method',
      size: 125,
    }),
    columnHelper.accessor(
      (d) => {
        const locale = localeDateFormatterUtils.getUserLocale();
        const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
        return moment(d.last_generated).format(localeDateFormat);
      },
      {
        id: 'last_generated',
        header: 'Last Time Generated',
        size: 175,
      },
    ),
    columnHelper.accessor(
      (d) => {
        const locale = localeDateFormatterUtils.getUserLocale();
        const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
        return moment(d.updated_on).format(localeDateFormat);
      },
      {
        id: 'updated_on',
        header: 'Last Updated',
        size: 175,
      },
    ),
  ];

  return (
    <div className="below-header dynamic-content-list data-dictionary">
      <PageWithFilterHeaderAndBulkActions
        accessesByItemId={accessesByItemId}
        bulkActionComponents={bulkActionComponents}
        buttonClass="is-primary"
        columns={flags.enableDataDictionary ? columnsDataDictionary : columns}
        context="dynamic_content"
        displayFilterHeader={true}
        entities={dynamicContent}
        fetchItems={_fetchItems}
        filterPlaceholder="Search Dynamic Content"
        isSearching={isSearching}
        loading={isPaginating}
        newEntityUrl="/dynamic_content/new"
        onRowClick={onRowClick}
        pagination={pagination}
        searchAttributes={searchAttributes}
        searchState={searchState}
        title="Your Dynamic Content"
        entityType="dynamic_content"
        hasStickyColumns={flags.enableDataDictionary}
      />
      <AccessModal
        accesses={new AccessManager(ui?.modal?.content?.id, accessesByItemId, userContext.user)}
        item={ui?.modal?.name === 'accessModal' ? ui.modal.content : null}
        show={ui?.modal?.name === 'accessModal'}
      />
    </div>
  );
}

DynamicContentFullPageList.propTypes = {
  accessesByItemId: PropTypes.object,
  dynamicContent: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  openModal: PropTypes.func,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  ui: PropTypes.object,
};

function DynamicContentFullPageListWrapper(props) {
  const { data: accessesByItemId } = useAccesses('dynamic_content');
  return <DynamicContentFullPageList {...props} accessesByItemId={accessesByItemId} />;
}

const bulkActionComponents = [
  (_props) => {
    return (
      <BulkAccessButton
        key="access"
        selectedItemIds={_props.selectedItems.map((dc) => dc.id)}
        entityType="dynamic_content"
      />
    );
  },
  (_props) => {
    return (
      <BulkTagButton
        key="itemTag"
        selectedItemIds={_props.selectedItems.map((dc) => dc.id)}
        entityType="dynamic_content"
      />
    );
  },
  (_props) => {
    return <BulkUpdateDataSourceButton key="dataSource" selectedDynamicContent={_props.selectedItems} />;
  },
  (_props) => {
    return <BulkDeleteButton key="delete" selectedItemIds={_props.selectedItems.map((dc) => dc.id)} />;
  },
];

export default connect(mapUiStateToProps, mapDispatchToProps)(DynamicContentFullPageListWrapper);
