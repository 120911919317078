import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';

const ReactTableActionDropdown = ({
  onSortFunc,
  sortedState,
  expandedState,
  setExpandedStateFunc,
  headerId,
  headerRef,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (headerRef) {
      const rect = headerRef.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [headerRef]);

  const handleAction = (action) => {
    if (action.onClick) {
      action.onClick();
    }
  };

  const defaultActions = [
    {
      label: 'Sort ascending',
      icon: <Icon name="arrow_up" size={16} />,
      onClick: () => onSortFunc(headerId, 'asc'),
      id: 'asc',
    },
    {
      label: 'Sort descending',
      icon: <Icon name="arrow_down" size={16} />,
      onClick: () => onSortFunc(headerId, 'desc'),
      id: 'desc',
    },
  ];

  if (headerId === 'description') {
    defaultActions.push({
      label: expandedState ? 'Collapse Descriptions' : 'Expand Descriptions',
      icon: expandedState ? <Icon name="text_collapse" size={16} /> : <Icon name="text_expand" size={16} />,
      onClick: () => setExpandedStateFunc(!expandedState),
      id: 'expand',
    });
  }

  return (
    <>
      <div className="modal is-active h-screen w-screen cursor-default" />
      <div
        className="fixed z-50 py-2 w-56 bg-matik-white rounded shadow-md border border-grey-300"
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
      >
        <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          {defaultActions.map((action, index) => (
            <button
              key={index}
              className={`flex items-center px-4 py-2 text-sm w-full text-left text-matik-black ${
                sortedState?.headerId === headerId && sortedState?.sort === action.id
                  ? 'bg-grey-100 hover:bg-grey-200'
                  : 'hover:bg-grey-200'
              }`}
              role="menuitem"
              onClick={() => handleAction(action)}
            >
              <div className="flex gap-3 items-center">
                {action.icon && action.icon}
                {action.label}
              </div>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

ReactTableActionDropdown.propTypes = {
  onSortFunc: PropTypes.func,
  sortedState: PropTypes.string,
  expandedState: PropTypes.bool,
  setExpandedStateFunc: PropTypes.func,
  headerId: PropTypes.string,
  headerRef: PropTypes.object,
};

export default ReactTableActionDropdown;
