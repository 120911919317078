import React, { useEffect } from 'react';
import { Element, useNode, useEditor } from '@craftjs/core';
import PropTypes from 'prop-types';
import ColumnContainer from './ColumnContainer';
import { forEach } from 'lodash';

function Column({ children, style, ...rest }) {
  const {
    connectors: { connect },
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
  }));

  return (
    <div ref={(dom) => connect(dom)} className="is-transparent">
      <Element is={ColumnContainer} id={'Column'} style={style} {...rest} canvas>
        {children}
      </Element>
    </div>
  );
}

Column.craft = {
  rules: {
    canDrop: () => false,
    canDrag: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: ColumnSettings,
  },
};

Column.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object,
};

function ColumnSettings() {
  const {
    actions: { setHidden },
    id,
  } = useNode((node) => ({
    id: node.id,
  }));

  const columnStyleSettings = [
    {
      // 1
      id: 0,
      styles: [{ hidden: false }, { hidden: true }, { hidden: true }, { hidden: true }],
    },
    {
      // 1 : 1
      id: 1,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 1 : 1 : 1
      id: 2,
      styles: [{ hidden: false }, { hidden: false }, { hidden: false }, { hidden: true }],
    },
    {
      // 1 : 1 : 1 : 1
      id: 3,
      styles: [{ hidden: false }, { hidden: false }, { hidden: false }, { hidden: false }],
    },
    {
      // 1 : 2
      id: 4,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 2 : 1
      id: 5,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 1 : 3
      id: 6,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 3 : 1
      id: 7,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 1 : 4
      id: 8,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
    {
      // 4 : 1
      id: 9,
      styles: [{ hidden: false }, { hidden: false }, { hidden: true }, { hidden: true }],
    },
  ];

  const { childNodeList, columnNumber, parentColumnModule, state } = useEditor((state, query) => {
    let childNodeList = [];
    let columnNumber = 0;
    let parentColumnModule;

    const childNodes = query.node(id).descendants();
    const allColumns = query.node(query.node(id).ancestors()[0]).descendants();
    parentColumnModule = state.nodes[query.node(query.node(id).ancestors()[0]).ancestors()[0]];
    columnNumber = allColumns.findIndex((column) => column === id) + 1;

    forEach(childNodes, (childNode) => {
      childNodeList.push(state.nodes[childNode]);
    });

    return {
      childNodeList,
      columnNumber,
      parentColumnModule,
      state,
    };
  });

  useEffect(() => {
    const columnCssSetting = columnStyleSettings[parentColumnModule.data.props.buttonId];
    setHidden(columnCssSetting.styles[columnNumber - 1].hidden);
  }, [parentColumnModule.data.props.buttonId]);

  return (
    <>
      {childNodeList.map((childNode) => {
        const parentNodeId = state.nodes[childNode.id].data.parent;
        return (
          state.nodes?.[parentNodeId] &&
          !state.nodes[parentNodeId].data?.hidden && (
            <div key={`column-${columnNumber}`} className="column-settings-container">
              <p className="modules-header">Column {columnNumber} Settings</p>
              {React.createElement(childNode.related?.settings)}
            </div>
          )
        );
      })}
    </>
  );
}

export default Column;
