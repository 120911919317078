import Constants from 'components/Constants';

import AthenaForm from 'components/producer/dataSources/forms/AthenaForm';
import CRMOauthForm from 'components/producer/dataSources/forms/CRMOauthForm';
import DatabricksForm from 'components/producer/dataSources/forms/DatabricksForm';
import ExcelForm from 'components/producer/dataSources/forms/ExcelForm';
import GainsightForm from 'components/producer/dataSources/forms/GainsightForm';
import GenericDatabaseForm from 'components/producer/dataSources/forms/GenericDatabaseForm';
import GoogleBQOauthForm from 'components/producer/dataSources/forms/GoogleBQOauthForm';
import GoogleSheetForm from 'components/producer/dataSources/forms/GoogleSheetForm';
import LookerForm from 'components/producer/dataSources/forms/LookerForm';
import ProductboardForm from 'components/producer/dataSources/forms/ProductboardForm';
import RestForm from 'components/producer/dataSources/forms/RestForm';
import SnowflakeDatabaseForm from 'components/producer/dataSources/forms/SnowflakeDatabaseForm';
import TableauForm from 'components/producer/dataSources/forms/TableauForm';

import AthenaAuth from 'components/producer/dataSources/forms/AthenaAuth';
import DatabricksAuth from 'components/producer/dataSources/forms/DatabricksAuth';
import GainsightAuth from 'components/producer/dataSources/forms/GainsightAuth';
import GenericOauthForm from 'components/producer/dataSources/forms/GenericOauthForm';
import LookerAuth from 'components/producer/dataSources/forms/LookerAuth';
import { PasswordAuthWithLoginTab } from 'components/producer/dataSources/forms/PasswordAuth';
import ProductboardAuth from 'components/producer/dataSources/forms/ProductboardAuth';
import RestAuth from 'components/producer/dataSources/forms/RestAuth';
import SnowflakeAuth from 'components/producer/dataSources/forms/SnowflakeAuth';
import TableauAuth from 'components/producer/dataSources/forms/TableauAuth';

const dataSourceTypeForm = {
  api: RestForm,
  athena: AthenaForm,
  databricks: DatabricksForm,
  excel: ExcelForm,
  gainsight: GainsightForm,
  googlebq: GoogleBQOauthForm,
  google_sheet: GoogleSheetForm,
  hubspot: CRMOauthForm,
  looker: LookerForm,
  mssql: GenericDatabaseForm,
  mysql: GenericDatabaseForm,
  postgresql: GenericDatabaseForm,
  presto: GenericDatabaseForm,
  productboard: ProductboardForm,
  redshift: GenericDatabaseForm,
  salesforce: CRMOauthForm,
  snowflake: SnowflakeDatabaseForm,
  tableau: TableauForm,
};

const dataSourceAuthForm = {
  api: RestAuth,
  athena: AthenaAuth,
  databricks: DatabricksAuth,
  gainsight: GainsightAuth,
  googlebq: GenericOauthForm,
  google_sheet: GenericOauthForm,
  hubspot: GenericOauthForm,
  looker: LookerAuth,
  mssql: PasswordAuthWithLoginTab,
  mysql: PasswordAuthWithLoginTab,
  postgresql: PasswordAuthWithLoginTab,
  presto: PasswordAuthWithLoginTab,
  productboard: ProductboardAuth,
  redshift: PasswordAuthWithLoginTab,
  salesforce: GenericOauthForm,
  snowflake: SnowflakeAuth,
  tableau: TableauAuth,
};

const dataSourceTypeCanBeOAuth = {
  api: true,
  athena: false,
  databricks: false,
  excel: false,
  gainsight: false,
  googlebq: true,
  google_sheet: true,
  hubspot: true,
  looker: false,
  mssql: false,
  mysql: false,
  postgresql: false,
  presto: false,
  productboard: false,
  redshift: false,
  salesforce: true,
  snowflake: true,
  tableau: false,
};

const dataSources = {
  getDataSourceTypeForm: (dataSourceType) => {
    return dataSourceTypeForm[dataSourceType] ?? null;
  },

  getDataSourceAuthForm: (dataSourceType) => {
    return dataSourceAuthForm[dataSourceType] ?? null;
  },

  isOAuth: (dataSourceType, auth = {}) => {
    const canBeOAuth = dataSourceTypeCanBeOAuth[dataSourceType];
    if (canBeOAuth) {
      if (
        [Constants.DATA_SOURCE_TYPES.api, Constants.DATA_SOURCE_TYPES.snowflake].includes(dataSourceType) &&
        !auth.access_token &&
        !auth.client_id
      ) {
        return false;
      } else if (Constants.DATA_SOURCE_TYPES.googlebq === dataSourceType && auth.service_account_info) {
        return false;
      }
    }
    return canBeOAuth;
  },

  generateSOQLQueryString: (query) => {
    if (!query) {
      return '';
    }

    const queryObj = JSON.parse(query);
    const returnFieldsByName = queryObj.returnFieldsByName || {};
    const queryFilters = queryObj.filters || [];
    const fieldsToReturn = Object.keys(returnFieldsByName)
      .filter((fieldName) => returnFieldsByName[fieldName])
      .map((fieldName) => (queryObj.apply_formatting ? `FORMAT(${fieldName})` : fieldName))
      .join(', ');
    const filters = queryFilters.reduce((filterString, filter) => {
      let filterToAdd = '';
      if (filterString.length > 0) {
        filterToAdd += ' AND ';
      } else {
        filterToAdd += ' WHERE ';
      }
      const filterObjName = filter['object'] ? filter['object'][0] : queryObj.selectedObject;
      let filterVal = filter['value'];
      // TODO explicitly handle filter types
      if (filter['type'] !== 'boolean') {
        if (isNaN(filter['value']) && filter['value'].indexOf('&:') < 0) {
          if (filter['type'] === 'datetime') {
            // Don't quote date literal keywords. Also don't quote ISO date strings.
            const dateLiteralValueCheck = filter['value'].split(':')[0];
            if (
              !Constants.SUPPORTED_DATE_LITERALS_BY_DATASOURCE.salesforce.includes(dateLiteralValueCheck) &&
              filterVal.toLowerCase() !== 'null'
            ) {
              filterVal = filter['value'];
            }
          } else if (filter['operator'] !== 'IN' && filter['operator'] !== 'NOT IN') {
            filterVal = `'${filter['value']}'`;
          }
        }
      }

      let operator = filter['operator'] ? filter['operator'] : '=';
      if (operator === 'BETWEEN') {
        if (filter['type'] === 'datetime') {
          filterToAdd += `DAY_ONLY(${filterObjName}.${filter['field']}) ${filterVal}`;
        } else {
          filterToAdd += `${filterObjName}.${filter['field']} ${filterVal}`;
        }
      } else if (operator === 'IN' || operator === 'NOT IN') {
        filterToAdd += `${filterObjName}.${filter['field']} ${operator} (${filterVal})`;
      } else if (filter['type'] === 'datetime') {
        filterToAdd += `DAY_ONLY(${filterObjName}.${filter['field']}) ${operator} ${filterVal}`;
      } else {
        filterToAdd += `${filterObjName}.${filter['field']} ${operator} ${filterVal}`;
      }

      return filterString + filterToAdd;
    }, '');

    const from = `FROM ${queryObj.selectedObject}`;
    let orderBy = '';
    if (queryObj.orderByField) {
      let orderByObject = queryObj.selectedObject;
      if (queryObj.orderByObject && queryObj.orderByObject[0]) {
        orderByObject = queryObj.orderByObject[0];
      }
      orderBy = ` ORDER BY ${orderByObject}.${queryObj.orderByField} ${
        queryObj.orderByDirection ? queryObj.orderByDirection : 'ASC'
      }`;
    } else if (queryObj.orderByDirection) {
      orderBy = ` ORDER BY Id ${queryObj.orderByDirection}`;
    }

    const limit = queryObj.limit ? ` LIMIT ${queryObj.limit}` : '';
    return `SELECT ${fieldsToReturn} ${from} ${filters} ${orderBy} ${limit}`;
  },

  buildCRMReturnFields: (apiInfo, resourceType, selectedResource, fieldsType) => {
    let returnFields = {};
    const selectedResourceData = apiInfo[resourceType][selectedResource];
    const fields = selectedResourceData[fieldsType];

    fields?.forEach((field) => {
      // Add top level fields for object
      returnFields[field.fieldName || field.name] = {
        name: field.fieldName || field.name,
        label: field.label,
        lookupFields: field.lookupFields || null,
        dataType: field.dataType,
      };

      // Add sub fields for Gainsight
      if (field.lookupFields) {
        field.lookupFields.forEach((lookupField) => {
          const qualifiedFieldName = `${field.fieldName}.${lookupField.fieldName}`;
          returnFields[qualifiedFieldName] = {
            name: qualifiedFieldName,
            label: qualifiedFieldName,
            lookupFields: null,
            dataType: lookupField.dataType,
          };
        });
      }
    });

    // Add sub fields for Salesforce
    if (selectedResourceData.relationships) {
      selectedResourceData.relationships.forEach((relationship) => {
        if (!relationship[1] || relationship[1].length === 0) {
          return;
        }
        if (apiInfo.objects[relationship[1][0]]?.fields) {
          apiInfo.objects[relationship[1][0]]['fields'].forEach((field) => {
            returnFields = dataSources.addRelationshipData(field, relationship, returnFields);
          });
        }
        if (relationship[1].length > 1 && apiInfo.objects[relationship[1][1]]?.fields) {
          apiInfo.objects[relationship[1][1]]['fields'].forEach((field) => {
            returnFields = dataSources.addRelationshipData(field, relationship, returnFields);
          });
        }
      });
    }

    return returnFields;
  },

  addRelationshipData: (field, relationship, returnFieldMapping) => {
    const fieldName = field.name;
    const qualifiedFieldName = `${relationship[0]}.${fieldName}`;
    returnFieldMapping[qualifiedFieldName] = {
      name: qualifiedFieldName,
      label: qualifiedFieldName,
      lookupFields: null,
    };

    return returnFieldMapping;
  },

  getPublicDataSourceSelectOptions: () => {
    return Object.values(Constants.DATA_SOURCE_TYPES)
      .filter(
        (option) =>
          option !== Constants.DATA_SOURCE_TYPES.matik_logos &&
          option !== Constants.DATA_SOURCE_TYPES.matik_ai &&
          option !== Constants.DATA_SOURCE_TYPES.matik_auto_insights &&
          Constants.DATA_SOURCE_TYPE_DISPLAY[option],
      )
      .map((option) => ({
        value: option,
        label: Constants.DATA_SOURCE_TYPE_DISPLAY[option].text,
        icon: Constants.DATA_SOURCE_TYPE_DISPLAY[option].icon,
      }))
      .sort((dataSourceA, dataSourceB) => {
        if (dataSourceA.label < dataSourceB.label) {
          return -1;
        }
        if (dataSourceA.label > dataSourceB.label) {
          return 1;
        }
        return 0;
      });
  },

  categorizeDataSourceSelectOptions: (dataSourceSelectOptions) => {
    const categorizedDataSources = [];
    const categories = Object.keys(Constants.DATA_SOURCE_CATEGORIES);

    for (const category of categories) {
      const { title, dataSources } = Constants.DATA_SOURCE_CATEGORIES[category];
      const filteredDataSources = dataSourceSelectOptions.filter((dataSource) =>
        dataSources.includes(dataSource.value),
      );
      if (filteredDataSources.length > 0) {
        categorizedDataSources.push({
          label: title,
          options: filteredDataSources,
        });
      }
    }
    return categorizedDataSources;
  },

  createReturnFieldLabel: (queryObj, fieldName, returnFields, toggleRenameModal, isFixed) => {
    let fieldLabel = returnFields?.[fieldName]?.label || fieldName;
    let displayName = returnFields?.[fieldName]?.displayName;
    let dataType = returnFields?.[fieldName]?.dataType;
    if (queryObj?.returnFieldMapping?.[fieldName]) {
      fieldLabel = queryObj?.returnFieldMapping?.[fieldName].label;
      displayName = queryObj?.returnFieldMapping?.[fieldName]?.displayName;
    }
    const returnLabel = {
      label: fieldLabel,
      value: fieldName,
      displayName: displayName,
      toggleRenameModal: toggleRenameModal,
      dataType: dataType,
    };
    if (isFixed !== undefined) {
      returnLabel.isFixed = isFixed;
    }

    return returnLabel;
  },
};

export default dataSources;
