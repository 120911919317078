import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ppt_icon from '../../images/ppt_icon.svg';
import ppt_icon_sm from '../../images/ppt_icon_sm.svg';
import google_slides_icon from '../../images/google_slides_icon.svg';
import google_slides_icon_sm from '../../images/google_slides_icon_sm.svg';
import google_docs_icon_sm from '../../images/google_docs_icon_16x16.svg';
import email_icon_sm from '../../images/email_icon_sm.svg';
import word_icon from 'images/microsoft_word_icon_24x24.svg';
import word_icon_sm from 'images/microsoft_word_icon_16x16.svg';
import Constants from 'components/Constants';

class SourceTypeImage extends Component {
  render() {
    if (
      this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
      this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365
    ) {
      return (
        <div>
          <img
            src={this.props.size && this.props.size === 'sm' ? ppt_icon_sm : ppt_icon}
            width={this.props.width}
            alt="PowerPoint"
            className={this.props.cssClassName}
          />
        </div>
      );
    } else if (this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES) {
      return (
        <div>
          <img
            src={this.props.size && this.props.size === 'sm' ? google_slides_icon_sm : google_slides_icon}
            width={this.props.width}
            alt="Google Slides"
            className={this.props.cssClassName}
          />
        </div>
      );
    } else if (this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS) {
      return (
        <div>
          <img
            src={google_docs_icon_sm}
            width={this.props.width}
            alt="Google Docs"
            className={this.props.cssClassName}
          />
        </div>
      );
    } else if (this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      return (
        <div>
          <img
            src={this.props.size && this.props.size === 'sm' ? email_icon_sm : email_icon_sm}
            width={this.props.width}
            alt="Email"
            className={this.props.cssClassName}
          />
        </div>
      );
    } else if (
      this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.WORD ||
      this.props.sourceType === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      return (
        <div>
          <img
            src={this.props.size && this.props.size === 'sm' ? word_icon_sm : word_icon}
            width={this.props.width}
            alt="Word"
            className={this.props.cssClassName}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

SourceTypeImage.propTypes = {
  sourceType: PropTypes.string,
  width: PropTypes.string,
  cssClassName: PropTypes.string,
  size: PropTypes.string,
};

export default SourceTypeImage;
