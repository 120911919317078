import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';

import Constants from 'components/Constants';
import AccessManager from 'lib/AccessManager';

import AccessSelector from 'components/shared/AccessSelector';
import { MenuNoSearch, Select } from 'components/shared/FormSelect';
import Button from 'components/lib/Button';

function AddAccessField({ onAdd, itemIds, itemType }) {
  const [permission, setPermission] = useState(Constants.PERMISSIONS.read);
  const [accessors, setAccessors] = useState({});

  const onPermissionChange = (obj, action) => {
    if (action.action === 'select-option') {
      const permission = Constants.PERMISSIONS[obj.value];
      setPermission(permission);
    }
  };

  const onUserSelectChange = (obj, action) => {
    if (action.action === 'select-option') {
      setAccessors({ ...accessors, [action.option.value]: action.option.label });
    } else if (action.action === 'select-all-options') {
      const update = { ...accessors };
      action.option.forEach((option) => {
        update[option.value] = option.label;
      });
      setAccessors(update);
    } else if (action.action === 'remove-value') {
      const update = { ...accessors };
      delete update[action.removedValue.value];
      setAccessors(update);
    } else if (action.action === 'clear') {
      setAccessors({});
    }
  };

  const addAccesses = (e) => {
    e.preventDefault();

    const accesses = Object.keys(accessors).map((accessorKey) => {
      const accessor = AccessManager.parseAccessorKey(accessorKey);
      return { accessor_type: accessor.type, accessor_id: accessor.id };
    });

    setAccessors({});
    onAdd(accesses, permission.value);
  };

  let accessorButtonText;
  let accessorButtonDisabled;
  const accessorListLength = Object.keys(accessors).length;

  if (accessorListLength > 0) {
    accessorButtonText = `Add ${Pluralize('Accessor', accessorListLength, true)}`;
    accessorButtonDisabled = false;
  } else {
    accessorButtonText = 'Add Accessor';
    accessorButtonDisabled = true;
  }

  return (
    <div className="flex gap-2.5 items-center">
      <AccessSelector
        className="grow"
        onChange={onUserSelectChange}
        menuPlacement="auto"
        accesses={accessors}
        itemType={itemType}
        itemIds={itemIds}
      />
      <Select
        className="w-[120px]"
        componentsToAdd={{ Menu: MenuNoSearch }}
        onChange={onPermissionChange}
        classNamePrefix="matik-select"
        value={permission}
        isSearchable={false}
        options={Object.values(Constants.PERMISSIONS)
          .filter((p) => {
            return p.value !== 'owner';
          })
          .map((p) => ({
            value: p.value,
            label: p.label,
          }))}
      />
      <Button
        type="button"
        onClick={addAccesses}
        category="primary"
        status={accessorButtonDisabled ? 'disabled' : null}
      >
        <span className="whitespace-nowrap">{accessorButtonText}</span>
      </Button>
    </div>
  );
}
AddAccessField.propTypes = {
  itemType: PropTypes.string,
  itemIds: PropTypes.arrayOf(PropTypes.number),
  onAdd: PropTypes.func,
};

export default AddAccessField;
