import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import MatikStorageProviderSelect from 'components/shared/MatikStorageProviderSelect';
import Button from 'components/lib/Button';

// This component is similar with IntegrationFolderSelectorWithMatikSelect
// The main difference is the use of MatikSelect instead of IconSelect (which is based on react-select)
// The ultimate goal is to replace react-select with MatikSelect
function IntegrationFolderSelectorWithMatikSelect({
  currentFolder = {},
  onFolderSelect = () => {},
  placeholder = 'Select a Folder',
  isEnabled = true,
  onFolderDelete,
}) {
  const emptyFolderTemplate = {
    folder_name: placeholder,
    source_type: 'temp',
  };
  const microsoftRef = useRef(null);
  const googleRef = useRef(null);
  const current = currentFolder?.folder_name || placeholder;
  const handleOpenFolderPicker = () => {
    if (!isEnabled) {
      return;
    }
    if (currentFolder?.source_type == 'microsoft') {
      microsoftRef.current?.click();
    } else if (currentFolder?.source_type == 'google') {
      googleRef.current?.click();
    }
  };
  const handleFolderProviderSelect = (result) => {
    if (!isEnabled) {
      return;
    }

    const isSwitchingProvider =
      (result.value === 'microsoft' && currentFolder?.source_type === 'google') ||
      (result.value === 'google' && currentFolder?.source_type === 'microsoft') ||
      currentFolder?.source_type === 'temp';

    if (isSwitchingProvider) {
      onFolderSelect({ ...emptyFolderTemplate, source_type: result.value });
    }
  };

  const onMicrosoftFolderSelect = (e) => {
    const result = e.items[0];
    const folder = {
      source_type: 'microsoft',
      folder_id: result?.id,
      drive_id: result?.parentReference?.driveId,
      folder_name: result?.name,
    };
    onFolderSelect(folder);
  };
  const onGoogleFolderSelect = (e) => {
    const result = e[0];
    const folder = {
      source_type: 'google',
      folder_id: result?.id,
      folder_name: result?.name,
    };
    onFolderSelect(folder);
  };

  const deleteFolderFromGroup = () => {
    onFolderDelete(currentFolder);
  };

  return (
    <div className="flex w-full gap-x-3">
      <div className="w-72">
        <MatikStorageProviderSelect value={currentFolder} onChange={handleFolderProviderSelect} isEnabled={isEnabled} />
      </div>
      <TextboxWithEmbeddedIcons
        readOnly={!isEnabled}
        textboxValue={current}
        rightIcon={
          isEnabled && currentFolder?.source_type !== 'temp' ? (
            <Icon name="chevron_right" size={16} theme="filled" />
          ) : null
        }
        rightIconClickHandler={handleOpenFolderPicker}
      />
      <MicrosoftPicker
        className="hidden"
        onPicked={onMicrosoftFolderSelect}
        pickerMode="folders"
        buttonRef={microsoftRef}
      />
      <GoogleDrivePicker
        className="hidden"
        onPicked={onGoogleFolderSelect}
        view="folders"
        permissionsMessage="In order to set a custom folder, you will need to select allow in the authentication window."
        buttonRef={googleRef}
      />
      <Button type="button" width="square" size="medium" category="secondary" onClick={deleteFolderFromGroup}>
        <span className="text-grey-600 flex py-2 px-3">
          <Icon size={16} name="dismiss" theme="filled" />
        </span>
      </Button>
    </div>
  );
}

IntegrationFolderSelectorWithMatikSelect.propTypes = {
  currentFolder: PropTypes.shape({
    folder_name: PropTypes.string,
    source_type: PropTypes.string,
    folder_id: PropTypes.string,
    drive_id: PropTypes.string,
  }),
  onFolderSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isEnabled: PropTypes.bool,
  onFolderDelete: PropTypes.func.isRequired,
};

export default IntegrationFolderSelectorWithMatikSelect;
