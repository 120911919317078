import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from '../DropdownMenu';
import { MConfirm } from '../Alerts';
import { isEmpty } from 'lodash';
import { ReactComponent as EyeIcon } from 'svg/eye.svg';
import { ReactComponent as TrashIcon } from 'svg/trash.svg';
import { ReactComponent as ArrowRepeatAll } from 'svg/arrow_repeat_all.svg';
import arrow_download from 'images/arrow_download.svg';
import pdf from 'lib/pdf';
import Constants from 'components/Constants';

class PresentationActionDropdown extends Component {
  render() {
    let menuItem = null;
    if (
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
    ) {
      menuItem = (
        <>
          <img src={arrow_download} className="mrs" alt="download presentation" />
          Download
        </>
      );
    } else if (
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
    ) {
      menuItem = (
        <>
          {this.props.presentation.status == 'done' && (
            <>
              <EyeIcon className="mrs icon-pull-down" alt="view presentation" />
              View
            </>
          )}
        </>
      );
    }

    let templateExists = true;
    if (
      !this.props.presentation.template ||
      isEmpty(this.props.presentation.template) ||
      this.props.presentation.template.deleted
    ) {
      templateExists = false;
    }

    return (
      <DropdownMenu
        dropdownTrigger={this.props.dropdownTrigger}
        isHoverable={this.props.isHoverable}
        active={this.props.active}
        isRight={true}
      >
        {this.props.presentation.presentation_file?.url && (
          <a href="#dummy" className="dropdown-item" onClick={this.onDownloadClick}>
            {menuItem}
          </a>
        )}

        {!isEmpty(this.props.presentation.pdf) && (
          <div>
            <div className="dropdown-divider"></div>
            <a href="#dummy" className="dropdown-item" onClick={this.onPdfDownloadClick}>
              <span className="icon is-small mrs">
                <img src={arrow_download} alt="download presentation as pdf" width="16" />
              </span>
              Download PDF
            </a>
          </div>
        )}
        {this.props.canRead && templateExists && (
          <a
            href="#dummy"
            className="dropdown-item"
            onClick={(e) => this.props.onRegenerateClick(e, this.props.presentation)}
          >
            <ArrowRepeatAll className="mrs icon-pull-down" />
            Regenerate
          </a>
        )}
        <div className="dropdown-divider"></div>
        <a href="#dummy" className="dropdown-item" onClick={this.onDeleteClick}>
          <TrashIcon className="mrs" />
          Delete
        </a>
      </DropdownMenu>
    );
  }

  onDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm('Delete', 'Are you sure you want to delete this presentation?', 'warning', (confirmed) => {
      if (confirmed) {
        this.props.onPresentationDelete(this.props.presentation);
      }
    });
  };

  onDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(this.props.presentation.presentation_file.url, '_blank');
  };

  onPdfDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    pdf.downloadPdf(this.props.presentation);
  };
}

PresentationActionDropdown.propTypes = {
  dropdownTrigger: PropTypes.any,
  isHoverable: PropTypes.bool,
  presentation: PropTypes.object,
  onPresentationDelete: PropTypes.func,
  onPresentationRegenerate: PropTypes.func,
  active: PropTypes.bool,
  onRegenerateClick: PropTypes.func,
  canRead: PropTypes.bool,
};

export default PresentationActionDropdown;
