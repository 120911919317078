import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import column_icon_1 from '../../../svg/column_icon_1.svg';
import column_icon_2 from '../../../svg/column_icon_2.svg';
import column_icon_3 from '../../../svg/column_icon_3.svg';
import column_icon_4 from '../../../svg/column_icon_4.svg';
import column_ratio_1_2 from '../../../svg/column_ratio_1_2.svg';
import column_ratio_1_3 from '../../../svg/column_ratio_1_3.svg';
import column_ratio_2_1 from '../../../svg/column_ratio_2_1.svg';
import column_ratio_3_1 from '../../../svg/column_ratio_3_1.svg';
import radio_icon from '../../../svg/radio.svg';
import radio_selected_icon from '../../../svg/radio_selected.svg';
import { MConfirm } from 'components/shared/Alerts';
import { forEach } from 'lodash';

export function HtmlBuilderApplyColumnRatio({ columnModuleId }) {
  const [selectedButton, setSelectedButton] = useState(0);
  const { actions, parentColumnModuleNode, columnData } = useEditor((state, query) => {
    let parentColumnModuleNode;
    let columnData = { column1HasData: false, column2HasData: false, column3HasData: false, column4HasData: false };

    parentColumnModuleNode = state.nodes[columnModuleId];
    const columnChildren = query.node(query.node(parentColumnModuleNode.id).descendants()[0]).descendants();
    forEach(columnChildren, (child, index) => {
      const columnContainer = query.node(child).descendants()[0];
      if (columnContainer) {
        const columnContainerChildren = query.node(columnContainer).descendants();
        if (columnContainerChildren.length > 0) {
          columnData[`column${index + 1}HasData`] = true;
        }
      }
    });

    return {
      parentColumnModuleNode,
      columnData,
    };
  });

  const buttonConfig = [
    { id: 0, text: '1 Column', icon: column_icon_1 },
    { id: 1, text: '2 Columns', icon: column_icon_2 },
    { id: 2, text: '3 Columns', icon: column_icon_3 },
    { id: 3, text: '4 Columns', icon: column_icon_4 },
    { id: 4, text: '1:2 Columns', icon: column_ratio_1_2 },
    { id: 5, text: '2:1 Columns', icon: column_ratio_2_1 },
    { id: 6, text: '1:3 Columns', icon: column_ratio_1_3 },
    { id: 7, text: '3:1 Columns', icon: column_ratio_3_1 },
    { id: 8, text: '1:4 Columns', icon: column_ratio_1_3 },
    { id: 9, text: '4:1 Columns', icon: column_ratio_3_1 },
  ];

  // Set selected button on mount
  useEffect(() => {
    setSelectedButton(parentColumnModuleNode.data.props.buttonId);
  }, []);

  // If column count changes, default button to first selection
  useEffect(() => {
    setSelectedButton(parentColumnModuleNode.data.props.buttonId);
  }, [parentColumnModuleNode.data.props.buttonId]);

  function formatColumnsList(columns) {
    columns = [...columns].reverse();

    if (columns.length === 0) {
      return '';
    } else {
      if (columns.length === 1) {
        return `Column ${columns[0]}`;
      } else {
        return `Columns ${columns.slice(0, -1).join(', ')} and ${columns.slice(-1)}`;
      }
    }
  }

  function getColumnsCount(buttonId) {
    if (buttonId >= 0 && buttonId <= 3) {
      return buttonId + 1; // 1-4 columns
    } else if (buttonId >= 4 && buttonId <= 7) {
      return 2; // 2 columns
    }
    return null;
  }

  function handleButtonClick(buttonIndex) {
    const currentColumnsCount = getColumnsCount(parentColumnModuleNode.data.props.buttonId);
    const newColumnsCount = getColumnsCount(buttonIndex);
    const columnsToRemove = currentColumnsCount - newColumnsCount;

    let columnsWithData = [];
    if (currentColumnsCount > newColumnsCount) {
      for (let i = currentColumnsCount - 1; i >= newColumnsCount; i--) {
        if (columnData[`column${i + 1}HasData`]) {
          columnsWithData.push(`${i + 1}`);
        }
      }
    }

    if (columnsWithData.length > 0) {
      let columns = formatColumnsList(columnsWithData);
      let warningMessage =
        `Proceeding with this layout change will remove ${columnsToRemove} column${columnsToRemove > 1 ? 's' : ''},` +
        ` starting from the right. If you'd like to keep the content you've already added to ${columns},` +
        ' drag those modules out into another column.';
      MConfirm(
        'Content in columns',
        warningMessage,
        'warning',
        (confirmed) => {
          if (confirmed) {
            actions.setProp(parentColumnModuleNode.id, (props) => {
              props.buttonId = buttonIndex;
            });
            setSelectedButton(buttonIndex);
          }
        },
        'Change Layout',
        'Cancel',
      );
    } else {
      actions.setProp(parentColumnModuleNode.id, (props) => {
        props.buttonId = buttonIndex;
      });
      setSelectedButton(buttonIndex);
    }
  }

  return (
    <div>
      <div className="toolbox">
        {buttonConfig.map((button, index) => (
          <button key={button.id} className="button is-secondary" onClick={() => handleButtonClick(index)}>
            <div className="ratio-button-icons">
              <img src={selectedButton === index ? radio_selected_icon : radio_icon} alt={button.text} />
              <img src={button.icon} />
              <div className="toolbox-button-text">{button.text}</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

HtmlBuilderApplyColumnRatio.propTypes = {
  columnModuleId: PropTypes.string,
};
