import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'react-bulma-components';
import FullScreenModal from '../../shared/modal/FullScreenModal';
import API from '../../../lib/api';
import utils from '../../../lib/utils';
import { UserContext } from '../../UserContext';
import GoogleImportCard from './GoogleImportCard';
import { ComputerImportCard } from './ComputerImportCard';
import { MAlert, MInsufficientPerms } from 'components/shared/Alerts';
import Constants from 'components/Constants';
import { useTemplateMutator } from 'lib/hooks/useTemplate';

const AddDocumentTemplateModal = (props) => {
  const [googleIsLoading, setGoogleIsLoading] = useState(false);
  const [docIsLoading, setDocIsLoading] = useState(false);
  const [doc365IsLoading, setDoc365IsLoading] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('Uploading');
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [oauthUpdate, setOauthUpdate] = useState(null);
  const { create: onTemplateCreate } = useTemplateMutator();
  const { user, updateUser } = useContext(UserContext);

  const handleFormSubmit = (file) => {
    const templateData = { name: file.name };
    if (file instanceof File) {
      if (docValidate(file)) {
        setDocIsLoading(true);
        setProcessingStatus('Uploading');
        templateData['source_type'] = 'word';
        templateData['name'] = file.name.split('.').shift();
      }
    } else if (file.type && file.type === Constants.MICROSOFT.word_type) {
      setDoc365IsLoading(true);
      setProcessingStatus('Uploading');
      templateData['source_type'] = 'word_365';
      templateData['source'] = file;
      templateData['name'] = file.name;
    } else {
      setGoogleIsLoading(true);
      setProcessingStatus('Uploading');
      templateData['source_type'] = 'google_slides';
      templateData['source'] = file;
      templateData['name'] = file.name;
      templateData['description'] = file.description;
    }

    onTemplateCreate(templateData, file, setProcessingStatus, onProgress)
      .then((newTemplateData) => {
        setGoogleIsLoading(false);
        setDocIsLoading(false);
        setDoc365IsLoading(false);
        setProcessingStatus('done');

        props.onTemplateAdd(newTemplateData);
      })
      .catch(submitError);
  };

  const submitError = (err) => {
    setGoogleIsLoading(false);
    setDocIsLoading(false);
    setDoc365IsLoading(false);
    API.defaultError(err);
  };

  const onProgress = (progressEvent) => {
    setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  };

  const docValidate = (file) => {
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension !== 'doc' && fileExtension !== 'docx') {
        MAlert('Please select a Word file.');
        return false;
      }
      if (file.size > 1024 * 1024 * 60) {
        MAlert('The Word fie you selected exceeds our maximum allowed file size of 60MB');
        return false;
      }
    }

    return true;
  };

  const handleSelectFile = (e) => {
    if (e.target.files[0]) {
      handleFormSubmit(e.target.files[0]);
    }
  };

  const handleSelectGoogleSheet = (data) => {
    if (data.docs) {
      handleFormSubmit(data.docs[0]);
    }
  };

  const handle365SelectFile = (data) => {
    const item = data.items[0];
    const file = {
      ...item,
      type: Constants.MICROSOFT.word_type,
      name: item.name.split('.').shift(),
      mimeType: item.name.split('.').pop(),
      url: item.webUrl,
      driveItemUrl: item.webDavUrl,
      driveId: item.parentReference?.driveId,
    };
    handleFormSubmit(file);
  };

  const oauthFunction = (onUpdate) => {
    setOauthUpdate(onUpdate);
    utils.connectGoogle(user, updateUser, onUpdate, showPermsAlert);
  };

  const reconnectGoogle = () => {
    utils.reconnectGoogle(user, updateUser, oauthUpdate, showPermsAlert);
  };

  const showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to import your template, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, reconnectGoogle);
  };

  let googleAuth = null;
  const googleIntegration = utils.googleIntegration(user);
  if (googleIntegration) {
    googleAuth = {
      access_token: googleIntegration.access_token,
      expires_on: googleIntegration.expires_on,
      hasNecessaryScopes: googleIntegration.has_necessary_scopes,
    };
  }
  const headerCenter = <Heading className="full-screen-modal-title">Add Document Template</Heading>;
  return (
    <FullScreenModal
      show={props.show}
      onClose={props.onClose}
      showDefaultFooter={false}
      headerCenter={headerCenter}
      onBackClick={props.onPresentationBackClick}
    >
      <div className="add-template-body">
        <div className="is-fullwidth">
          <div className="mbs mtxxxl is-4">Upload Template</div>
          <div className="add-template-cards">
            <GoogleImportCard
              oauthFunction={oauthFunction}
              auth={googleAuth}
              handleSelectGoogleSheet={handleSelectGoogleSheet}
              percentCompleted={percentCompleted}
              processingStatus={processingStatus}
              isLoading={googleIsLoading}
              onTemplateAdd={props.onTemplateAdd}
              type="documents"
            />
            <ComputerImportCard
              percentCompleted={percentCompleted}
              processingStatus={processingStatus}
              isLoading={docIsLoading}
              is365Loading={doc365IsLoading}
              handleSelectFile={handleSelectFile}
              handle365SelectFile={handle365SelectFile}
              type="documents"
            />
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

AddDocumentTemplateModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onPresentationBackClick: PropTypes.func,
};

export default AddDocumentTemplateModal;
