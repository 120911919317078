import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { AuthMode } from '@algolia/client-common';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import API from '../../../lib/api';
import Constants from '../../Constants';
import { forEach, isEmpty, map } from 'lodash';

const WithInstantSearch = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      entityType: PropTypes.string,
      filters: PropTypes.string,
      inputs: PropTypes.object,
      userContext: PropTypes.object,
      // If templates redux state or some other redux defines this,
      // this must wrap the connection of redux to the inner component
      orderedIds: PropTypes.array,
      defaultRefinement: PropTypes.object,
      hitsPerPage: PropTypes.number,
    };

    constructor(props) {
      super(props);
      this.state = {
        // eslint-disable-next-line no-undef
        index: process.env.REACT_APP_ALGOLIA_INDEX,
        searchClient: { search: () => {} },
        searchKeyIsLoading: true,
        searchState: {},
        showFilters: false,
        existingInputs: {},
      };

      this.mounted = true;
    }

    componentDidMount() {
      this.fetchSearchKey();
      if (this.props.defaultRefinement) {
        const currentRefinements = this.state.searchState.refinementList;
        this.setState({ searchState: { refinementList: { ...currentRefinements, ...this.props.defaultRefinement } } });
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.entityType !== this.props.entityType) {
        this.fetchSearchKey();
        if (this.props.defaultRefinement) {
          const currentRefinements = this.state.searchState.refinementList;
          this.setState({
            searchState: { refinementList: { ...currentRefinements, ...this.props.defaultRefinement } },
          });
        }
      }
      if (
        this.props.defaultRefinement &&
        ((this.state.searchState?.refinementList &&
          Object.keys(this.props.defaultRefinement).some((key) => !(key in this.state.searchState.refinementList))) ||
          !this.state.searchState?.refinementList)
      ) {
        const currentRefinements = this.state.searchState.refinementList;
        this.setState({
          searchState: { refinementList: { ...currentRefinements, ...this.props.defaultRefinement } },
        });
      }
    }

    fetchSearchKey() {
      this.setState({ searchKeyIsLoading: true });
      API.post(`/search/key/${this.props.entityType}/`, {}, (response) => {
        if (this.mounted) {
          this.setState({
            searchClient: algoliasearch(
              // eslint-disable-next-line no-undef
              process.env.REACT_APP_ALGOLIA_APP_ID,
              response.data,
              { authMode: AuthMode.WithinHeaders },
            ),
            searchKeyIsLoading: false,
          });
        }
      });
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    attributes() {
      const attributeMapping = {
        dynamic_content: {
          dynamic_content_method: 'Method',
          dynamic_content_type: 'Type',
          templates: 'Templates',
          item_tags: 'Tag',
          'user.name': 'Owner',
          parameters: 'Inputs',
          'data_source.name': 'Data Source Name',
        },
        data_source: {
          data_source_type: 'Type',
        },
        input: {
          parameter_type: 'Type',
          source_type: 'Source Type',
          dynamic_content: 'Dynamic Content',
          'user.name': 'Owner',
        },
        presentation: {
          presentation_type: 'Presentation Type',
          'template.name': 'Template',
          'user.name': 'Generated by',
          updated_on: 'Date Generated',
          status: 'Status',
          params: 'Inputs',
        },
        template: {
          source_type: 'Type',
          item_tags: 'Tag',
          is_library: 'Is Library',
          is_sub_template: 'Is Sub-template',
        },
        user: {
          roles: 'Roles',
        },
      };

      if (this.props.inputs && this.props.inputs.param_keys) {
        forEach(this.props.inputs.param_keys, (key) => {
          attributeMapping.presentation[`params.${key.name}`] = '';
        });
      }
      return attributeMapping[this.props.entityType];
    }

    render() {
      const isSearching = !!(this.state.searchState && (this.state.searchState.query || this.isRefined()));
      // Special case for user index
      let index = this.state.index;
      let entityType = this.props.entityType;
      if (this.props.entityType === 'user') {
        index = `${this.state.index}_users`;
      } else if (this.props.entityType === 'input') {
        entityType = 'parameter';
      }
      let filters = `type:${entityType}`;
      if (this.props.filters) {
        filters += ` AND ${this.props.filters}`;
      }
      if ('updated_on' in this.state.searchState) {
        const timestamps = this.state.searchState.updated_on;
        if (timestamps[0] && timestamps[1]) {
          filters += ` AND updated_on:${timestamps[0]} TO ${timestamps[1]}`;
        }
      }
      if (entityType === 'presentation') {
        if (this.props.userContext && this.props.userContext.user) {
          filters += ` AND user.id:${this.props.userContext.user.id} OR viewable_by:user_${this.props.userContext.user.id}`;
          forEach(this.props.userContext.user.roles, (role) => {
            if (Constants.ROLE_ID_MAPPING[role]) {
              filters += ` OR viewable_by:group_${Constants.ROLE_ID_MAPPING[role]}`;
            }
          });
          if (!isEmpty(this.props.userContext.user.group_ids)) {
            forEach(this.props.userContext.user.group_ids, (group_id) => {
              filters += ` OR viewable_by:group_${group_id}`;
            });
          }
        }
      }
      if (!isEmpty(this.props.orderedIds)) {
        const templateObjectIds = map(this.props.orderedIds, (templateId) => `${templateId}_${entityType}`);
        for (let i = 0; i < templateObjectIds.length; i++) {
          if (i === 0) {
            filters += ` AND (objectID:${templateObjectIds[i]}`;
          } else {
            filters += ` OR objectID:${templateObjectIds[i]}`;
          }
        }
        filters += ')';
      }
      return (
        <React.Fragment>
          <InstantSearch
            indexName={index}
            searchClient={this.state.searchClient}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
          >
            {this.props.entityType === 'user' ? (
              <Configure
                hitsPerPage={this.props.hitsPerPage || Constants.PAGE_SIZE}
                maxValuesPerFacet={Constants.MAX_VALUES_PER_FACET}
              />
            ) : (
              <Configure
                filters={filters}
                hitsPerPage={this.props.hitsPerPage || Constants.PAGE_SIZE}
                maxValuesPerFacet={Constants.MAX_VALUES_PER_FACET}
              />
            )}
            <WrappedComponent
              {...this.props}
              {...this.state}
              searchAttributes={this.attributes()}
              onSearchStateChange={this.onSearchStateChange}
              isSearching={isSearching}
            />
          </InstantSearch>
        </React.Fragment>
      );
    }

    isRefined() {
      if (!this.state.searchState.refinementList) {
        return false;
      }
      for (let refinement in this.state.searchState.refinementList) {
        if (this.state.searchState.refinementList[refinement]) {
          return true;
        }
      }
      if (this.state.searchState.updated_on) {
        return true;
      }

      return false;
    }

    onSearchStateChange = (searchState) => {
      if (!this.mounted) {
        return;
      }
      let emptyParams = [];
      if (searchState.refinementList) {
        if ('updated_on' in searchState.refinementList) {
          searchState['updated_on'] = searchState.refinementList.updated_on;
          delete searchState.refinementList.updated_on;
        }
        if ('params' in searchState.refinementList) {
          forEach(searchState.refinementList.params, (param) => {
            for (let key in param) {
              searchState.refinementList[`params.${key}`] = param[key];
              if (isEmpty(param[key])) {
                emptyParams.push(`params.${key}`);
              }
            }
          });
          delete searchState.refinementList.params;
        }
      }
      this.setState({ searchState });
      // Remove empty params so isSearching is set to false and sorting will work again
      if (emptyParams.length > 0) {
        let newSearchState = searchState;
        forEach(emptyParams, (param) => {
          delete newSearchState.refinementList[param];
        });
        this.setState({ newSearchState });
      }
    };
  };
};

export default WithInstantSearch;
