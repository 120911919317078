import React, { Component, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Columns, Form, Box } from 'react-bulma-components';
import { find, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import Constants from '../../Constants';
import API from '../../../lib/api';
import { MAlert, MConfirm } from '../../shared/Alerts';
import InputFormQuery from './InputFormQuery';
import InputFormApi from './InputFormApi';
import inputs from '../../../lib/inputs';
import CopyModal from 'components/producer/dynamicContent/CopyModal';
import { Select } from '../../shared/FormSelect';
import CheckboxWithLabel from '../../shared/CheckboxWithLabel';
import WYSIWYGEditor from '../../shared/WYSIWYGEditor';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import FixedFormButtons from '../../shared/FixedFormButtons';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import utils from 'lib/utils';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import FormHeader from 'components/shared/FormHeader';
import QueryLoading from '../../shared/QueryLoading';
import ExplainerButton from '../../shared/ExplainerButton';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import Banner from 'components/shared/Banner';
import { useQueryInputList } from '../../../lib/hooks/useInputList';
import InputFormMatikUser from './InputFormMatikUser';
import { UserContext } from '../../UserContext';
import { useTemplateContent } from 'lib/hooks/useTemplate';
import { useDynamicContentReferers } from 'lib/hooks/useInput';
import AccessesDebugView from 'components/shared/AccessDebugView';

class InputForm extends Component {
  constructor(props) {
    super(props);

    this.widthRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      allowUserOverride: props.input?.allow_user_override ?? false,
      dataSource: props.input?.query_obj?.data_source ?? null,
      defaultValue: props.input?.default_value ?? null,
      defaultValueOptions: null,
      defaultValueError: null,
      defaultOptionsAreLoading: false,
      defaultOptionsDataTS: null,
      description: props.input?.description ?? '',
      display_name: props.input?.display_name ?? '',
      isChanged: false,
      name: props.input?.name ?? '',
      nameError: '',
      query_obj: props.input?.query_obj ?? {},
      source_list: props.input?.source_list ? props.input.source_list.join(',') : '',
      source_type: props.input?.source_type ?? Constants.InputSources.USER_INPUT,
      testResult: null,
      type: props.input?.type ?? Constants.InputTypes.STRING,
      showDynamicContentModal: false,
      inputMapping: props.input?.input_mapping ?? {},
      isLoading: false,
      existingInputs: {},
      defaults: null,
      isFullscreen: this.props.isFullscreen,
      includeNullValues: props.input?.include_null_values,
      includeFirstRow: props.input?.include_first_row,
    };
  }

  componentDidMount() {
    this.reloadAllInputs();
    const defaultState = cloneDeep(this.state);
    this.setState({ defaults: defaultState });
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.setState({ isChanged: false });
    this.props.toggleIsChanged(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isChanged !== this.state.isChanged) {
      this.props.toggleIsChanged(this.state.isChanged);
    }
  }

  render() {
    if (this.state.isChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    let additionalElem = null;
    const queryDataSources = this.getQueryDataSources();
    const apiDataSources = this.getApiDataSources();
    const nameColor = this.state.nameError ? 'danger' : null;

    const dynamicContentContext = {
      existingInputs: this.state.existingInputs,
      query: this.props.query,
      onQueryStringUpdate: this.onQueryStringUpdate,
      onQueryObjectUpdate: (queryObj) => this.onQueryStringUpdate(JSON.stringify(queryObj)),
    };

    if (this.state.source_type && this.state.source_type === Constants.InputSources.QUERY) {
      additionalElem = (
        <InputFormQuery
          dataSources={queryDataSources}
          dataSource={this.state.dataSource}
          width={this.getWidth()}
          inputsInQueryString={this.props.inputsByName}
          existingInputs={this.state.existingInputs}
          testResult={this.state.testResult}
          updateTestResult={(testResult) => this.setState({ testResult })}
          onDataSourceUpdate={this.onDataSourceUpdate}
          onQueryStringUpdate={this.onQueryStringUpdate}
          query_obj={this.state.query_obj}
          entityId={this.props.input ? this.props.input.id : 'new'}
          entityType="input"
          input={this.props.input}
          onInputMappingUpdate={this.onInputMappingUpdate}
          inputMapping={this.state.inputMapping}
          formRef={null}
        />
      );
    } else if (this.state.source_type && this.state.source_type === 'list') {
      additionalElem = (
        <Form.Field>
          <Form.Textarea
            value={this.state.source_list}
            onChange={this.onUpdate}
            name="source_list"
            placeholder="Comma-Separated List of Values"
          />
        </Form.Field>
      );
    } else if (
      this.state.dataSource &&
      apiDataSources &&
      apiDataSources.length > 0 &&
      this.state.source_type &&
      this.state.source_type === 'api'
    ) {
      additionalElem = (
        <InputFormApi
          existingInputs={this.state.existingInputs}
          isFetching={this.props.isFetching}
          width={this.getWidth()}
          apiDataSources={apiDataSources}
          dataSource={this.state.dataSource}
          query_obj={this.state.query_obj}
          onDataSourceUpdate={this.onDataSourceUpdate}
          inputsInQueryString={this.props.inputsByName}
          onQueryStringUpdate={this.onQueryStringUpdate}
          entityId={this.props.input ? this.props.input.id : 'new'}
          entityType="input"
          testResult={this.state.testResult}
          updateTestResult={(testResult) => this.setState({ testResult })}
          input={this.props.input}
          onInputMappingUpdate={this.onInputMappingUpdate}
          inputMapping={this.state.inputMapping}
          formRef={null}
        />
      );
    }
    const dropdownOptions = this.state.source_type
      ? Constants.INPUT_TYPES_FOR_INPUT_SOURCE[this.state.source_type]
      : [];
    const defaultInput = {
      description: this.state.description,
      source_type: this.state.source_type,
      source_list: this.state.source_list.split(','),
      query_obj: this.state.query_obj,
      type: this.state.type,
      name: this.state.name,
      display_name: this.state.display_name,
      input_mapping: this.state.inputMapping,
      include_null_values: this.state.includeNullValues,
      include_first_row: this.state.includeFirstRow,
    };
    let defaultValue = '';
    let defaultInputValues;
    const onResync = () =>
      inputs.loadOptionsFromQuery(
        defaultInput,
        defaultInputValues,
        this.onInputLoadSuccess,
        this.onInputLoadError,
        null,
        this.beforeLoad,
        null,
        null,
      );
    defaultInputValues = {
      [this.state.name]: {
        value: inputs.parseDefaultValue(this.state.defaultValue, this.state.type),
        options: this.state.defaultValueOptions,
        fetch_error: this.state.defaultValueError,
        isLoading: this.state.defaultOptionsAreLoading,
        dataTS: this.state.defaultOptionsDataTS,
        onSyncButtonClick: onResync,
      },
    };
    if (
      (this.props.input && this.props.input.query_obj) ||
      (this.state.source_type !== Constants.InputSources.QUERY && this.state.source_type !== Constants.InputSources.API)
    ) {
      if (!this.props.inputsByName || Object.values(this.props.inputsByName).length === 0) {
        defaultValue = (
          <Form.Control>
            {inputs.getFieldFromInputType(
              defaultInput,
              defaultInputValues,
              null,
              this.onDefaultValueChange,
              this.onInputLoadSuccess,
              this.onInputLoadError,
              null,
              () => this.beforeLoad,
              null,
              true,
            )}
          </Form.Control>
        );
      } else {
        defaultValue = <span>Can&apos;t set default value for inputs with nested inputs.</span>;
      }
    }
    let matik_user_banner_text = '';

    if (this.state.source_type === Constants.InputSources.MATIK_USER) {
      const nameToBannerTextMap = {
        MatikUser_Name: 'name',
        MatikUser_Email: 'email',
        'MatikUser_Profile-Image': 'profile image',
        MatikUser_Phone: 'phone number',
        MatikUser_Title: 'title',
      };

      matik_user_banner_text = `This input always returns the ${
        nameToBannerTextMap[this.state.name]
      } of the user currently generating the 
      template. It is created by Matik and can not be edited or deleted. (If you need to review or edit 
        users' ${nameToBannerTextMap[this.state.name]}s, visit the Matik User List.)`;
    }
    const inputSourceOptions = this.calculateInputSourceOptions(queryDataSources, apiDataSources);
    const typeOptions = dropdownOptions?.map((option) => ({ label: option.display, value: option.value }));
    const selectedType = find(typeOptions, (option) => option.value === this.state.type);

    const showNullValueCheckbox =
      (this.state.source_type === Constants.InputSources.QUERY ||
        this.state.source_type === Constants.InputSources.API) &&
      ![Constants.DATA_SOURCE_TYPES.google_sheet, Constants.DATA_SOURCE_TYPES.excel].includes(
        this.state.dataSource?.type,
      );

    let defaultValueText = 'Default Value (Optional)';
    if (this.state.type === Constants.InputTypes.BOOLEAN) {
      defaultValueText = 'Default Value';
    }
    const boxClassName = 'is-shadowless has-light-gray-border mbxl pal mtm';
    const renderedInputForm = (
      <>
        <Box className={boxClassName}>
          {this.props.allowNameChange && (
            <React.Fragment>
              <Form.Label>Name</Form.Label>
              <Form.Field className="mbl">
                <Form.Input
                  type="text"
                  value={this.state.name.replace(/[ .:|{}(),]/g, '_')}
                  onChange={this.onUpdate}
                  name="name"
                  placeholder="Input name"
                  color={nameColor}
                  aria-label="Input Name"
                />
                {this.state.nameError && <Form.Help color="danger">{this.state.nameError}</Form.Help>}
              </Form.Field>
            </React.Fragment>
          )}
          <Form.Label>Display Name</Form.Label>
          <Form.Help className="pbs">The name of the input displayed to the consumer</Form.Help>
          <Form.Field className="mbl">
            <Form.Input
              type="text"
              value={this.state.display_name}
              onChange={this.onUpdate}
              name="display_name"
              placeholder="Display name"
              aria-label="Input Display Name"
            />
          </Form.Field>
          <Form.Label>Description</Form.Label>
          <Form.Field className="mbl">
            <Form.Control>
              <WYSIWYGEditor
                name="description"
                className="long-input"
                placeholder="Description"
                id="template-desc"
                ariaLabel="Input description"
                updateEditor={this.updateEditor}
                onChange={this.onUpdate}
                value={this.state.description}
              />
              {this.props.flags.queryExplainer && !this.context.user?.enterprise?.settings?.disable_all_ai && (
                <div className="query-explainer-container">
                  <ExplainerButton
                    onGenerate={this.updateDescription}
                    query={this.state.query_obj}
                    queryUse="parameter"
                    name={this.state.name || 'New Parameter'}
                  />
                </div>
              )}
            </Form.Control>
          </Form.Field>
          <Columns>
            <Columns.Column>
              <Form.Label>Input Source</Form.Label>
              <Form.Help className="pbs">How the values for the input will be populated</Form.Help>
              <Form.Field>
                <Form.Control>
                  <Select
                    onChange={this.onUpdateInputSource}
                    value={this.optionFromSourceType()}
                    classNamePrefix="matik-select"
                    aria-label="Param Source Type"
                    options={inputSourceOptions}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>Configuration Type</Form.Label>
              <Form.Help className="pbs">How this input will be displayed</Form.Help>
              <Select
                classNamePrefix="matik-select"
                value={selectedType}
                name="type"
                onChange={this.onTypeUpdate}
                aria-label="Param Type"
                options={typeOptions}
              />
            </Columns.Column>
          </Columns>
        </Box>
        {additionalElem && (
          <DynamicContentContext.Provider value={dynamicContentContext}>
            <Box className={boxClassName}>{additionalElem}</Box>
          </DynamicContentContext.Provider>
        )}
        <Box className={boxClassName}>
          <Form.Label>
            <span className="mrs">{defaultValueText}</span>
            <QueryLoading
              isLoading={this.state.defaultOptionsAreLoading}
              dataTS={this.state.defaultOptionsDataTS}
              onSyncButtonClick={onResync}
            />
          </Form.Label>
          <Form.Help className="pbs">
            The default value for this Input&nbsp;
            {(this.state.source_type === Constants.InputSources.QUERY ||
              this.state.source_type === Constants.InputSources.API) &&
              '(Note: Default value options will update on input save)'}
          </Form.Help>
          <Form.Field>{defaultValue}</Form.Field>
          {showNullValueCheckbox && (
            <>
              <Form.Field>
                <CheckboxWithLabel
                  checked={this.state.includeNullValues}
                  id="include_null_values"
                  label="Include NULL values"
                  name="include_null_values"
                  onChange={this.onIncludeNullValuesChange}
                  tooltip={`When toggled on, if your data includes NULL values, end users will be able to select ${Constants.NULL_VALUE_INPUT_LABEL} as an input option.`}
                />
              </Form.Field>
              <Form.Field>
                <CheckboxWithLabel
                  checked={this.state.allowUserOverride}
                  id="allow_user_override"
                  label="Allow user to override returned value"
                  name="allow_user_override"
                  onChange={this.onOverrideChange}
                />
              </Form.Field>
              <Form.Field>
                <CheckboxWithLabel
                  checked={this.state.includeFirstRow}
                  id="include_first_row"
                  label="Include first row of values"
                  name="include_first_row"
                  onChange={this.onIncludeFirstValueChange}
                  tooltip={
                    'When toggled on, the first row of results which is assumed to be the table header, is added as an input option.'
                  }
                />
              </Form.Field>
            </>
          )}
        </Box>
        <FixedFormButtons
          isLoading={this.state.isLoading}
          query={this.props.query}
          showCancel={this.props.showCancel}
          submitForm={this.onFormSubmit}
          isChanged={this.state.isChanged}
          saveButtonText={this.props.saveButtonText}
          validateFormData={this.validateInputData}
          inputsInQueryString={this.props.inputsByName}
          noBackground={this.props.noBackground}
          onClose={this.props.onClose}
          buttonWidth={this.props.buttonWidth}
        />
      </>
    );

    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        id="input-form"
        className={`${this.props.isFullscreen ? 'input-form-fullscreen' : 'input-form'} is-flex is-flex-col is-grow`}
      >
        <Box
          className={`${this.props.isFullscreen ? 'is-shadowless' : ''} input-form-body is-grow`}
          domRef={this.formRef}
        >
          <div className="pbxl">
            {!this.props.hideHeader && !this.props.isFullscreen && (
              <FormHeader
                formType={Constants.ItemTypes.INPUT}
                id={this.props.entityId}
                isChanged={this.state.isChanged}
                input={this.props.input}
                connectedItems={{ [Constants.ItemTypes.DYNAMIC_CONTENT]: { items: this.props.contentReferers || [] } }}
                isNew={!this.props.input?.id}
                linkDisabled={this.props.linkDisabled}
                name={this.state.name.length > 0 ? this.state.name : 'New Input'}
                onClose={this.props.onClose}
                onCloseDataTip={this.props.onCloseDataTip}
                onDelete={this.onInputDelete}
                showClickableCloseIcon={this.props.showClickableCloseIcon}
                showDelete={this.props.showDelete}
                url={`/inputs/${this.props.entityId}`}
              />
            )}
            <AccessesDebugView className="ml-4" itemType="parameter" itemId={this.props.entityId} />
            {this.state.source_type === Constants.InputSources.MATIK_USER ? (
              <>
                <Banner bannerType="info" text={matik_user_banner_text} />
                <Form.Field className="mtl">
                  <InputFormMatikUser input={this.props.input} />
                </Form.Field>
              </>
            ) : (
              renderedInputForm
            )}
          </div>
        </Box>
        <CopyModal oldName={this.state.name} itemType={Constants.ItemTypes.INPUT} onCopySubmit={this.onCopySubmit} />
        <Prompt
          when={this.state.isChanged}
          message="Are you sure you want to navigate away? There are unsaved changes."
        />
      </form>
    );
  }

  calculateInputSourceOptions(queryDataSources, apiDataSources) {
    const options = [
      { label: 'User Input', value: 'user_input' },
      { label: 'List', value: 'list' },
    ];
    if (queryDataSources && queryDataSources.length > 0) {
      options.push({ label: 'Query', value: 'query' });
    }
    if (apiDataSources && apiDataSources.length > 0) {
      options.push({ label: 'API', value: 'api' });
    }

    return options;
  }

  optionFromSourceType() {
    const mapping = {
      user_input: { label: 'User Input', value: 'user_input' },
      list: { label: 'List', value: 'list' },
      query: { label: 'Query', value: 'query' },
      api: { label: 'API', value: 'api' },
    };
    return mapping[this.state.source_type];
  }

  onOverrideChange = (e) => {
    this.setState({
      allowUserOverride: e.target.checked,
      isChanged: e.target.checked !== this.state.defaults.allowUserOverride,
    });
  };

  onIncludeNullValuesChange = (e) => {
    this.setState({
      includeNullValues: e.target.checked,
      isChanged: e.target.checked !== this.state.defaults.includeNullValues,
    });
  };

  onIncludeFirstValueChange = (e) => {
    this.setState({
      includeFirstRow: e.target.checked,
      isChanged: e.target.checked !== this.state.defaults.includeFirstRow,
    });
  };

  onDefaultValueChange = (name, values) => {
    const valJSON = JSON.stringify(values[name].value);
    this.setState({ defaultValue: valJSON, isChanged: true });
  };

  onInputLoadSuccess = (
    inputName,
    inputOptions,
    inputType,
    startingNestedInputValues,
    currentNestedInputValues,
    mappedOptions,
    inputMappingOptions,
    isStillLoading,
    dataTS,
  ) => {
    this.setState({
      defaultValueOptions: inputOptions,
      defaultOptionsAreLoading: isStillLoading,
      defaultOptionsDataTS: dataTS,
    });
  };

  onInputLoadError = (err) => {
    this.setState({ defaultValueError: err, defaultOptionsAreLoading: false, defaultOptionsDataTS: null });
  };

  beforeLoad = () => {
    this.setState({ defaultOptionsAreLoading: true });
  };

  getQueryDataSources = () => {
    if (!this.props.dataSources) {
      return [];
    }
    return this.props.dataSources.filter(
      (dataSource) => Constants.DATA_SOURCE_TYPES_FOR_METHOD['query'].indexOf(dataSource.type) >= 0,
    );
  };

  getApiDataSources = () => {
    if (!this.props.dataSources) {
      return [];
    }
    return this.props.dataSources.filter(
      (dataSource) => Constants.DATA_SOURCE_TYPES_FOR_METHOD['api'].indexOf(dataSource.type) >= 0,
    );
  };

  onUpdate = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    this.onUpdateHelper(fieldName, fieldValue);
  };

  buildDescriptionFromBlocks = (description) => {
    let descriptionText = '';
    description.blocks.forEach((block, idx) => {
      if (idx === 0) {
        descriptionText += block.text;
      } else {
        descriptionText += '\n' + block.text;
      }
    });
    return descriptionText;
  };

  updateEditor = (editorData) => {
    let description = JSON.parse(editorData);
    const descriptionText = this.buildDescriptionFromBlocks(description);
    let initialDescription = this.props.input?.description;
    let initialDescriptionText = '';
    if (utils.isValidJSON(initialDescription)) {
      initialDescription = JSON.parse(this.props.input.description);
      initialDescriptionText = this.buildDescriptionFromBlocks(initialDescription);
    }
    if (descriptionText !== initialDescriptionText) {
      const newDescription = descriptionText.length ? editorData : '';
      this.setState({ description: newDescription, isChanged: true });
    } else if (descriptionText === initialDescriptionText) {
      this.setState({ isChanged: false });
    }
  };

  updateDescription = (description) => {
    this.setState({ description });
  };

  onUpdateHelper = (fieldName, fieldValue) => {
    const fieldNameError = fieldName + 'Error';
    this.setState({
      [fieldName]: fieldValue,
      [fieldNameError]: '',
      isChanged: this.state.defaults[fieldName] !== fieldValue,
    });
  };

  onTypeUpdate = (obj, action) => {
    if (action.action === 'select-option') {
      const name = 'type';
      const value = obj.value;
      this.onUpdateHelper(name, value);
      this.setState({ defaultValue: null });
    }
  };

  onUpdateInputSource = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      if (
        value === Constants.InputSources.QUERY &&
        (!this.state.query_obj ||
          !this.state.query_obj.data_source ||
          !this.state.query_obj.data_source.id ||
          this.state.source_type !== Constants.InputSources.QUERY)
      ) {
        const updatedQueryObj = this.getQueryObjWithDefault();
        const queryDataSources = this.getQueryDataSources();
        updatedQueryObj.data_source = queryDataSources.length > 0 ? queryDataSources[0] : {};
        this.setState({ query_obj: updatedQueryObj, dataSource: updatedQueryObj.data_source });
      } else if (value !== Constants.InputSources.QUERY && this.state.query_obj) {
        this.setState({ query_obj: {}, dataSource: null, inputMapping: {} });
      }

      if (value !== 'source_list' && this.state.source_list) {
        this.setState({ source_list: '', inputMapping: {} });
      }

      if (value === 'api') {
        const dataSources = this.getApiDataSources();
        const updatedQueryObj = { query_string: '' };
        updatedQueryObj.data_source = dataSources && dataSources.length > 0 ? dataSources[0] : {};
        this.setState({ query_obj: updatedQueryObj, dataSource: dataSources[0], inputMapping: {} });
      }

      let updatedType = this.state.type;

      if (
        this.state.type &&
        Constants.INPUT_TYPES_FOR_INPUT_SOURCE[value].filter((inputType) => inputType.value === this.state.type)
          .length === 0
      ) {
        updatedType = Constants.INPUT_TYPES_FOR_INPUT_SOURCE[value][0].value;
      }
      this.setState({ source_type: value, type: updatedType, isChanged: value !== this.state.defaults.source_type });
    }
  };

  onQueryStringUpdate = (stringValue) => {
    // Convert Salesforce query-based values to JSON string
    if (this.state.dataSource.type === 'salesforce' && !utils.isValidJSON(stringValue)) {
      stringValue = JSON.stringify({ soql_string: stringValue, source: 'objects' });
    }
    const updatedQueryObj = this.getQueryObjWithDefault();
    updatedQueryObj.query_string = stringValue;

    this.setState({
      query_obj: updatedQueryObj,
      isChanged: stringValue !== this.state.defaults?.query,
    });
    this.props.setQuery(stringValue);
    return Promise.resolve();
  };

  onInputMappingUpdate = (inputMapping) => {
    this.setState({ inputMapping, isChanged: true });
  };

  onDataSourceUpdate = (value) => {
    const updatedQueryObj = {};

    const dataSource = find(this.props.dataSources, { id: parseInt(value.value) });
    if (dataSource) {
      updatedQueryObj.data_source = { id: dataSource.id, type: dataSource.type, name: dataSource.name };
      this.setState({ query_obj: updatedQueryObj, dataSource, inputMapping: {}, isChanged: true });
    }
  };

  getQueryObjWithDefault = () => {
    return this.state.query_obj ? Object.assign({}, this.state.query_obj) : {};
  };

  getInputMapping() {
    const inputMappingKey = document.getElementById('input-mapping-key');
    const inputMappingValue = document.getElementById('input-mapping-value');
    if (this.state.inputMapping) {
      return this.state.inputMapping;
    } else if (inputMappingKey && inputMappingValue) {
      let mappingKey = inputMappingKey.innerText;
      let mappingValue = inputMappingValue.innerText;
      return { [mappingKey]: mappingValue };
    } else {
      return {};
    }
  }

  onCopySubmit = (e, newName) => {
    e.preventDefault();
    if (!this.validateInputData()) {
      return;
    }
    const data = {
      name: newName,
      display_name: this.state.display_name,
      default_value: this.state.defaultValue,
      description: this.state.description,
      type: this.state.type,
      source_type: this.state.source_type,
      source_list: this.state.source_type === Constants.InputSources.LIST ? this.state.source_list.split(',') : null,
      query_obj: [Constants.InputSources.QUERY, Constants.InputSources.API].includes(this.state.source_type)
        ? this.state.query_obj
        : null,
      params: this.props.inputsByName
        ? inputs.inputsInQueryWithoutNested(this.props.query, this.props.inputsByName)
        : [],
      allow_user_override: this.state.allowUserOverride,
      input_mapping: this.getInputMapping(),
    };
    this.setState({ isLoading: true });
    API.post(
      '/parameters/',
      data,
      (response) => {
        this.props.onInputAdd(response.data.new_entity);
        this.reloadAllInputs();
        this.props.closeModal();
      },
      (error) => {
        this.setState({ isLoading: false });
        API.defaultError(error);
      },
    );
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.validateInputData()) {
      return;
    }
    this.props.toggleIsChanged(false);
    const data = {
      name: this.state.name,
      display_name: this.state.display_name,
      default_value: this.state.defaultValue,
      description: this.state.description,
      type: this.state.type,
      source_type: this.state.source_type,
      source_list: this.state.source_type === Constants.InputSources.LIST ? this.state.source_list.split(',') : null,
      query_obj: [Constants.InputSources.QUERY, Constants.InputSources.API].includes(this.state.source_type)
        ? this.state.query_obj
        : null,
      params: this.props.inputsByName
        ? inputs.inputsInQueryWithoutNested(this.props.query, this.props.inputsByName)
        : [],
      allow_user_override: this.state.allowUserOverride,
      input_mapping: this.getInputMapping(),
      include_null_values: this.state.includeNullValues,
      include_first_row: this.state.includeFirstRow,
    };
    this.setState({ isLoading: true });
    if (this.props.input && this.props.input.id) {
      API.put(
        `/parameters/${this.props.input.id}/`,
        data,
        (response) => {
          if (this.props.onInputUpdate) {
            this.props.onInputUpdate(response.data.updated_entity);
          } else {
            this.props.onClose();
          }
          this.setState({ isChanged: false, isLoading: false });
          this.reloadAllInputs();
          this.props.invalidateInputs();
        },
        (err) => {
          this.setState({ isLoading: false });
          API.defaultError(err);
        },
      );
    } else {
      API.post(
        '/parameters/',
        data,
        (response) => {
          this.setState({
            name: '',
            display_name: '',
            description: '',
            source_type: 'user_input',
            source_list: '',
            query_obj: null,
            type: 'string',
            isChanged: false,
          });
          this.props.setQuery('');
          this.props.onInputAdd(response.data.new_entity);
          this.props.invalidateInputs();
          this.reloadAllInputs();
        },
        (err) => {
          this.setState({ isLoading: false });
          API.defaultError(err);
        },
      );
    }
  };

  reloadAllInputs = () => {
    API.get('/parameters/?all=true', (response) => {
      const updatedExistingInputs = {};
      for (let input of response.data) {
        updatedExistingInputs[input.name] = input;
      }
      this.setState({ existingInputs: updatedExistingInputs });
    });
  };

  onInputDelete = (e) => {
    e.preventDefault();
    MConfirm('Delete', 'Are you sure you want to delete this input?', 'warning', (confirmed) => {
      if (confirmed) {
        API.delete(
          `/parameters/${this.props.input.id}/`,
          () => {
            this.props.onInputDelete(this.props.input);
            this.props.history.push('/inputs');
          },
          API.defaultError,
        );
      }
    });
  };

  validateInputData = () => {
    if (!this.state.name) {
      const formHead = document.querySelector('.input-form');
      formHead.scrollIntoView({ behavior: 'smooth' });
      this.setState({ nameError: 'Name is required' });
      return false;
    }
    const whitespaceReg = new RegExp(/(\s|\)|\(|'|")/);
    if (whitespaceReg.test(this.state.name)) {
      this.setState({ nameError: 'Input name cannot contain spaces or characters other than - or _' });
      return false;
    }

    if (this.props.inputsByName && this.props.inputsByName[this.state.name]) {
      MAlert('Input cannot contain itself as a nested input', 'Error', 'error');
      return false;
    }

    return true;
  };

  getWidth = () => {
    if (this.widthRef.current) {
      return this.widthRef.current.clientWidth;
    } else {
      return 0;
    }
  };
}

InputForm.propTypes = {
  allowNameChange: PropTypes.bool,
  dataSources: PropTypes.array,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  existingInputs: PropTypes.object,
  isFetching: PropTypes.bool,
  onInputAdd: PropTypes.func,
  onInputDelete: PropTypes.func,
  onInputUpdate: PropTypes.func,
  input: PropTypes.object,
  saveButtonText: PropTypes.string,
  flags: PropTypes.object,
  onClose: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  toggleIsChanged: PropTypes.func,
  isChanged: PropTypes.bool,
  ui: PropTypes.object,
  history: PropTypes.object,
  closeSidepane: PropTypes.func,
  showDelete: PropTypes.bool,
  onCloseDataTip: PropTypes.string,
  showClickableCloseIcon: PropTypes.bool,
  hideHeader: PropTypes.bool,
  linkDisabled: PropTypes.bool,
  showCancel: PropTypes.bool,
  noBackground: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  buttonWidth: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  inputsByName: PropTypes.object,
  invalidateInputs: PropTypes.func,
  contentReferers: PropTypes.array,
};

InputForm.contextType = UserContext;

function InputFormWrapper(props) {
  const [query, setQuery] = useState(props.input?.query_obj?.query_string ?? '');
  const [inputType, setInputType] = useState(props.input?.type ?? null);
  const inputValues = useSelector((state) => state.inputValuesByEntity?.[`input_${props.input?.id ?? 'new'}`]);
  const {
    isLoading: areQueryInputsLoading,
    inputsByName: queryInputsByName,
    invalidate,
  } = useQueryInputList(query, inputType, inputValues);
  const { invalidateAll: invalidateTemplateContent } = useTemplateContent();

  const { data: contentReferers } = useDynamicContentReferers(props.input?.id);

  const handleInvalidateInputs = () => {
    invalidate();
    invalidateTemplateContent();
  };

  return (
    <InputForm
      {...props}
      query={query}
      setQuery={setQuery}
      inputType={inputType}
      setInputType={setInputType}
      areQueryInputsLoading={areQueryInputsLoading}
      inputsByName={queryInputsByName}
      invalidateInputs={handleInvalidateInputs}
      contentReferers={contentReferers}
    />
  );
}

InputFormWrapper.propTypes = {
  input: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(withRouter(withLDConsumer()(InputFormWrapper)));
