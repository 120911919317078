import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Connector from 'components/producer/conditions/Connector';
import ConditionGroupItem from 'components/producer/conditions/ConditionGroupItem';
import PopupMenu from 'components/shared/PopupMenu';
import ConditionSelector from './ConditionSelector';

const ConditionGroups = ({ conditionClauses, conditionsInTemplate, dynamicContentById, conditionsById, onChange }) => {
  if (!(conditionClauses?.or?.length > 0)) {
    conditionClauses = { or: [{ and: [] }] };
  }

  const handleGroupChange = (orIndex, updatedAnd) => {
    if (updatedAnd?.length > 0) {
      const updatedClauses = {
        or: [...conditionClauses.or],
      };
      updatedClauses.or[orIndex] = { and: updatedAnd };
      onChange(updatedClauses);
    } else {
      handleGroupDelete(orIndex);
    }
  };

  const handleGroupAdd = () => {
    const updatedClauses = {
      or: [...conditionClauses.or, { and: [] }],
    };
    onChange(updatedClauses);
  };

  const handleGroupDelete = (orIndex) => {
    const updatedClauses = {
      or: conditionClauses.or.filter((val, index) => index !== orIndex),
    };
    onChange(updatedClauses);
  };

  const or = conditionClauses.or;
  return (
    <div className="flex gap-6 flex-col">
      {or.map((clause, index) => (
        <div key={index} className="flex flex-col gap-2">
          <div className="flex items-center gap-1.5 text-matik-black group">
            <div className="rounded-md border border-grey-300 bg-grey-50 py-1.5 px-2.5 leading-none">
              {index === 0 ? 'If' : 'Or'}
            </div>
            <div className="grow">Keep this slide if these conditions are true</div>
            {clause.and?.length > 0 && (
              <div className="group-hover:visible invisible">
                <Button category="tertiary" onClick={() => handleGroupDelete(index)}>
                  <Icon name="trash_can" size={16} />
                </Button>
              </div>
            )}
          </div>
          <div>
            <ConditionGroup
              and={clause.and}
              dynamicContentById={dynamicContentById}
              conditionsById={conditionsById}
              conditionsInTemplate={conditionsInTemplate}
              onChange={(updatedAnd) => handleGroupChange(index, updatedAnd)}
            />
          </div>
        </div>
      ))}
      {or[or.length - 1].and.length > 0 && (
        <div>
          <Button category="secondary" onClick={handleGroupAdd}>
            <div className="flex items-center gap-1">
              <Icon name="add_circle" size={20} /> <div>Add Group</div>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
ConditionGroups.propTypes = {
  conditionClauses: PropTypes.shape({
    or: PropTypes.arrayOf(
      PropTypes.shape({
        and: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }),
  dynamicContentById: PropTypes.object,
  conditionsById: PropTypes.object,
  conditionsInTemplate: PropTypes.array,
  onChange: PropTypes.func,
};

export default ConditionGroups;

const ConditionGroup = ({ and, dynamicContentById, conditionsById, conditionsInTemplate, onChange }) => {
  const handleAdd = (conditionId) => {
    onChange([...and, conditionId]);
  };
  const handleDelete = (conditionId) => {
    const updatedAnd = and.filter((id) => id !== conditionId);
    onChange(updatedAnd);
  };
  return (
    <div className="grid grid-cols-[auto_1fr]">
      {and.map((conditionId, index) => (
        <Fragment key={`${conditionId},${index}`}>
          <div className="flex">
            <Connector
              className="w-9 h-full"
              right
              down
              roundedDownRight={index === 0}
              label={index > 0 ? 'And' : null}
              up={index > 0}
              labelClassName="w-9"
            />
            <Connector className="w-3 h-full" right left />
          </div>
          <div className="py-1 truncate">
            <ConditionGroupItem
              condition={conditionsById[conditionId] || { id: conditionId, name: 'Condition is inaccessible' }}
              dynamicContentById={dynamicContentById}
              onDeleteClick={() => handleDelete(conditionId)}
            />
          </div>
        </Fragment>
      ))}
      {and.length > 0 ? (
        <div>
          <ConditionSelectorPopup
            conditionsById={conditionsById}
            conditionsInTemplate={conditionsInTemplate}
            onAdd={handleAdd}
          >
            <Connector
              className="w-9"
              up
              label={<Icon size={16} name="plus" />}
              labelClassName="w-9 text-matik-black"
            />
          </ConditionSelectorPopup>
        </div>
      ) : (
        <div className="col-span-2">
          <ConditionSelectorPopup
            conditionsById={conditionsById}
            conditionsInTemplate={conditionsInTemplate}
            onAdd={handleAdd}
          >
            <Button category="secondary" tabIndex={-1}>
              <div className="flex items-center gap-1">
                <Icon name="plus" size={16} /> <div>Add Condition</div>
              </div>
            </Button>
          </ConditionSelectorPopup>
        </div>
        /* drag n drop not implemented yet 
        <div className="flex items-center justify-center gap-2.5 border border-1 border-grey-300 border-dashed col-span-2 rounded-md bg-matik-white px-8 py-2">
          <div className="text-grey-500 flex flex-row items-center gap-1.5">
            <Icon name="conditional" size={16} /> <div>Drag Condition here or</div>
          </div>
          <Button category="secondary" onClick={() => handleAdd(101)}>
            <div className="flex items-center gap-1">
              <Icon name="plus" size={16} /> <div>Add Condition</div>
            </div>
          </Button>
        </div>
        */
      )}
    </div>
  );
};
ConditionGroup.displayName = 'ConditionGroups.ConditionGroup';
ConditionGroup.propTypes = {
  and: PropTypes.arrayOf(PropTypes.number),
  dynamicContentById: PropTypes.object,
  conditionsById: PropTypes.object,
  conditionsInTemplate: PropTypes.array,
  onChange: PropTypes.func,
};

const ConditionSelectorPopup = ({ conditionsById, conditionsInTemplate, onAdd, children }) => {
  const menuRef = useRef();
  const handleSelect = (condition) => {
    menuRef.current.close();
    onAdd(condition.id);
  };
  return (
    <PopupMenu className="inline-block" ref={menuRef} position="y" trigger={children}>
      <div className="w-[592px] rounded-md border border-grey-300 bg-matik-white shadow-m">
        <ConditionSelector
          conditions={Object.values(conditionsById)}
          conditionsInTemplate={conditionsInTemplate}
          onSelect={handleSelect}
        />
      </div>
    </PopupMenu>
  );
};
ConditionSelectorPopup.propTypes = {
  conditionsById: PropTypes.object,
  conditionsInTemplate: PropTypes.array,
  onAdd: PropTypes.func,
  children: PropTypes.any,
};
