import React, { useState, useEffect, useRef } from 'react';
import 'react-autocomplete-input/dist/bundle.css';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';
import TabItem from 'components/lib/TabItem';
import Tabs from 'components/lib/Tabs';
import IconPill from 'components/lib/IconPill';
import Button from 'components/lib/Button';
import { Form } from 'react-bulma-components';
import { CreatableSelect, Select, MenuNoSearch } from 'components/shared/FormSelect';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import API from 'lib/api';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import Indicator from 'components/lib/Indicator';
import { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { openSidepane } from 'redux/ui/action';
function EmailSenderFormField({
  onFromFieldChange,
  from,
  sendgridInfo,
  fromEmailErrorMsg,
  replyEmailErrorMsg,
  fallbackEmailErrorMsg,
  openAuthModal,
  enterprise,
  bulkedInput,
  allDynamicContent,
  onContentClick,
  startingSenderType,
  dynamicSenderDisabled,
}) {
  const [showField, setShowField] = useState(false);
  const [activeTab, setActiveTab] = useState(from.senderType === 'static' ? 'static' : 'dynamic');

  useEffect(() => {
    if (dynamicSenderDisabled) {
      setActiveTab('static');
    } else {
      setActiveTab(from.senderType === Constants.RecipientFieldTypes.STATIC ? 'static' : 'dynamic');
    }
  }, [dynamicSenderDisabled]);

  const toggleExpandField = () => {
    setShowField(!showField);
  };

  const changeTab = (tabName) => {
    setActiveTab(tabName.toLowerCase());
    if (tabName.toLowerCase() === Constants.RecipientFieldTypes.STATIC) {
      onFromFieldChange('senderType', Constants.RecipientFieldTypes.STATIC);
    } else {
      const dynamicSenderType =
        startingSenderType && startingSenderType !== Constants.RecipientFieldTypes.STATIC
          ? startingSenderType
          : Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
      onFromFieldChange('senderType', dynamicSenderType);
    }
  };

  const getEmailOptions = () => {
    let options = [];
    if (Array.isArray(sendgridInfo?.auth?.subusers)) {
      options = sendgridInfo?.auth?.subusers.map((subuser) => ({ value: subuser, label: subuser }));
    }
    options.push({ value: 'no-reply@matik.io', label: 'no-reply@matik.io' });
    return options;
  };

  const domainValidated = sendgridInfo?.auth?.domain_validated;

  let authErrorMsg = '';

  if (!domainValidated) {
    authErrorMsg = (
      <p className="help is-danger">
        To change the from email you need to{' '}
        <span className="inline-warning-link" onClick={openAuthModal}>
          validate your domain
        </span>{' '}
        first
      </p>
    );
  }

  const formatCreateLabel = (typedVal) => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <IconPill iconName="plus" iconTheme="regular" size="xs" theme="square" />
      &nbsp;&nbsp;Add {typedVal} to your enterprise
    </span>
  );

  const chevronName = showField ? 'chevron_down' : 'chevron_right';
  const headerClass = showField ? '' : 'bottom-radius';
  let statusColor = 'red-500';

  if (from.senderType === Constants.RecipientFieldTypes.STATIC) {
    statusColor = from.fromEmail !== '' && from.replyEmail !== '' && from.name !== '' ? 'green-500' : 'red-500';
  } else {
    statusColor = from.senderContent ? 'green-500' : 'red-500';
  }

  let dynamicSenderDisabledTooltip = '';
  if (dynamicSenderDisabled) {
    dynamicSenderDisabledTooltip =
      'To use a Dynamic Sender, first select Recipients and specify the input you’d like to personalize on.';
  }

  return (
    <>
      <div className={`email-form-field-header ${headerClass}`} onClick={toggleExpandField}>
        <div className="email-form-field-header-left">
          <Indicator color={statusColor} />
          <span className="ml-2">Sender</span>
        </div>
        <Icon name={chevronName} size={16} theme="filled"></Icon>
      </div>
      {showField && (
        <div className="email-settings-collapsible-field">
          <div className="email-form-tabs my-2">
            <Tabs>
              <div data-tooltip-content={dynamicSenderDisabledTooltip} data-tooltip-id="matik-tooltip" className="flex">
                <TabItem
                  label="Dynamic"
                  onClick={changeTab}
                  isActive={activeTab === 'dynamic'}
                  status={dynamicSenderDisabled ? 'disabled' : 'default'}
                />
              </div>
              <TabItem label="Static" onClick={changeTab} isActive={activeTab === 'static'} />
            </Tabs>
          </div>
          {activeTab === 'static' && (
            <StaticSender
              from={from}
              domainValidated={domainValidated}
              getEmailOptions={getEmailOptions}
              authErrorMsg={authErrorMsg}
              fromEmailErrorMsg={fromEmailErrorMsg}
              replyEmailErrorMsg={replyEmailErrorMsg}
              sendgridInfo={sendgridInfo}
              onFromFieldChange={onFromFieldChange}
              enterprise={enterprise}
              formatCreateLabel={formatCreateLabel}
            />
          )}
          {activeTab === 'dynamic' && (
            <DynamicSender
              bulkedInput={bulkedInput}
              from={from}
              onFromFieldChange={onFromFieldChange}
              fallbackEmailErrorMsg={fallbackEmailErrorMsg}
              authErrorMsg={authErrorMsg}
              enterprise={enterprise}
              allDynamicContent={allDynamicContent}
              onContentClick={onContentClick}
              domainValidated={domainValidated}
              getEmailOptions={getEmailOptions}
              formatCreateLabel={formatCreateLabel}
              sendgridInfo={sendgridInfo}
            />
          )}
        </div>
      )}
    </>
  );
}

export default EmailSenderFormField;

EmailSenderFormField.propTypes = {
  onFromFieldChange: PropTypes.func,
  from: PropTypes.object,
  sendgridInfo: PropTypes.object,
  fromEmailErrorMsg: PropTypes.string,
  replyEmailErrorMsg: PropTypes.string,
  openAuthModal: PropTypes.func,
  enterprise: PropTypes.object,
  bulkedInput: PropTypes.string,
  allDynamicContent: PropTypes.object,
  onContentClick: PropTypes.func,
  startingSenderType: PropTypes.string,
  fallbackEmailErrorMsg: PropTypes.string,
  dynamicSenderDisabled: PropTypes.bool,
};

const StaticSender = ({
  from,
  sendgridInfo,
  domainValidated,
  authErrorMsg,
  fromEmailErrorMsg,
  replyEmailErrorMsg,
  getEmailOptions,
  onFromFieldChange,
  enterprise,
  formatCreateLabel,
}) => {
  const [invalidFromEmail, setInvalidFromEmail] = useState('');

  const onStaticSenderChange = async (fieldName, fieldValue) => {
    if (fieldName === 'fromEmail') {
      if (utils.isEmailValid(fieldValue)) {
        setInvalidFromEmail(false);
        const newSubuserAdded =
          fieldValue !== 'no-reply@matik.io' &&
          (sendgridInfo?.auth?.subusers?.includes(fieldValue) === false || !sendgridInfo?.auth?.subusers);
        if (newSubuserAdded) {
          const data = { subuser: fieldValue };
          await API.post(`/enterprises/${enterprise.id}/add_mail_subuser/`, data, () => {}, API.defaultError);
        }
      } else {
        setInvalidFromEmail(fieldValue);
      }
    }
    onFromFieldChange(fieldName, fieldValue, from.senderType);
  };

  return (
    <>
      <Form.Field>
        <Form.Label>From Name</Form.Label>
        <Form.Control>
          <Form.Input
            name="name"
            type="text"
            placeholder="Enter From Name"
            value={from.name}
            onChange={(e) => onStaticSenderChange(e.target.name, e.target.value)}
          ></Form.Input>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>From Email</Form.Label>
        <Form.Control>
          <CreatableSelect
            name="fromEmail"
            placeholder="Select From Email"
            onChange={(selection) => onStaticSenderChange('fromEmail', selection.value)}
            isDisabled={!domainValidated}
            options={getEmailOptions()}
            value={from.fromEmail ? { value: from.fromEmail, label: from.fromEmail } : null}
            isMulti={false}
            styles={{ backgroundColor: 'white' }}
            classNamePrefix="matik-select"
            formatCreateLabel={formatCreateLabel}
          />
        </Form.Control>
        {authErrorMsg}
        {fromEmailErrorMsg && <p className="help is-danger">{fromEmailErrorMsg}</p>}
        {invalidFromEmail && <p className="help is-danger">{`${invalidFromEmail} is not a valid email address.`}</p>}
      </Form.Field>
      <Form.Field>
        <Form.Label>Reply To Email</Form.Label>
        <Form.Control>
          <Form.Input
            name="replyEmail"
            type="email"
            placeholder="Enter Reply to Email"
            value={from.replyEmail}
            onChange={(e) => onStaticSenderChange(e.target.name, e.target.value)}
          ></Form.Input>
        </Form.Control>
        {replyEmailErrorMsg && <p className="help is-danger">{replyEmailErrorMsg}</p>}
      </Form.Field>
    </>
  );
};

StaticSender.propTypes = {
  from: PropTypes.object,
  domainValidated: PropTypes.bool,
  getEmailOptions: PropTypes.func,
  authErrorMsg: PropTypes.node,
  fromEmailErrorMsg: PropTypes.string,
  replyEmailErrorMsg: PropTypes.string,
  sendgridInfo: PropTypes.object,
  onFromFieldChange: PropTypes.func,
  enterprise: PropTypes.object,
  formatCreateLabel: PropTypes.func,
};

const DynamicSender = ({
  from,
  domainValidated,
  authErrorMsg,
  bulkedInput,
  onFromFieldChange,
  fallbackEmailErrorMsg,
  enterprise,
  allDynamicContent,
  onContentClick,
  getEmailOptions,
  formatCreateLabel,
  sendgridInfo,
}) => {
  const [invalidFallbackEmail, setInvalidFallbackEmail] = useState('');
  const [fallbackChecked, setFallbackChecked] = useState(Boolean(from.fallbackEmail));
  const selectRef = useRef(null);
  const dispatch = useDispatch();

  const senderDynamicContentList = Object.values(allDynamicContent).filter(
    (content) => content.dynamic_content_type === Constants.DynamicContentTypes.SENDER,
  );

  const dynamicSenderTypeOptions = [
    { label: 'Personalized based on input', value: Constants.RecipientFieldTypes.DYNAMIC_BULKED },
    { label: 'Same for all recipients', value: Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED },
  ];

  const startingDynamicSenderTypeValue =
    from.senderType === Constants.RecipientFieldTypes.DYNAMIC_BULKED
      ? dynamicSenderTypeOptions[0]
      : dynamicSenderTypeOptions[1];

  const onDynamicSenderTypeChange = (option) => {
    onFromFieldChange('senderType', option.value);
  };

  const onDynamicSenderChange = (data) => {
    onFromFieldChange('senderContent', data.value, 'dynamic');
  };

  const getStartingDynamicSenderValue = () => {
    let startingDynamicSenderValue;
    if (from.senderContent) {
      const currentOptions = getDynamicSenderOptions();
      const selectedContent = currentOptions.filter((content) => content.value === from.senderContent)[0];
      startingDynamicSenderValue = selectedContent;
      if (startingDynamicSenderValue) {
        return startingDynamicSenderValue;
      } else {
        onFromFieldChange('senderContent', null);
      }
    }
    return null;
  };

  const getDynamicSenderOptions = () => {
    if (from.senderType === Constants.RecipientFieldTypes.DYNAMIC_BULKED) {
      const senderContentMatchingInput = senderDynamicContentList.filter((senderDynamicContent) => {
        const inputList = Object.values(senderDynamicContent.parameters);
        return inputList.some((input) => input.name === bulkedInput);
      });
      return senderContentMatchingInput.map((senderDynamicContent) => ({
        label: senderDynamicContent.name,
        value: senderDynamicContent.id,
      }));
    } else if (
      from.senderType === Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED ||
      from.senderType === Constants.RecipientFieldTypes.STATIC
    ) {
      const senderContentWithoutMatchingInput = senderDynamicContentList.filter((senderDynamicContent) => {
        const inputList = Object.values(senderDynamicContent.parameters);
        return !inputList.some((input) => input.name === bulkedInput);
      });
      return senderContentWithoutMatchingInput.map((senderDynamicContent) => ({
        label: senderDynamicContent.name,
        value: senderDynamicContent.id,
      }));
    }
  };

  const formatSenderOptionLabel = (option, { context }) => {
    return (
      <div className="recipient-select-option">
        <div className="recipient-select-option-left">
          <IconPill iconName="sender" iconTheme="regular" size="s" color="white" theme="square" />
          <span className="mls">{option.label}</span>
        </div>
        {context === 'value' && (
          <Button category="tertiary" onClick={() => editSenderContentOnClick(option.label)}>
            <Icon name="pencil" size={16} theme="regular" />
          </Button>
        )}
      </div>
    );
  };

  const editSenderContentOnClick = (contentName) => {
    const selectedContent = senderDynamicContentList.filter((content) => content.name === contentName)[0];
    if (selectedContent) {
      const contentTag = utils.getTagFromSingleDynamicContent(selectedContent);
      onContentClick(contentTag);
    }
  };

  const onFallbackEmailChange = async (fieldName, fieldValue) => {
    if (fieldName === 'fallbackCheckbox') {
      setFallbackChecked(!fallbackChecked);
      onFromFieldChange('fallbackEmail', '');
    } else {
      if (utils.isEmailValid(fieldValue)) {
        setInvalidFallbackEmail(false);
        const newSubuserAdded =
          fieldValue !== 'no-reply@matik.io' &&
          (sendgridInfo?.auth?.subusers?.includes(fieldValue) === false || !sendgridInfo?.auth?.subusers);
        if (newSubuserAdded) {
          const data = { subuser: fieldValue };
          await API.post(`/enterprises/${enterprise.id}/add_mail_subuser/`, data, () => {}, API.defaultError);
        }
      } else {
        setInvalidFallbackEmail(fieldValue);
      }
      onFromFieldChange(fieldName, fieldValue, 'dynamic');
    }
  };

  const addSenderContentOnClick = () => {
    selectRef.current?.blur();
    dispatch(openSidepane(null, 'template-sidepane', Constants.DynamicContentTypes.SENDER));
  };

  const MenuListFooter = ({ onClick }) => {
    return (
      <span className="select-dropdown-footer" onClick={onClick}>
        <IconPill iconName="plus" iconTheme="regular" size="xs" theme="square" />
        Create New Dynamic Content
      </span>
    );
  };

  MenuListFooter.propTypes = {
    onClick: PropTypes.func,
  };

  const MenuList = (props) => {
    const { MenuListFooter = null } = props.selectProps.components;

    return (
      <components.MenuList {...props}>
        {props.children}
        {MenuListFooter}
      </components.MenuList>
    );
  };

  MenuList.propTypes = {
    selectProps: PropTypes.object,
    children: PropTypes.array,
  };

  const checkboxLabel = <span className="email-form-help-text mb-2">Use Fallback Sender</span>;
  const checkboxTooltip =
    'Designate a fallback to use in cases where the dynamic sender returns a non-authenticated email. (If no fallback is designated, emails with a non-authenticated sender will simply not send.)';
  return (
    <div className="email-form-sub-field">
      <Select
        value={startingDynamicSenderTypeValue}
        classNamePrefix="matik-select"
        isDisabled={bulkedInput === 'None' || !bulkedInput}
        onChange={onDynamicSenderTypeChange}
        options={dynamicSenderTypeOptions}
        isSearchable={false}
        openMenuOnClick={true}
        componentsToAdd={{ Menu: MenuNoSearch }}
        id="sender-type"
      />
      <div className="my-2"></div>
      <Select
        reactSelectRef={selectRef}
        value={getStartingDynamicSenderValue()}
        classNamePrefix="matik-select"
        onChange={onDynamicSenderChange}
        options={getDynamicSenderOptions()}
        placeholder="Select Dynamic Sender"
        openMenuOnClick={false}
        formatOptionLabel={formatSenderOptionLabel}
        name="dynamic-sender"
        componentsToAdd={{ MenuList, MenuListFooter: <MenuListFooter onClick={addSenderContentOnClick} /> }}
      />
      <div className="my-2">
        <CheckboxWithLabel
          checked={fallbackChecked}
          label={checkboxLabel}
          name="fallbackCheckbox"
          id="fallback-checkbox"
          onChange={(e) => onFallbackEmailChange(e.target.name, e.target.value)}
          tooltip={checkboxTooltip}
          dataPlace="top"
          className="w-fit"
        />
      </div>
      <CreatableSelect
        name="fallbackEmail"
        placeholder="Select Authenticated Email"
        onChange={(selection) => onFallbackEmailChange('fallbackEmail', selection.value)}
        isDisabled={!domainValidated || !fallbackChecked}
        options={getEmailOptions()}
        value={from.fallbackEmail ? { value: from.fallbackEmail, label: from.fallbackEmail } : null}
        isMulti={false}
        styles={{ backgroundColor: 'white' }}
        classNamePrefix="matik-select"
        formatCreateLabel={formatCreateLabel}
      />
      {authErrorMsg}
      {fallbackEmailErrorMsg && <p className="help is-danger">{fallbackEmailErrorMsg}</p>}
      {invalidFallbackEmail && (
        <p className="help is-danger">{`${invalidFallbackEmail} is not a valid email address.`}</p>
      )}
    </div>
  );
};

DynamicSender.propTypes = {
  bulkedInput: PropTypes.string,
  from: PropTypes.object,
  onFromFieldChange: PropTypes.func,
  fallbackEmailErrorMsg: PropTypes.string,
  authErrorMsg: PropTypes.string,
  enterprise: PropTypes.object,
  allDynamicContent: PropTypes.object,
  onContentClick: PropTypes.func,
  domainValidated: PropTypes.bool,
  getEmailOptions: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  sendgridInfo: PropTypes.object,
};
