import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Level, Heading, Form } from 'react-bulma-components';
import { Prompt } from 'react-router-dom';
import utils from '../../../lib/utils';
import API from '../../../lib/api';
import { MConfirm, MInsufficientPermissions, MConnect } from '../../shared/Alerts';
import Constants from '../../Constants';
import subcontent_icon from '../../../images/subcontent.svg';
import format_icon from '../../../images/format.svg';
import Icon from '../../lib/Icon';
import useTemplate from 'lib/hooks/useTemplate';
import { UserContext } from 'components/UserContext';
import Button from '../../lib/Button';
import { useTemplateTagMutator } from '../../../lib/hooks/useTemplateTag';
import FormChange from '../../../lib/formChange';
import InputDynamicContent from '../dynamicContent/InputDynamicContent';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
import { ContentContext } from './ContentContext';

function EditTag({ currentTemplate, selectedSlide, handleClick }) {
  const contentContext = useContext(ContentContext);

  const [format, setFormat] = useState(contentContext.selectedSlideTag.format);
  const [subcontent, setSubcontent] = useState(contentContext.selectedSlideTag.subcontent);
  const [tagContent, setTagContent] = useState(contentContext.selectedSlideTag.contentName);
  const [isLoading, setIsLoading] = useState(false);
  const formChange = useRef(new FormChange({ format, subcontent, tagContent }));

  const userContext = useContext(UserContext);

  const { create, update, deleteTag } = useTemplateTagMutator();
  const { isLoading: isDynamicContentLoading, dynamicContentById } = useAllDynamicContentById();

  const { invalidate } = useTemplate();

  const onInvalidateTemplate = () => {
    if (currentTemplate) {
      invalidate(currentTemplate.id);
    }
  };

  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;
    };
  }, []);

  const exitEdit = (e) => {
    e.preventDefault();
    contentContext.deselectSlideTag();
  };

  const needsToConnect = () => {
    if (
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      const microsoftIntegration = utils.microsoftIntegration(userContext.user);
      return !microsoftIntegration || !microsoftIntegration.has_necessary_scopes;
    }
    return false;
  };

  const onAdd = (response) => {
    setIsLoading(false);
    onInvalidateTemplate();
    formChange.current.updateInitialValues({ format, subcontent, tagContent });
    const newTagBody = response.tag;
    contentContext.selectSlideTag(
      newTagBody,
      contentContext.selectedSlideTag.elementId,
      contentContext.selectedSlideTag.selection,
    );
  };

  const submitError = (err) => {
    setIsLoading(false);
    API.defaultError(err);
  };

  const onContentEditClick = (content) => {
    const formattedContent = { ...content, matchingContent: content };
    handleClick(formattedContent);
  };

  const onUpdate = () => {
    setIsLoading(false);
    onInvalidateTemplate();
    formChange.current.updateInitialValues({ format, subcontent, tagContent });
    contentContext.selectSlideTag(
      utils.tagFromPartsIncludingName(tagContent, contentContext.selectedSlideTag.tagName, subcontent, format),
      contentContext.selectedSlideTag.elementId,
      contentContext.selectedSlideTag.selection,
    );
  };

  const updateTag = (e = null) => {
    e?.preventDefault();
    if (needsToConnect()) {
      return connectIntegration();
    }
    setIsLoading(true);
    const tagBody = utils.tagFromPartsIncludingName(
      tagContent,
      contentContext.selectedSlideTag.tagName,
      subcontent,
      format,
    );
    if (!contentContext.selectedSlideTag.contentName) {
      create(
        tagBody,
        contentContext.selectedSlideTag.elementId,
        currentTemplate,
        selectedSlide,
        contentContext.selectedSlideTag.selectedText,
      )
        .then(onAdd)
        .catch(submitError);
    } else {
      update(
        tagBody,
        contentContext.selectedSlideTag.elementId,
        contentContext.selectedSlideTag.fullTag(),
        currentTemplate,
        selectedSlide,
      )
        .then(onUpdate)
        .catch(submitError);
    }
  };

  const reconnectIntegration = () => {
    utils.reconnectMicrosoft(userContext.user, userContext.updateUser, updateTag, showMSPermsAlert);
  };

  const showMSPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message =
      'To edit the PowerPoint template from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', reconnectIntegration);
  };

  const connectIntegration = () => {
    const title = 'Connect to Microsoft 365',
      message = 'You need to connect to Microsoft 365 before you can edit this PowerPoint template',
      type = 'microsoft',
      confirm = 'Connect to Microsoft 365';
    const callback = (confirm) => {
      confirm && utils.connectMicrosoft(userContext.user, userContext.updateUser, updateTag, showMSPermsAlert);
    };
    MConnect(title, message, type, callback, confirm);
  };

  const onDelete = () => {
    setIsLoading(false);
    onInvalidateTemplate();

    contentContext.deselectSlideTag(null);
  };

  const onDeleteTag = (e) => {
    e.preventDefault();
    if (needsToConnect()) {
      return connectIntegration();
    }
    setIsLoading(true);
    MConfirm(
      'Deleting Tag',
      'Are you sure you want to remove this tag?',
      'warning',
      (confirmed) => {
        if (confirmed) {
          const tagBody = utils.tagFromPartsIncludingName(
            tagContent,
            contentContext.selectedSlideTag.tagName,
            subcontent,
            format,
          );
          deleteTag(contentContext.selectedSlideTag.elementId, tagBody, currentTemplate, selectedSlide)
            .then(onDelete)
            .catch(submitError);
        }
      },
      'Delete',
    );
  };

  const onChangeContent = (contentToUpdate) => {
    setTagContent(contentToUpdate.name);
  };

  const onChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const val = e.target.value;
    if (name === 'subcontent') {
      setSubcontent(val);
    } else if (name === 'format') {
      setFormat(val);
    }
  };

  const isChanged = formChange.current.hasChanged({ format, subcontent, tagContent });
  if (isChanged) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = undefined;
  }
  let buttonStatus = 'disabled';
  if (isLoading) {
    buttonStatus = 'loading';
  } else if (isChanged) {
    buttonStatus = 'default';
  }

  const contentObj = Object.values(dynamicContentById || {}).find((dc) => dc.name === tagContent);
  return (
    <div className="edit-tag">
      <Prompt when={isChanged} message="Are you sure you want to navigate away? There are unsaved changes." />
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <button className="button is-secondary is-round-button" onClick={exitEdit}>
              <Icon name="chevron_left" size={16} theme="regular" />
            </button>
          </Level.Item>
          <Level.Item>
            <Heading size={5} subtitle>
              Edit Tag
            </Heading>
          </Level.Item>
        </Level.Side>
        <Level.Side align="right">
          <Level.Item>
            <Button
              category="tertiary"
              onClick={onDeleteTag}
              data-tooltip-content="Delete"
              data-tooltip-id="matik-tooltip"
            >
              <Icon name="trash_can" size={20} />
            </Button>
          </Level.Item>
        </Level.Side>
      </Level>
      <InputDynamicContent
        content={contentObj}
        allDynamicContentNamesById={dynamicContentById}
        onEditClick={onContentEditClick}
        isLoading={isDynamicContentLoading}
        onChange={onChangeContent}
        acceptedContentTypes={Object.values(Constants.DynamicContentTypes)}
      />
      <Level className="mtm">
        <Level.Side align="left">
          <Level.Item>
            <img src={subcontent_icon} alt="Subcontent" />
          </Level.Item>
          <Level.Item>
            <div>
              <p>Subcontent</p>
              <div>
                <Form.Help>The column from this content we should insert</Form.Help>
              </div>
            </div>
          </Level.Item>
        </Level.Side>
        <Level.Side align="right"> </Level.Side>
      </Level>
      <Form.Field>
        <Form.Control>
          <Form.Input name="subcontent" placeholder="Subcontent" value={subcontent} onChange={onChange} />
        </Form.Control>
      </Form.Field>
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <img src={format_icon} alt="Format" />
          </Level.Item>
          <Level.Item>
            <div>
              <p>Format</p>
              <div>
                <Form.Help>How we should format this value</Form.Help>
              </div>
            </div>
          </Level.Item>
        </Level.Side>
        <Level.Side align="right"> </Level.Side>
      </Level>
      <Form.Field>
        <Form.Control>
          <Form.Input name="format" placeholder="Format" value={format} onChange={onChange} />
        </Form.Control>
      </Form.Field>
      <Form.Field kind="group">
        <Form.Control>
          <button className={'button is-secondary'} onClick={exitEdit}>
            Cancel
          </button>
        </Form.Control>
        <Form.Control>
          <Button category="primary" onClick={updateTag} status={buttonStatus}>
            Save
          </Button>
        </Form.Control>
      </Form.Field>
    </div>
  );
}

EditTag.propTypes = {
  currentTemplate: PropTypes.object,
  selectedSlide: PropTypes.object,
  handleClick: PropTypes.func,
};

export default EditTag;
