import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual, find } from 'lodash';
import Constants from '../Constants';
import inputs from '../../lib/inputs';
import utils from '../../lib/utils';
import teams from '../../lib/teams';
import { EndUserCreateContext } from '../consumer/EndUserCreateContext';
import { ReactComponent as BulkLogo } from '../../svg/bulk.svg';
import withUserContext from './WithUserContext';
import AuthTokenInput from './AuthTokenInput';
import QueryLoading from './QueryLoading';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { ReactComponent as InputMappingIcon } from '../../images/input_mapping.svg';
import ReactDOMServer from 'react-dom/server';
import UpsellGenericModal from '../payments/UpsellGenericModal';
import { MProductUpsell } from './Alerts';
import TabHeader from 'components/producer/templates/TabHeader';
import API from '../../lib/api';

import { getLoopedInfo } from 'lib/looping.js';
import Button from '../lib/Button';
import IconPill from '../lib/IconPill';
import MatikUserInputField from './paramFields/MatikUserInputField';
import CsvBulkUploadForm from './presentations/CsvBulkUploadForm';

const LoopingIcon = ({ input, bulkInputName, loopedInfo }) => {
  const dependsOnBulk = inputs.inputIsDependentOnBulkInput(input, bulkInputName);
  return (
    <div
      data-tooltip-place="right"
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={
        dependsOnBulk
          ? 'Each presentation in the bulk job will loop through all matching values of this input'
          : 'This input will be looped on, with customized content being generated for each of its provided values.'
      }
    >
      <IconPill color={loopedInfo?.color} size="m" theme="circle" iconName="loop" />
    </div>
  );
};
LoopingIcon.propTypes = {
  input: PropTypes.object,
  bulkInputName: PropTypes.string,
  loopedInfo: PropTypes.object,
};
const getLoopedInputInfo = (input, template, attachedTemplate) => {
  const loops = (template?.slide_loops || []).concat(attachedTemplate?.slide_loops || []);
  return getLoopedInfo(input, loops);
};

const TABS = { INPUTS: 'inputs', UPLOAD: 'upload' };

class FormFromInputs extends Component {
  render() {
    const tabs = [
      {
        label: 'Inputs',
        selected: this.props.activeTab === TABS.INPUTS,
        onClick: (e) => this.props.handleTabClick(e, TABS.INPUTS),
      },
      {
        label: 'CSV Upload',
        selected: this.props.activeTab === TABS.UPLOAD,
        onClick: (e) => this.props.handleTabClick(e, TABS.UPLOAD),
      },
    ];

    return (
      <React.Fragment>
        {!this.props.isReadOnly &&
          this.props.userContext.user?.enterprise?.enterprise_settings?.csv_bulk_enabled &&
          !this.props.test && <TabHeader tabs={tabs} />}
        {this.props.activeTab === TABS.UPLOAD ? (
          <CsvBulkUploadForm template={this.props.template} isReadOnly={this.props.isReadOnly} />
        ) : (
          <>
            {this.props.dataSourcesWithAuthToken &&
              this.props.dataSourcesWithAuthToken.map((dataSource) => this.renderAuthTokenInput(dataSource))}
            {utils.hasMatikUserInputs(this.props.inputs) && (
              <MatikUserInputField
                inputs={this.props.inputs}
                inputValues={this.props.inputValues}
                setInputOptionsFromQuery={this.setInputOptionsFromQuery}
                userContext={this.props.userContext}
                entityId={this.props.entityId}
                entityType={this.props.entityType}
                setUserBasedInputsIsLoading={this.props.setUserBasedInputsIsLoading}
                isPresentationView={this.props.isPresentationView}
              />
            )}
            {this.props.inputs.map(this.renderInput)}
          </>
        )}
      </React.Fragment>
    );
  }

  isBulkGenerationEnabledForEnterprise() {
    if (!(this.props.userContext && this.props.userContext.user && this.props.userContext.user.enterprise)) {
      return false;
    }

    return this.props.userContext.user.enterprise.enterprise_settings.bulk_generation_enabled && this.props.allowBulk;
  }

  isBulkGenerationEnabledForTier() {
    if (!(this.props.userContext && this.props.userContext.user && this.props.userContext.user.enterprise)) {
      return false;
    }

    return this.props.allowBulk && !teams.isTeamsUser(this.props.userContext.user.enterprise.plan_id);
  }

  renderInput = (input) => {
    const inputValue = this.props.inputValues[input.name];

    if (!inputValue) {
      return null;
    }
    let inputColor = null;
    let errorText = null;
    if (inputValue.error) {
      inputColor = 'danger';
      errorText = <Form.Help color="danger">{inputValue.error}</Form.Help>;
    }
    const nameLabel = input.display_name ? input.display_name : input.name;

    let bulkLogo = null;
    let isInputBulk = false;
    let tip = '';
    const inputNamesCheck = {};
    this.props.inputs.forEach((input) => (inputNamesCheck[input.name] = true));

    const loopedInfo = getLoopedInputInfo(input, this.props.template, this.props.attachedTemplate);

    let mappedIcon = null;
    const inputIsMapped =
      input.input_mapping &&
      this.props.isProducer &&
      !isEmpty(input.input_mapping) &&
      !isEqual(Object.keys(input.input_mapping)[0], Object.values(input.input_mapping)[0]);
    if (inputIsMapped) {
      mappedIcon = <InputMappingIcon className="icon mrs" />;
    }

    if (inputs.inputSupportsBulk(input, inputNamesCheck) && !loopedInfo && !this.props.isProducer) {
      isInputBulk = this.props.currentBulkInputName === input.name && !this.props.isTestSlide;
      if (this.props.template?.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
        if (isInputBulk) {
          tip = 'Click to return this to single generation';
        } else if (this.props.currentBulkInputName) {
          tip = 'Bulk presentations are being generated from another input';
        }

        const color = isInputBulk ? 'has-text-success' : '';
        const disableToggle = this.props.currentBulkInputName && !isInputBulk;

        bulkLogo =
          this.props.template && !this.props.isReadOnly ? (
            <div data-tooltip-id="matik-tooltip" data-tooltip-content={tip} data-tooltip-place="right">
              <Button
                type="button"
                category="tertiary"
                size="small"
                status={disableToggle ? 'disabled' : 'default'}
                onClick={this.onBulkInputToggle(input.name, !isInputBulk)}
              >
                <BulkLogo className={color} />
              </Button>
            </div>
          ) : null;
      } else if (this.props.template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
        if (isInputBulk) {
          tip = 'This is the bulk input for this email';
        }
        bulkLogo =
          this.props.template && !this.props.isReadOnly && isInputBulk ? (
            <Button type="button" category="tertiary" size="small" disabled={true} onClick={null}>
              <BulkLogo data-tooltip-content={tip} data-tooltip-id="matik-tooltip" />
            </Button>
          ) : null;
      }
    }

    let description;
    if (input.description) {
      if (utils.isValidJSON(input.description)) {
        const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(JSON.parse(input.description));
        description = parse(draftToHtml(descJSON));
      } else {
        description = input.description;
      }
    } else {
      description = 'No description';
    }

    let inputField = '';

    if (isInputBulk || loopedInfo) {
      inputField = inputs.getBulkFieldFromInputType(
        input,
        this.props.inputValues,
        inputColor,
        this.onChange,
        this.setInputOptionsFromQuery,
        this.queryError,
        this.fallback,
        this.beforeQuery,
        this.props.isReadOnly,
        this.props.currentBulkInputName,
      );
    } else {
      inputField = inputs.getFieldFromInputType(
        input,
        this.props.inputValues,
        inputColor,
        this.onChange,
        this.setInputOptionsFromQuery,
        this.queryError,
        this.fallback,
        this.beforeQuery,
        this.props.isReadOnly,
        true, // allowRelativeDates
        this.props.currentBulkInputName,
      );
    }

    let mappedValue;
    if (
      inputIsMapped &&
      inputField.props &&
      inputField.props.inputValues &&
      inputField.props.inputValues[input.name] &&
      inputField.props.inputValues[input.name]['mappedOptions']
    ) {
      if (Array.isArray(inputField.props.inputValues[input.name]['value'])) {
        mappedValue = inputField.props.inputValues[input.name]['value']
          .map((val) => inputField.props.inputValues[input.name]['mappedOptions'][val])
          .join(', ');
      } else {
        mappedValue =
          inputField.props.inputValues[input.name]['mappedOptions'][inputField.props.inputValues[input.name]['value']];
      }
    }

    const hideFormMatikUserInput =
      !this.props.isPresentationView && input.source_type === Constants.InputSources.MATIK_USER;

    return (
      <div key={input.name} className={`pb-3 ${hideFormMatikUserInput ? 'hidden' : ''}`}>
        <Form.Field
          className="input-field"
          onMouseOver={() => this.handleMouseEnter(input.name)}
          onMouseLeave={() => this.handleMouseLeave()}
        >
          <div className="is-flex is-vertical-centered gap-2">
            {loopedInfo && (
              <LoopingIcon input={input} bulkInputName={this.props.currentBulkInputName} loopedInfo={loopedInfo} />
            )}
            <div className="text-overflow-ellipsis">
              <Form.Label onClick={(e) => e.preventDefault()}>
                {mappedIcon} <span className="mrs">{nameLabel}</span>{' '}
                {this.isBulkGenerationEnabledForEnterprise() || !this.isBulkGenerationEnabledForTier() ? bulkLogo : ''}
                <QueryLoading
                  isLoading={inputValue.isLoading}
                  dataTS={inputValue.dataTS}
                  onSyncButtonClick={() =>
                    inputs.loadOptionsFromQuery(
                      input,
                      this.props.inputValues,
                      this.setInputOptionsFromQuery,
                      this.queryError,
                      this.fallback,
                      this.beforeQuery,
                      null,
                      this.props.currentBulkInputName,
                    )
                  }
                />
              </Form.Label>
              <Form.Help className="whitespace-normal" renderAs="div">
                {description}
              </Form.Help>
            </div>
          </div>
          <Form.Control>{inputField}</Form.Control>
          {inputIsMapped && mappedValue && (
            <Form.Help className="input-mapping">
              {inputField.props && inputField.props.input && inputField.props.input.input_mapping
                ? `Maps to ${Object.values(inputField.props.input.input_mapping)[0]}: ${mappedValue}`
                : ''}
            </Form.Help>
          )}
          {errorText}
        </Form.Field>
      </div>
    );
  };

  renderAuthTokenInput = (dataSource) => {
    const dynamicContent = Object.values(this.props.allDynamicContent || {}).filter(
      (dc) => dc.query_obj?.data_source?.id === dataSource.id,
    );
    return (
      <AuthTokenInput
        key={`${dynamicContent}_${dataSource}`}
        dynamicContent={dynamicContent}
        dataSource={dataSource}
        tokenError={this.props.tokenError}
        tokenSuccess={this.props.tokenSuccess}
        tokenLoading={this.props.tokenLoading}
        onAuthTokenChange={this.onAuthTokenChange}
        onTestAccess={this.onTestAccess}
      />
    );
  };

  onTestAccess = (e, dataSource, dynamicContent) => {
    e.preventDefault();
    const apiInputValues = {};
    for (let key in this.props.inputValues) {
      if (key in dynamicContent.parameters) {
        if (this.props.inputValues[key].value) {
          apiInputValues[key] = this.props.inputValues[key];
        }
      }
    }
    this.props.onTestAccess(
      e,
      dataSource,
      dynamicContent,
      apiInputValues,
      this.props.authTokenByDynamicContent[dynamicContent.name],
    );
  };

  beforeQuery = (inputName) => {
    const updatedInputValues = Object.assign({}, this.props.inputValues);
    updatedInputValues[inputName].isLoading = true;
    this.props.updateInputValues(updatedInputValues);
  };

  fallback = (inputsStillRequired) => `Depends on ${utils.convertArrayToEnglish(inputsStillRequired)}...`;

  queryError = (err, inputName) => {
    const updatedInputValues = this.props.inputValues;
    if (updatedInputValues[inputName]) {
      updatedInputValues[inputName].fetch_error = err;
      updatedInputValues[inputName].isLoading = false;
    }
    this.props.updateInputValues(updatedInputValues);
  };

  setInputOptionsFromQuery = (
    inputName,
    options,
    inputType,
    startingNestedInputValues,
    currentNestedInputValues,
    mappedOptions,
    inputMappingOptions,
    isStillLoading,
    dataTS,
  ) => {
    let updatedInputValues = this.props.inputValues;
    // In the conditional dynamic content, it's possible we've changed the inputValues array since we started loading.
    if (!updatedInputValues[inputName]) {
      return;
    }
    if (isEqual(startingNestedInputValues, currentNestedInputValues)) {
      updatedInputValues[inputName].isLoading = isStillLoading;
      updatedInputValues[inputName].dataTS = dataTS;
      updatedInputValues[inputName].options = options;
      updatedInputValues[inputName].mappedOptions = mappedOptions;
      const input = find(this.props.inputs, (input) => input.name === inputName);
      if (inputType === Constants.InputTypes.STRING) {
        if (!updatedInputValues[inputName].value) {
          if (input && input.default_value && options && options.indexOf(input.default_value) >= 0) {
            updatedInputValues[inputName].value = input.default_value;
          } else {
            updatedInputValues[inputName].value = options[0];
          }
        }
      }

      const isDependentOnBulkInput = inputs.inputIsDependentOnBulkInput(input, this.props.currentBulkInputName);
      updatedInputValues[inputName].is_dependent_on_bulk_input = isDependentOnBulkInput;
      if (this.props.currentBulkInputName && isDependentOnBulkInput && inputType === Constants.InputTypes.LIST) {
        updatedInputValues[inputName].value = options;
      } else if (Array.isArray(updatedInputValues[inputName].value)) {
        if (isDependentOnBulkInput) {
          updatedInputValues[inputName].value = options;
        } else {
          const existingValues = [];
          const values = updatedInputValues[inputName].value;
          for (let i = 0; i < values.length; i++) {
            if (options.indexOf(values[i]) >= 0) {
              existingValues.push(values[i]);
            }
          }
          updatedInputValues[inputName].value = existingValues;
        }
      } else {
        if (options.indexOf(updatedInputValues[inputName].value) < 0) {
          if (options && options.length > 0) {
            updatedInputValues[inputName].value = options[0];
          } else {
            updatedInputValues[inputName].value = Constants.INITIAL_VALUES_FOR_INPUT_TYPES[inputType];
          }
        }
      }
      this.onChange(inputName, updatedInputValues);
    } else {
      updatedInputValues[inputName].isLoading = isStillLoading;
      updatedInputValues[inputName].dataTS = dataTS;
      this.props.updateInputValues(updatedInputValues);
    }
  };

  onChange = (inputName, updateInputValues) => {
    updateInputValues = this.resetInputOptionsFromNestedInput(inputName, updateInputValues);
    this.props.updateInputValues(updateInputValues);
  };

  onAuthTokenChange = (e, dynamicContent) => {
    this.props.setAuthTokenByDynamicContent(dynamicContent, e.target.value);
  };

  onBulkInputToggle = (inputName, shouldBeBulk) => (e) => {
    e.preventDefault();
    if (this.isBulkGenerationEnabledForEnterprise() && this.isBulkGenerationEnabledForTier()) {
      this.onChange(inputName, this.props.inputValues);
      this.props.onBulkInputToggle(inputName, shouldBeBulk);
    } else {
      const planName = Constants.MATIK_TIERS[this.props.userContext.user.enterprise.plan_id].display_name;
      const noBulkHtml = ReactDOMServer.renderToStaticMarkup(
        <UpsellGenericModal
          featureHeader={`Generate Bulk Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
          featureDescription={`Bulk Generation is not available for the ${planName} plan. Please upgrade to generate bulk presentations.`}
        />,
      );
      API.track('enterprise_upsell_shown', { from: 'no_bulk_generation' });
      MProductUpsell(`${noBulkHtml}`, false, (result) => {
        if (!result.dismiss) {
          API.track('discover_matik_enterprise_click', { from: 'no_bulk_generation' });
          const demoUrl = teams.buildRequestDemoUrl(
            this.props.userContext.user,
            'bulk_generation',
            this.props.userContext.user.enterprise.trial_days_remaining,
          );
          window.open(demoUrl, '_blank');
        }
      });
    }
  };

  resetInputOptionsFromNestedInput = (inputName, inputValues) => {
    let updatedInputValues = this.props.inputValues;
    const input = this.props.inputs.filter((input) => input.name === inputName)[0];
    if (input.type === Constants.InputTypes.DATE_RANGE && inputValues[inputName].error) {
      return updatedInputValues;
    }
    if (input.parameters && Object.keys(input.parameters).length > 0) {
      for (let childInputName in input.parameters) {
        if (updatedInputValues[childInputName]) {
          if (updatedInputValues[childInputName].options) {
            updatedInputValues[childInputName].options = null;
          }

          if (updatedInputValues[childInputName].fetch_error) {
            updatedInputValues[childInputName].fetch_error = null;
          }
        }
        const childInput = this.props.inputs.filter((input) => input.name === childInputName)[0];
        if (childInput && childInput.parameters) {
          updatedInputValues = this.resetInputOptionsFromNestedInput(childInputName, updatedInputValues);
        }
      }
    }

    return updatedInputValues;
  };

  handleMouseEnter = (inputName) => {
    this.context.highlightSlideNums(this.props.slideIdsByInputName[inputName]);
  };

  handleMouseLeave = () => {
    this.context.highlightSlideNums([]);
  };
}

FormFromInputs.propTypes = {
  allowBulk: PropTypes.bool,
  currentBulkInputName: PropTypes.string,
  inputs: PropTypes.array,
  attachmentInputs: PropTypes.array,
  inputValues: PropTypes.object,
  attachmentInputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  onBulkInputToggle: PropTypes.func,
  slideIdsByInputName: PropTypes.object,
  updateInputValues: PropTypes.func,
  updateAttachmentInputValues: PropTypes.func,
  userContext: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  dataSourcesWithAuthToken: PropTypes.array,
  allDynamicContent: PropTypes.object,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  authTokenByDynamicContent: PropTypes.object,
  setAuthTokenByDynamicContent: PropTypes.func,
  onTestAccess: PropTypes.func,
  isProducer: PropTypes.bool,
  history: PropTypes.object,
  inModal: PropTypes.bool,
  isTestSlide: PropTypes.bool,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  test: PropTypes.bool,
  setUserBasedInputsIsLoading: PropTypes.func,
};

FormFromInputs.defaultProps = {
  slideIdsByInputName: {},
};

FormFromInputs.contextType = EndUserCreateContext;

export default withUserContext(withRouter(FormFromInputs));
