import React from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Constants from '../../Constants';
import unknown_icon from '../../../images/unknown.svg';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';

function ContentCard(props) {
  const { content, link } = props;

  const imgSrc = Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.type?.toLowerCase()]
    ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.type?.toLowerCase()].icon
    : unknown_icon;
  let titleClassName;
  let formatsClassName;
  if (content.isChild) {
    titleClassName = 'dc-content-title-child';
    formatsClassName = 'dc-content-formats-child';
  } else {
    titleClassName = 'dc-content-title';
    formatsClassName = 'dc-content-formats';
  }

  return (
    <div className="param-card has-cursor-pointer text-overflow-ellipsis" id={`param-card-${content.id}`}>
      <Link to={link}>
        <Level className={`has-text-black ${content.formats ? 'pts' : 'mts'}`}>
          <Level.Side align="left">
            <Level.Item>
              <img src={imgSrc} alt="Content Type" />
            </Level.Item>
            <div className="content-card-container is-connected-content">
              <TextOverflowWithTooltip
                className={`text-overflow-ellipsis min-width-0 ${titleClassName}`}
                text={content.name || 'Content is inaccessible'}
              />
              <span>
                <TextOverflowWithTooltip
                  className={`text-overflow-ellipsis min-width-0 ${formatsClassName} has-text-grey is-size-7`}
                  text={content.formats ? content.formats.join(', ') : ''}
                />
              </span>
            </div>
          </Level.Side>
        </Level>
      </Link>
    </div>
  );
}

ContentCard.propTypes = {
  content: PropTypes.object,
  link: PropTypes.string.isRequired,
};

export default ContentCard;
