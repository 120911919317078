import { sanitize } from 'dompurify';
import { findKey, isEmpty } from 'lodash';
import API from 'lib/api';
import Constants from 'components/Constants';

const resolvedMapped = {
  DraggableButton: 'a',
  DraggableText: 'div',
  Image: 'img',
  Chart: 'img',
  Table: 'div',
  Column: 'div',
  ColumnContainer: 'div',
  ColumnModule: 'div',
  Divider: 'div',
  EmailContainer: 'div',
  EmailBody: 'div',
  HtmlBuilderEmptyState: 'div',
};

const propsNameMapped = {
  className: 'class',
  'view-box': 'viewBox',
  'button-url': 'href',
  'image-u-r-l': 'src',
};

const unwantedProps = [
  'children',
  'contenteditable',
  'draggable',
  'text',
  'text-h-t-m-l',
  'table-h-t-m-l',
  'table-dc',
  'preview-dc',
  'previewed-text',
  'generated-table-theme',
];

const htmlUtils = {
  constants: {
    fontFamilyOptions: [
      { label: 'American Typewriter', value: 'american typewriter' },
      { label: 'Andale Mono', value: 'andale mono' },
      { label: 'Arial', value: 'arial' },
      { label: 'Arial Black', value: 'arial black' },
      { label: 'Arial Narrow', value: 'arial narrow' },
      { label: 'Arial Rounded MT Bold', value: 'arial rounded mt bold' },
      { label: 'Baskerville', value: 'baskerville' },
      { label: 'Big Caslon', value: 'big caslon' },
      { label: 'Bradley Hand', value: 'bradley hand' },
      { label: 'Brush Script MT', value: 'brush script mt' },
      { label: 'Comic Sans MS', value: 'comic sans ms' },
      { label: 'Copperplate', value: 'copperplate' },
      // { label: 'Courier', value: 'courier' },
      { label: 'Courier New', value: 'courier new' },
      { label: 'Cursive', value: 'cursive' },
      { label: 'Didot', value: 'didot' },
      { label: 'Fantasy', value: 'fantasy' },
      { label: 'Futura', value: 'futura' },
      { label: 'Geneva', value: 'geneva' },
      { label: 'Georgia', value: 'georgia' },
      { label: 'Gill Sans', value: 'gill sans' },
      { label: 'Helvetica', value: 'helvetica' },
      { label: 'Hoefler Text', value: 'hoefler text' },
      { label: 'Impact', value: 'impact' },
      { label: 'Lucida Grande', value: 'Lucida Grande' },
      { label: 'Luminari', value: 'luminari' },
      { label: 'Monaco', value: 'Monaco' },
      { label: 'Monospace', value: 'monospace' },
      { label: 'Palatino', value: 'palatino' },
      { label: 'Papyrus', value: 'papyrus' },
      { label: 'Perpetua', value: 'perpetua' },
      { label: 'Rockwell', value: 'rockwell' },
      { label: 'Sans Serif', value: 'sans-serif' },
      { label: 'Tahoma', value: 'tahoma' },
      { label: 'Times', value: 'times' },
      { label: 'Times New Roman', value: 'times new roman' },
      { label: 'Trebuchet MS', value: 'trebuchet ms' },
      { label: 'Trattatello', value: 'trattatello' },
      { label: 'Verdana', value: 'verdana' },
    ],
    fontSizeOptions: [
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
      { label: '14', value: 14 },
      { label: '16', value: 16 },
      { label: '18', value: 18 },
      { label: '20', value: 20 },
      { label: '24', value: 24 },
      { label: '28', value: 28 },
      { label: '32', value: 32 },
      { label: '36', value: 36 },
      { label: '40', value: 40 },
      { label: '48', value: 48 },
      { label: '56', value: 56 },
      { label: '64', value: 64 },
      { label: '72', value: 72 },
    ],
    columnsMapping: {
      '1fr': ['100%', null, null, null],
      '1fr 1fr': ['50%', '50%', null, null],
      '1fr 1fr 1fr': ['33%', '33%', '33%', null],
      '1fr 1fr 1fr 1fr': ['25%', '25%', '25%', '25%'],
      '1fr 2fr': ['33%', '66%', null, null],
      '2fr 1fr': ['66%', '33%', null, null],
      '1fr 3fr': ['25%', '75%', null, null],
      '3fr 1fr': ['75%', '25%', null, null],
      '1fr 4fr': ['20%', '80%', null, null],
      '4fr 1fr': ['80%', '20%', null, null],
    },
  },

  saveHtml: async (htmlString, currentTemplate, subject, jsonNodes) => {
    if (currentTemplate.source.editor_type === 'code' || htmlUtils.validateHtml(htmlString)) {
      let escapedHtmlString = sanitize(htmlString, { ADD_ATTR: ['target'], WHOLE_DOCUMENT: true });
      let generatedHtml = escapedHtmlString;
      let chartDataByDynamicContentName = {};
      if (currentTemplate.source.editor_type === 'visual') {
        chartDataByDynamicContentName = htmlUtils.generateChartData(jsonNodes);
        generatedHtml = htmlUtils.generateHtml(generatedHtml);
        generatedHtml = await htmlUtils.inlineCSSForTables(jsonNodes, generatedHtml);
      }
      // remove quotes from dc tags
      generatedHtml = generatedHtml.replaceAll('"{{', '{{').replaceAll('}}"', '}}');
      const updatedTemplate = htmlUtils.updateEmailTemplate(
        generatedHtml,
        currentTemplate,
        subject,
        jsonNodes,
        chartDataByDynamicContentName,
      );
      return updatedTemplate;
    }

    return {};
  },

  generateChartData: (jsonNodes) => {
    const chartDataByDynamicContentName = {};
    const parsedJsonNodes = JSON.parse(jsonNodes);

    for (let key in parsedJsonNodes) {
      if (!isEmpty(parsedJsonNodes[key].props.chartDc)) {
        chartDataByDynamicContentName[parsedJsonNodes[key].props.chartDc.name] = {
          data: parsedJsonNodes[key].props.chartData,
          options: parsedJsonNodes[key].props.chartOptions,
          type: parsedJsonNodes[key].props.type,
          width: parsedJsonNodes[key].props.width,
          height: parsedJsonNodes[key].props.height,
        };
      }
    }
    return chartDataByDynamicContentName;
  },

  generateHtml: (htmlString) => {
    return `
      <!DOCTYPE html>
      <html xmlns="http://www.w3.org/1999/xhtml" lang-="en">
        <head>
          <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="format-detection" content="telephone=no" />
          <meta name="x-apple-disable-message-reformating" />
        </head>
        <body>
          ${htmlString}
        </body>
      </html>
    `;
  },

  updateEmailTemplate: (htmlString, currentTemplate, subject, jsonNodes, chartDataByDynamicContentName) => {
    // since we're only updating the email body in this function, make sure not to touch recipient/sender dc
    const currentDynamicContent = currentTemplate?.matching_dynamic_content_by_slide
      ? Object.values(currentTemplate?.matching_dynamic_content_by_slide)
      : [];

    let currentDynamicContentObj = {};
    if (currentDynamicContent.length) {
      currentDynamicContentObj = currentDynamicContent[0];
    }
    const nonHtmlDynamicContent = Object.values(currentDynamicContentObj).filter((dc) =>
      [Constants.DynamicContentTypes.SENDER, Constants.DynamicContentTypes.RECIPIENTS].includes(
        dc.dynamic_content_type,
      ),
    );
    const nonHtmlTags = nonHtmlDynamicContent.map((dc) => dc.name);

    const updatedTemplate = { ...currentTemplate };
    // scan html for any new dc tags and add them
    const tagsArray = nonHtmlTags || [];
    const subjectText = subject || '';
    const subjectDynamicContentTags = htmlUtils.scanForDcTags(subjectText);
    const htmlText = htmlString;
    const htmlDynamicContentTags = htmlUtils.scanForDcTags(htmlText);
    let newTags = tagsArray.concat(subjectDynamicContentTags, htmlDynamicContentTags);
    if (newTags[0] && Array.isArray(newTags[0]) && newTags[0].length < 1) {
      newTags = [];
    }
    updatedTemplate.newTags = newTags;
    updatedTemplate.html = htmlText;
    if (!updatedTemplate.source) {
      updatedTemplate.source = {};
    }
    updatedTemplate.source.table_styles = updatedTemplate.source.table_styles || {};
    updatedTemplate.source.chart_data_by_dynamic_content_name = chartDataByDynamicContentName;
    updatedTemplate.source.json_nodes = jsonNodes;

    // Parse jsonNodes to extract the current table keys for visual builder
    if (currentTemplate.source.editor_type === 'visual') {
      const nodes = JSON.parse(jsonNodes);
      const currentTableKeys = Object.values(nodes).reduce((keys, node) => {
        if (node.type && node.type.resolvedName === 'Table' && node.props.tableKey) {
          keys[node.props.tableKey] = true;
        }
        return keys;
      }, {});

      // Remove unused table themes
      Object.keys(updatedTemplate.source.table_styles).forEach((tableKey) => {
        if (!Object.prototype.hasOwnProperty.call(currentTableKeys, tableKey)) {
          delete updatedTemplate.source.table_styles[tableKey];
        }
      });

      // Add or update the themes from the current jsonNodes
      Object.values(nodes).forEach((node) => {
        if (node.type && node.type.resolvedName === 'Table') {
          const tableKey = node.props.tableKey;
          const inlineStyles = node.props.inlineStyles;
          if (tableKey && inlineStyles) {
            updatedTemplate.source.table_styles[tableKey] = inlineStyles;
          }
        }
      });
    }
    return updatedTemplate;
  },

  scanForDcTags: (text) => {
    const regex = /{{((?:[^}]|}(?!}))*)}}/g;
    const dynamicContentTags = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Strip HTML tags from the content inside {{ }}
      const cleanedContentTag = match[1].replace(/<[^>]*>/g, '').trim();
      if (cleanedContentTag) {
        dynamicContentTags.push(cleanedContentTag);
      }
    }
    return dynamicContentTags;
  },

  convertJsonNodesToHtmlString(jsonNodes) {
    let queryJson = JSON.parse(jsonNodes);
    const containerNodeKey = findKey(queryJson, (node) => node.type.resolvedName === 'EmailBody');
    queryJson.ROOT = queryJson[containerNodeKey];
    queryJson.ROOT.parent = '';
    delete queryJson[containerNodeKey];
    return htmlUtils.convertToHtml({ node: queryJson.ROOT, data: queryJson });
  },

  transformProps: (props) => {
    const imageDc = props?.imageDc;
    const chartDc = props?.chartDc;
    let gridColumns = '';
    let alignment = 'left';
    const transformObject = Object.entries(props).reduce((obj, [key, value]) => {
      const propNameTransformed = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

      let name = propsNameMapped[propNameTransformed] || propNameTransformed;
      if (name === 'style') {
        let styleString = '';
        for (let styleKey in value) {
          const styleKeyTransformed = styleKey.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
          styleString += `${styleKeyTransformed}:${value[styleKey]};`;
          if (styleKeyTransformed === 'grid-template-columns') {
            gridColumns = value[styleKey];
          }
        }
        value = styleString;
      }

      if (name === 'align' || name === 'image-alignment') {
        alignment = value;
      }
      if (props.chartAlignment) {
        alignment = props.chartAlignment;
      }

      // Replace image src with dynamic content tag if it's not static
      if (
        name === 'src' &&
        !isEmpty(imageDc) &&
        imageDc.dynamic_content_method !== Constants.DynamicContentMethods.STATIC
      ) {
        value = `{{${imageDc.name}}}`;
      }

      if (!isEmpty(chartDc)) {
        obj['src'] = `{{${chartDc.name}}}`;
        obj['width'] = props.width;
        obj['height'] = props.height;
        obj['alt'] = `${chartDc.name}`;
      }

      obj[name] = value;
      return obj;
    }, {});

    const transformedProps = Object.entries(transformObject)
      .filter(([key]) => !unwantedProps.includes(key))
      .map(([key, value]) => ` ${key}="${value}"`)
      .join('');

    return { transformedProps, gridColumns, alignment };
  },

  removeMarginsFromPTags: (htmlString) => {
    const parser = new DOMParser();
    // eslint-disable-next-line scanjs-rules/call_parseFromString
    const doc = parser.parseFromString(htmlString, 'text/html');
    const pTags = doc.getElementsByTagName('p');
    for (let tag of pTags) {
      if (!tag.innerText) {
        tag = '<br />';
      } else {
        const existingStyle = tag.getAttribute('style') || '';
        const newStyle = `${existingStyle}margin-block-start:0px;margin-block-end:0px;margin-inline-start:0px;margin-inline-end:0px;`;

        tag.setAttribute('style', newStyle);
      }
    }
    return doc.body.innerHTML;
  },

  inlineCSSForTables: (jsonNodes, originalHtml) => {
    return new Promise((resolve, reject) => {
      let updatedHtml = originalHtml;
      const nodes = JSON.parse(jsonNodes);

      // Compile table data into an array
      const tableData = Object.values(nodes)
        .filter((node) => node.type && node.type.resolvedName === 'Table')
        .map((node) => ({
          tableHTML: node.props.tableHTML,
          inlineStyles: node.props.inlineStyles,
          tableKey: node.props.tableKey,
        }));

      if (tableData.length === 0) {
        resolve(updatedHtml);
        return;
      }

      API.post(
        '/html/css/',
        tableData,
        (response) => {
          const styledTables = response.data;

          // replace each original table with the styled one based on table key
          styledTables.forEach(({ tableKey, styledTableHTML }) => {
            const regexToFindTable = new RegExp(`(data-table-key="${tableKey}".*?>)(.*?)(</table></div>)`, 'gs');
            updatedHtml = updatedHtml.replace(regexToFindTable, (match, p1) => {
              return `${p1}${styledTableHTML}</table></div>`;
            });
          });

          resolve(updatedHtml);
        },
        (error) => {
          API.defaultError(error);
          reject(error);
        },
      );
    });
  },

  convertToHtml: ({ node, data }) => {
    let tagName = '';

    if (typeof node.type === 'object') {
      /**
       * If it's an object and we don't find the resolvedName we use
       * a div as fallback, this can happen on linkedNodes
       */
      tagName = resolvedMapped[node.type.resolvedName] || 'div';
    } else {
      tagName = node.type;
    }

    const [linkedNodes] = Object.values(node.linkedNodes);
    const nodes = linkedNodes ? data[linkedNodes].nodes : node.nodes;
    const nodeProps = linkedNodes ? data[linkedNodes].props : node.props;

    let child;
    if (node.type.resolvedName === 'DraggableButton') {
      child = node.props.text;
    } else if (node.type.resolvedName === 'DraggableText') {
      const textHTMLWithPTagsFixed = htmlUtils.removeMarginsFromPTags(node.props.textHTML);
      child = textHTMLWithPTagsFixed;
    } else if (node.type.resolvedName === 'Table') {
      child = node.props.tableHTML;
    } else if (node.type.resolvedName === 'ColumnModule') {
      child = nodes.map((x) => htmlUtils.convertToHtml({ node: data[x], data }));
    } else {
      // child = nodes.map((x) => htmlUtils.convertToHtml({ node: data[x], data })).join('');
      let children = '';
      for (let x of nodes) {
        children += htmlUtils.convertToHtml({ node: data[x], data });
      }
      child = children;
    }

    const { transformedProps, gridColumns, alignment } = htmlUtils.transformProps(nodeProps);
    if (tagName) {
      if (node.type.resolvedName === 'Chart' && !isEmpty(node.props.chartDc)) {
        return `
          <div style="text-align:${alignment};" data-module-type="chart">
            <img ${transformedProps} />
          </div>
        `;
      } else if (node.type.resolvedName === 'Image') {
        const imageType = !isEmpty(node.props.imageDc) ? 'dynamic' : 'static';
        if (imageType === 'dynamic') {
          return `
            <div style="text-align:${alignment};" data-module-type="image" data-image-type="dynamic">
              <${tagName}${transformedProps} />
            </div>
          `;
        } else {
          return `
            <div style="text-align:${alignment};" data-module-type="image" data-image-type="static">
              <${tagName}${transformedProps} />
            </div>
          `;
        }
      } else if (node.type.resolvedName === 'ColumnModule') {
        const columnModuleStyle = {
          style: {
            background: nodeProps.style.background,
            paddingTop: nodeProps.style.paddingTop,
            paddingBottom: nodeProps.style.paddingBottom,
            paddingLeft: nodeProps.style.paddingLeft,
            paddingRight: nodeProps.style.paddingRight,
            width: '100%',
            borderSpacing: '0px',
          },
        };
        const { transformedProps: transformedColumnModuleProps } = htmlUtils.transformProps(columnModuleStyle);
        return htmlUtils.buildTables(gridColumns, child, transformedColumnModuleProps);
      } else if (node.type.resolvedName === 'DraggableButton') {
        return `
          <div style="text-align:center;">
            <${tagName}${transformedProps}>${node.props.children || ''}${child}</${tagName}>
          </div>
        `;
      } else if (node.type.resolvedName === 'Divider') {
        const dividerContainerStyle = {
          style: {
            paddingTop: nodeProps.paddingTop,
            paddingBottom: nodeProps.paddingBottom,
            paddingLeft: nodeProps.paddingLeft,
            paddingRight: nodeProps.paddingRight,
          },
        };
        const dividerStyle = {
          style: nodeProps.style,
        };
        const { transformedProps: transformedContainerProps } = htmlUtils.transformProps(dividerContainerStyle);
        const { transformedProps: transformedDividerProps } = htmlUtils.transformProps(dividerStyle);
        return `
          <div${transformedContainerProps}>
            <${tagName}${transformedDividerProps}>${node.props.children || ''}${child}</${tagName}>
          </div>
        `;
      } else if (node.type.resolvedName === 'Table') {
        const tableDivStyle = {
          style: {
            backgroundColor: nodeProps.style.backgroundColor,
            paddingTop: nodeProps.style.paddingTop,
            paddingBottom: nodeProps.style.paddingBottom,
            paddingLeft: nodeProps.style.paddingLeft,
            paddingRight: nodeProps.style.paddingRight,
            borderColor: nodeProps.style.borderColor,
            borderWidth: nodeProps.style.borderWidth,
            borderStyle: nodeProps.style.borderStyle,
          },
        };
        const { transformedProps: transformedTableProps } = htmlUtils.transformProps(tableDivStyle);
        return `
          <div${transformedTableProps}>
            <div data-table-key="${node.props.tableKey}" data-table-dc="{{${node.props.tableDc.name}}}">${child}</div>
          </div>
        `;
      }

      return `<${tagName}${transformedProps}>${node.props.children || ''}${child}</${tagName}>`;
    }
    return '';
  },

  validateHtml: (htmlString) => {
    let parser = new DOMParser();
    htmlString = htmlString.replaceAll('&nbsp;', '&#160;').replaceAll('<br>', '<br />');
    // eslint-disable-next-line scanjs-rules/call_parseFromString
    let doc = parser.parseFromString(htmlUtils.generateHtml(htmlString), 'application/xhtml+xml');
    let errorNode = doc.querySelector('parsererror');
    if (errorNode) {
      // const errorMessage = errorNode.querySelector('div')?.innerText;
      // MAlert(errorMessage, 'HTML validation error', 'error');
      return true;
    }
    return true;
  },

  replaceTagsWithValues: (htmlString, previewValues) => {
    for (let key in previewValues) {
      let pattern = new RegExp(`{{${key}}}|{{${key}\\|.+}}`, 'g'); //replace {{dcname}} or {{dcname|format}}
      htmlString = htmlString.replaceAll(pattern, previewValues[key]);
    }
    return htmlString;
  },

  buildTables: (gridColumns, children, columnModuleProps) => {
    let columnTables = `
      <table${columnModuleProps}>
        <tr style="border-spacing:0px;">
    `;
    children.forEach((child, index) => {
      if (htmlUtils.constants.columnsMapping[gridColumns][index]) {
        columnTables += `
        <td style="width:${htmlUtils.constants.columnsMapping[gridColumns][index]};border-spacing:0px;padding:0px;vertical-align:top;">
          <table align="left" style="width:100%;border-spacing:0px;">
            <tr style="border-spacing:0px;">
              <td style="border-spacing:0px;padding:0px">${child}</td>
            </tr>
          </table>
        </td>
        `;
      }
    });
    columnTables += `
        </tr>
      </table>
    `;
    return columnTables;
  },
};

export default htmlUtils;
