import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'components/UserContext';
import { Link, Redirect } from 'react-router-dom';
import { each, filter, isEmpty, startCase, groupBy } from 'lodash';
import Pluralize from 'pluralize';
import PresentationViewer from './PresentationViewer';
import { Level, Heading, Container } from 'react-bulma-components';
import API from '../../../lib/api';
import utils from 'lib/utils';
import { ReactComponent as ArrowRepeatAll } from '../../../svg/arrow_repeat_all.svg';
import PdfDownloadButton from '../../consumer/PdfDownloadButton';
import pdf from '../../../lib/pdf';
import TextOverflowWithTooltip from '../TextOverflowWithTooltip';
import PresentationDetailSidebar from './PresentationDetailSidebar';
import { useDispatch, useSelector } from 'react-redux';
import AccessManager from '../../../lib/AccessManager';
import Constants from 'components/Constants';
import Banner from '../Banner';
import PresentationLogSidepane from './PresentationLogSidepane';
import presentations from '../../../lib/presentations';
import useTemplate from 'lib/hooks/useTemplate';
import { openSidepane, closeSidepane, openFullScreenModal, closeFullScreenModal } from 'redux/ui/action';
import TemplateConditionsModal from 'components/producer/templates/TemplateConditionsModal';
import { MConfirm } from '../Alerts';
import ButtonGroup from '../../lib/ButtonGroup';
import Button from '../../lib/Button';
import Icon from '../../lib/Icon';
import Tabs from '../../lib/Tabs';
import TabItem from '../../lib/TabItem';
import usePresentation from 'lib/hooks/usePresentation';
import useAccesses from 'lib/hooks/useAccess';
import conditional_generation_failed from 'images/conditional_generation_failed.svg';
function Presentation(props) {
  const [areInputsLoading, setAreInputsLoading] = useState(true);
  const [presentationInputsByName, setPresentationInputsByName] = useState();
  const [bannerActive, setBannerActive] = useState(false);
  const [conditionBannerActive, setConditionBannerActive] = useState(false);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [infoLogGroupByType, setInfoLogGroupByType] = useState([]);
  const [activeTab, setActiveTab] = useState('email');
  const [attachmentWarningsCount, setAttachmentWarningsCount] = useState(0);
  const [attachmentErrorsCount, setAttachmentErrorsCount] = useState(0);
  const [openButtonStatus, setOpenButtonStatus] = useState('default');
  const [downloadButtonStatus, setDownloadButtonStatus] = useState('default');

  const dispatch = useDispatch();
  const { data: currentPresentation, isPending: isFetchingPresentation } = usePresentation(props.presentationId);
  const { data: attachedPresentation } = usePresentation(currentPresentation?.attached_presentation_id);
  const { user } = useContext(UserContext);
  const templateType =
    currentPresentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
      ? 'email'
      : Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(currentPresentation?.presentation_type)
      ? 'document'
      : 'presentation';
  const ui = useSelector((state) => state.ui);
  const { data: accessesByItemId } = useAccesses(currentPresentation ? 'presentation' : null, [
    currentPresentation?.id,
  ]);
  const { data: presentationTemplate, invalidate: invalidateTemplate } = useTemplate(currentPresentation?.template?.id);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('logs')) {
      openPresentationLogSidepane();
    }
    if (currentPresentation) {
      fetchPresentationInputsByName(currentPresentation);
      let attachmentWarnings = [];
      let attachmentErrors = [];
      if (currentPresentation.attached_presentation_id) {
        if (attachedPresentation) {
          attachmentWarnings = filter(attachedPresentation.logs, { level: Constants.LOG_LEVEL.warning });
          attachmentErrors = filter(attachedPresentation.logs, { level: Constants.LOG_LEVEL.error });
          setAttachmentWarningsCount(attachmentWarnings.length);
          setAttachmentErrorsCount(attachmentErrors.length);
        }
      }
      const warnings = filter(currentPresentation.logs, { level: Constants.LOG_LEVEL.warning }).concat(
        attachmentWarnings,
      );
      const errors = filter(currentPresentation.logs, { level: Constants.LOG_LEVEL.error }).concat(attachmentErrors);
      const emailFailedCondition =
        currentPresentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT &&
        currentPresentation.status === 'failed condition';
      const nonEmailFailedCondition =
        currentPresentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
        currentPresentation.status === 'failed condition';
      if (emailFailedCondition || nonEmailFailedCondition) {
        setConditionBannerActive(true);
      } else if (
        currentPresentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT &&
        !warnings.length &&
        !errors.length
      ) {
        const unsent_error = {
          details: {},
          level: 'ERROR',
          message: currentPresentation.sendgrid_data.delivery_status_reason,
          type: 'email_send_error',
        };
        errors.push(unsent_error);
      }

      if (errors.length || warnings.length) {
        setErrors(errors);
        setWarnings(warnings);
        setBannerActive(true);
      }
      if (currentPresentation.status === 'error' && errors.length === 0) {
        setBannerActive(true);
      }
    }
  }, [currentPresentation, attachedPresentation, window.location.search]);

  useEffect(() => {
    return () => {
      if (ui.sidepane) {
        dispatch(closeSidepane());
      }
    };
  }, [ui.sidepane]);

  const fetchPresentationInputsByName = (currentPresentation) => {
    setAreInputsLoading(true);
    API.get(`/presentations/${currentPresentation.id}/content/`, (response) => {
      const presentationInputs = response.data.parameters;
      const presentationInputsByName = {};
      each(presentationInputs, (input) => (presentationInputsByName[input.name] = input));
      setPresentationInputsByName(presentationInputsByName);

      // TODO: The backend logic for determining when the template has changed is unreliable
      // when the presentation included library slides, so we'll disable the user notification
      // of that to reduce confusion.
      // https://matik.atlassian.net/browse/MPD-4075
      // props.setTemplateChanged(response.data.template_changed);
    }).finally(() => {
      setAreInputsLoading(false);
    });
  };

  const dismissBanner = (e) => {
    e.preventDefault();
    setBannerActive(false);
  };

  const dismissConditionBanner = (e) => {
    e.preventDefault();
    setConditionBannerActive(false);
  };

  const openPresentationLogSidepane = (e) => {
    e?.preventDefault();
    if (!ui.sidepane) {
      if (!infoLogGroupByType.length) {
        fetchPresentationInfoLogs(parseInt(props.presentationId));
      }
      dispatch(openSidepane(null, 'presentation-log'));
    }
  };

  const fetchPresentationInfoLogs = (id) => {
    API.get(`/presentations/${id}/logs`, (response) => {
      let infoLogs = [];
      let allLogs = [...response.data.presentation_logs, ...response.data.attached_presentation_logs];
      const filterLogByType =
        (...args) =>
        ({ type }) =>
          args.some((search_type) => type === search_type);

      let info = filter(
        allLogs,
        filterLogByType(
          'query_done',
          'fetched_template',
          'copy_template',
          'copy_library_slides',
          'add_looped_slides',
          'customize_data',
          'google_update',
          'fetched_presentation',
          'condition_clause_eval',
          'presentation_condition_failed',
          'remove_slide',
          'folder_upload_failed',
          'missing_office_365_folder_access',
        ),
      );
      //group the log based on type to display together in sidepane
      let infoLogGroupByType = groupBy(info, (log) => log.type);
      for (const logs of Object.values(infoLogGroupByType)) {
        infoLogs.push(...logs);
      }
      setInfoLogGroupByType(infoLogs);
    });
  };

  const renderTabPill = (warningCount, errorCount) => {
    if (errorCount > 0) {
      return ['red-500', errorCount];
    }
    if (warningCount > 0) {
      return ['yellow-500', warningCount];
    }

    return [null, null];
  };

  const onOpenClick = async (e, currentPresentation, download = false) => {
    e.preventDefault();
    if (currentPresentation.presentation_file) {
      if (
        currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
        currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
      ) {
        setOpenButtonStatus('loading');
        const response = await fetch(currentPresentation.presentation_file.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = currentPresentation.presentation_file.original_filename;
        link.click();
        setOpenButtonStatus('default');
      } else if (
        currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        if (download) {
          setDownloadButtonStatus('loading');
          utils.downloadPowerpointFile(e, currentPresentation, () => {
            setDownloadButtonStatus('default');
          });
        } else {
          setOpenButtonStatus('loading');
          utils.openPowerpointFileInNewTab(e, currentPresentation, () => {
            setOpenButtonStatus('default');
          });
        }
      } else {
        window.open(currentPresentation.presentation_file.url, '_blank', 'noopener,noreferrer');
      }
    }
  };

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  const openConditionsModal = (e) => {
    e.preventDefault();
    dispatch(openFullScreenModal('conditionsModal'));
  };

  const closeConditionsModal = () => {
    dispatch(closeFullScreenModal());
  };

  if (!currentPresentation && isFetchingPresentation) {
    return null;
  } else if (!currentPresentation && !isFetchingPresentation) {
    return <Redirect to={props.presentationsPath} />;
  }

  const recipientText = currentPresentation?.presentation_file?.to_email
    ? 'to ' + currentPresentation.presentation_file.to_email
    : '';

  const ignoreSendConditions = () => {
    if (currentPresentation.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      const onSuccess = () => {
        utils.notify('Email has been sent.');
      };
      MConfirm(
        'Override send conditions',
        `This email was not sent because it did not match your specified send conditions. Proceed to send the email ${recipientText} anyway.`,
        'warning',
        (confirmed) => {
          if (confirmed) {
            API.post(`/presentations/${currentPresentation.id}/ignore_conditions`, {}, onSuccess, API.defaultError);
          }
        },
        'Send',
      );
    } else {
      MConfirm(
        'Override generation conditions',
        `This ${templateType} was not generated because it did not match your specified generation conditions. Proceed to generation the ${templateType} anyway.`,
        'warning',
        (confirmed) => {
          if (confirmed) {
            props.onRegenerateClick(null, currentPresentation, true);
          }
        },
        'Generate',
      );
    }
  };

  const accessObj = new AccessManager(currentPresentation.id, accessesByItemId, user);
  const canRead = accessObj.can('read');
  const canEdit = accessObj.can('edit');
  const buttonText =
    currentPresentation &&
    (currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
      currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365)
      ? 'Open'
      : 'Download';

  let text, sublineText, bannerType;
  if (currentPresentation.status === 'error' && errors.length === 0) {
    // Case where status is 'error' but there are no error logs
    text = 'Error';
    sublineText =
      currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
        ? `We were not able to generate this email. ${currentPresentation.status_message}`
        : `We were not able to generate this presentation. ${currentPresentation.status_message}`;
    bannerType = 'error';
  } else if (errors.length) {
    text = `Error: ${startCase(errors[0].type)}`;
    sublineText =
      currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
        ? 'We were not able to send this email'
        : 'We were not able to generate this presentation';
    bannerType = 'error';
  } else if (warnings.length) {
    text = `Warning: ${Pluralize('warning', warnings.length, true)} while generating your presentation`;
    sublineText = 'You can report them to the template admin';
    if (currentPresentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      sublineText += ` or fix them in ${startCase(currentPresentation.presentation_type)}.`;
    }
    bannerType = 'warning';
  }

  const bannerWarningProps = {
    text: text,
    sublineText: sublineText,
    primaryButtonLabel: 'Review Details',
    onPrimaryButtonClick: openPresentationLogSidepane,
    onDismiss: dismissBanner,
  };

  let conditionBannerProps = {};
  conditionBannerProps.onPrimaryButtonClick = ignoreSendConditions;
  conditionBannerProps.onDismiss = dismissConditionBanner;
  if (
    currentPresentation.status === 'failed condition' &&
    currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
  ) {
    conditionBannerProps.text = 'Send conditions not met';
    const secondLineText = canEdit ? (
      <>
        <span>Review your conditions </span>
        <span className="banner-link" onClick={openConditionsModal}>
          here
        </span>
        , or select &quot;Send Now&quot; to send the email anyway.
      </>
    ) : (
      <span>Select &quot;Send Now&quot; to send the email anyway.</span>
    );
    conditionBannerProps.sublineText = (
      <span>
        {`This email ${recipientText} was not sent because your specified conditions were not met.`}
        <br />
        {secondLineText}
      </span>
    );
    conditionBannerProps.primaryButtonLabel = 'Send now';
  } else {
    conditionBannerProps.text = 'Generation conditions not met';
    const secondLineText = canEdit ? (
      <>
        <span>Review your conditions </span>
        <span className="banner-link" onClick={openConditionsModal}>
          here
        </span>
        , or select &quot;Generate&quot; {`to generate the ${templateType} anyway.`}
      </>
    ) : (
      <span>Select &quot;Generate&quot; {`to generate the ${templateType} anyway.`}</span>
    );
    conditionBannerProps.sublineText = (
      <span>
        {`This ${templateType} was not generated because the specified conditions were not met.`}
        <br />
        {secondLineText}
      </span>
    );
    conditionBannerProps.primaryButtonLabel = 'Generate';
  }

  const regenerationTooltipMessage = presentations.getRegenerationTooltipMessage(currentPresentation, canRead);

  const [emailTagColor, emailTag] = renderTabPill(
    warnings.length - attachmentWarningsCount,
    errors.length - attachmentErrorsCount,
  );
  const [attachmentTagColor, attachmentTag] = renderTabPill(attachmentWarningsCount, attachmentErrorsCount);

  const cc_emails = currentPresentation.presentation_file?.cc_emails?.length
    ? currentPresentation.presentation_file.cc_emails.join(', ')
    : '';
  const bcc_emails = currentPresentation.presentation_file?.bcc_emails?.length
    ? currentPresentation.presentation_file.bcc_emails.join(', ')
    : '';

  const shouldShowDownloadButton =
    currentPresentation?.presentation_file &&
    Object.keys(currentPresentation.presentation_file).length &&
    currentPresentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL;

  const shouldShow365DownloadButton =
    currentPresentation?.presentation_file &&
    Object.keys(currentPresentation.presentation_file).length &&
    (currentPresentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      currentPresentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365);

  return (
    <div className="wrapper">
      <div className="main phn">
        <Level className="header mbn">
          <Level.Side align="left">
            <Level.Item className="mrl">
              <Link
                to={props.presentationsPath}
                className="button is-secondary is-round-button is-borderless presentation-detail-back-button"
              >
                <Icon name="chevron_left" size={16} theme="regular" />
              </Link>
            </Level.Item>
            <Level.Item>
              <Heading className="text-overflow-ellipsis">
                <TextOverflowWithTooltip
                  className="text-overflow-ellipsis"
                  text={currentPresentation.name ? currentPresentation.name : currentPresentation.template.name}
                />
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <ButtonGroup align="right">
                {!isEmpty(currentPresentation.pdf) && (
                  <PdfDownloadButton onClick={() => pdf.downloadPdf(currentPresentation)} text="Download PDF" />
                )}
                <div
                  data-tooltip-id="matik-tooltip"
                  data-tooltip-content={regenerationTooltipMessage ? regenerationTooltipMessage : null}
                >
                  <Button
                    category="secondary"
                    onClick={(e) => props.onRegenerateClick(e, currentPresentation)}
                    status={
                      !canRead || isEmpty(currentPresentation.template) || regenerationTooltipMessage
                        ? 'disabled'
                        : 'default'
                    }
                  >
                    <ArrowRepeatAll className="mrs" />
                    Regenerate
                  </Button>
                </div>
                {shouldShowDownloadButton ? (
                  <Button onClick={(e) => onOpenClick(e, currentPresentation)} status={openButtonStatus}>
                    {buttonText}
                  </Button>
                ) : null}
                {shouldShow365DownloadButton ? (
                  <Button onClick={(e) => onOpenClick(e, currentPresentation, true)} status={downloadButtonStatus}>
                    Download
                  </Button>
                ) : null}
              </ButtonGroup>
            </Level.Item>
          </Level.Side>
        </Level>
        <div className="presentation-detail-wrapper">
          <PresentationDetailSidebar
            presentation={currentPresentation}
            inputs={presentationInputsByName}
            areInputsLoading={areInputsLoading}
            onRegenerateClick={props.onRegenerateClick}
            canRead={canRead}
          />
          <Container className="is-fluid pvxl">
            {conditionBannerActive && <Banner className="mbl" bannerType="info" {...conditionBannerProps} />}
            {bannerActive && <Banner className="mbl" bannerType={bannerType} {...bannerWarningProps} />}
            {currentPresentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? (
              <div className="w-full">
                <div className="flex justify-center mb-5">
                  <Tabs>
                    <TabItem
                      label="Email"
                      iconName="email"
                      onClick={switchTab}
                      isActive={activeTab === 'email'}
                      tag={emailTag}
                      tagColor={emailTagColor}
                    />
                    <TabItem
                      label="Attachment"
                      iconName="attach"
                      onClick={switchTab}
                      isActive={activeTab === 'attachment'}
                      tag={attachmentTag}
                      tagColor={attachmentTagColor}
                      status={currentPresentation.attached_presentation_id ? 'default' : 'disabled'}
                    />
                  </Tabs>
                </div>
                <Level.Item className="email-preview-header text-left">
                  {currentPresentation.presentation_file?.from_name && (
                    <p className="-indent-8 pl-8">{`From: ${currentPresentation.presentation_file?.from_name} <${currentPresentation.presentation_file?.from_email}>`}</p>
                  )}
                  {currentPresentation.presentation_file?.reply_email && (
                    <p className="-indent-8 pl-8">{`Reply-to: ${currentPresentation.presentation_file?.reply_email}`}</p>
                  )}
                  <p className="-indent-8 pl-8">{`Subject: ${currentPresentation.presentation_file?.parsed_subject}`}</p>
                  <p className="-indent-8 pl-8">{`To: ${currentPresentation.presentation_file?.to_email}`}</p>
                  <p className="-indent-8 pl-8">{`Cc: ${cc_emails}`}</p>
                  <p className="-indent-8 pl-8">{`Bcc: ${bcc_emails}`}</p>
                </Level.Item>
              </div>
            ) : null}
            {currentPresentation.status === 'failed condition' &&
            currentPresentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? (
              <div className="flex flex-col justify-center items-center w-1/3">
                <img src={conditional_generation_failed} />
                <p className="text-base font-semibold">{`${utils.capitalizeString(templateType)} Not Generated`}</p>
                <p className="text-sm text-grey-600 text-center">{`This ${templateType} was not generated because the specified conditions were not met.`}</p>
                <div className="my-4">
                  <ButtonGroup>
                    <Button category="secondary" onClick={openConditionsModal}>
                      View Conditions
                    </Button>
                    <Button category="primary" onClick={ignoreSendConditions}>
                      Generate
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ) : (
              <PresentationViewer
                template={activeTab === 'attachment' ? attachedPresentation.template : currentPresentation.template}
                presentation={activeTab === 'attachment' ? attachedPresentation : currentPresentation}
              />
            )}
          </Container>
        </div>
        <PresentationLogSidepane
          errors={errors}
          info={infoLogGroupByType}
          warnings={warnings}
          presentation={currentPresentation}
        />
        <TemplateConditionsModal
          show={ui.fullScreenModal?.name === 'conditionsModal'}
          onClose={closeConditionsModal}
          currentTemplate={presentationTemplate}
          conditionLevel="template"
          onConditionsUpdated={() => {
            invalidateTemplate(currentPresentation.template.id);
          }}
        />
      </div>
    </div>
  );
}

Presentation.propTypes = {
  presentationId: PropTypes.any,
  presentationsPath: PropTypes.string,
  onRegenerateClick: PropTypes.func,
};

export default Presentation;
