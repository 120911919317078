import React, { useState, useRef, useEffect } from 'react';
import Icon from 'components/lib/Icon';
import IconPill from 'components/lib/IconPill';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { CreatableInputOnly, MenuNoSearch, Select } from 'components/shared/FormSelect';
import Button from 'components/lib/Button';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import TabItem from 'components/lib/TabItem';
import Tabs from 'components/lib/Tabs';
import { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { openSidepane } from 'redux/ui/action';
import Indicator from 'components/lib/Indicator';
import ToggleSwitchWithLabel from 'components/lib/ToggleSwitchWithLabel';
import { useOneDynamicContent } from '../../../lib/hooks/useDynamicContent';

function EmailRecipientFormField({
  recipients,
  onDynamicRecipientsChange,
  allDynamicContent,
  dynamicRecipientsName,
  onContentClick,
  onInputClick,
  setFormHasError,
  inputErrorMsg,
  startingCcType,
  startingBccType,
}) {
  const [showField, setShowField] = useState(true);
  const [ccEnabled, setCcEnabled] = useState(recipients.cc.length || typeof recipients.cc === 'number');
  const [bccEnabled, setBccEnabled] = useState(recipients.bcc.length || typeof recipients.bcc === 'number');
  const [activeCcTab, setActiveCcTab] = useState(recipients.ccType === 'static' ? 'static' : 'dynamic');
  const [activeBccTab, setActiveBccTab] = useState(recipients.bccType === 'static' ? 'static' : 'dynamic');
  const [ccErrorMsg, setCcErrorMsg] = useState('');
  const [bccErrorMsg, setBccErrorMsg] = useState('');
  const selectRef = useRef(null);
  const dispatch = useDispatch();

  const { dynamicContent, isLoading: dynamicContentIsLoading } = useOneDynamicContent(recipients.dynamicRecipients);

  let selectedContentInputs = [];
  let showInputField = false;
  if (dynamicContent?.parameters) {
    selectedContentInputs = Object.values(dynamicContent.parameters);
    showInputField = true;
  }

  const recipientDynamicContentList = Object.values(allDynamicContent).filter(
    (content) => content.dynamic_content_type === Constants.DynamicContentTypes.RECIPIENTS,
  );

  const dynamicCcTypeOptions = [
    { label: 'Personalized based on input', value: Constants.RecipientFieldTypes.DYNAMIC_BULKED },
    { label: 'Same for all recipients', value: Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED },
  ];

  useEffect(() => {
    if (
      !dynamicContentIsLoading &&
      Object.values(dynamicContent?.parameters || {}).length === 0 &&
      recipients.bulkedInput !== 'None'
    ) {
      onDynamicRecipientsChange('bulkedInput', 'None');
    }
  }, [dynamicContent?.id]);

  const onRecipientsFieldsChange = (data, field) => {
    switch (field.name) {
      case 'dynamic-recipients':
        onDynamicRecipientsChange('dynamicRecipients', data.value, data.label);
        break;
      case 'dynamic-cc':
        onDynamicRecipientsChange('cc', data.value);
        break;
      case 'dynamic-bcc':
        onDynamicRecipientsChange('bcc', data.value);
        break;
    }
  };

  const toggleExpandField = () => {
    setShowField(!showField);
  };

  const editRecipientContentOnClick = (e) => {
    const contentName = e.currentTarget.id;
    const selectedContent = recipientDynamicContentList.filter((content) => content.name === contentName)[0];
    if (selectedContent) {
      const contentTag = utils.getTagFromSingleDynamicContent(selectedContent);
      onContentClick(contentTag);
    }
  };

  const addRecipientContentOnClick = () => {
    selectRef.current?.blur();
    dispatch(openSidepane(null, 'template-sidepane', Constants.DynamicContentTypes.RECIPIENTS));
  };

  const formatRecipientOptionLabel = (option, { context }) => {
    return (
      <div className="recipient-select-option">
        <div className="recipient-select-option-left">
          <IconPill iconName="recipient" iconTheme="regular" size="s" color="white" theme="square" />
          <span className="mls">{option.label}</span>
        </div>
        {context === 'value' && (
          <Button category="tertiary" onClick={editRecipientContentOnClick} id={option.label}>
            <Icon name="pencil" size={16} theme="regular" />
          </Button>
        )}
      </div>
    );
  };

  const editInputOnClick = (e) => {
    const selectedInputList = selectedContentInputs.filter((input) => input.name === recipients.bulkedInput);
    const selectedInputObj = selectedInputList.length ? selectedInputList[0] : {};
    onInputClick(e, selectedInputObj);
  };

  const checkValidEmails = (emailAddresses, field) => {
    for (const email of emailAddresses) {
      if (!utils.isEmailValid(email)) {
        if (field === 'cc') {
          setCcErrorMsg(email + ' is not a valid email address.');
          setFormHasError(true);
        } else {
          setBccErrorMsg(email + ' is not a valid email address.');
          setFormHasError(true);
        }
        return;
      }
    }
    if (field === 'cc') {
      setCcErrorMsg('');
      setFormHasError(false);
    } else {
      setBccErrorMsg('');
      setFormHasError(false);
    }
  };

  const formatInputOptionLabel = (option, { context }) => {
    const inputSourceType = option.source_type || '';
    const iconName = Constants.InputIconsBySource[inputSourceType.toUpperCase()];
    return (
      <div className="recipient-select-option">
        <div className="recipient-select-option-left">
          {iconName && <IconPill iconName={iconName} iconTheme="regular" size="s" color="white" theme="square" />}
          <span className="mls">{option.label}</span>
        </div>
        {context === 'value' && option.value !== 'None' && (
          <Button category="tertiary" onClick={editInputOnClick} id={option.label}>
            <Icon name="pencil" size={16} theme="regular" />
          </Button>
        )}
      </div>
    );
  };

  const changeCcTab = (tabName) => {
    setActiveCcTab(tabName.toLowerCase());
    if (tabName.toLowerCase() === 'static') {
      onDynamicRecipientsChange('ccType', Constants.RecipientFieldTypes.STATIC);
    } else {
      const dynamicCcType =
        startingCcType && startingCcType !== Constants.RecipientFieldTypes.STATIC
          ? startingCcType
          : Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
      onDynamicRecipientsChange('ccType', dynamicCcType);
    }
  };

  const onDynamicCcTypeChange = (option) => {
    onDynamicRecipientsChange('ccType', option.value);
  };

  const onStaticCcChange = (values) => {
    if (!values) {
      onDynamicRecipientsChange('cc', []);
      setCcErrorMsg('');
      setFormHasError(false);
      return;
    }
    const ccAddresses = values.map((value) => value.value);
    checkValidEmails(ccAddresses, 'cc');
    onDynamicRecipientsChange('ccType', Constants.RecipientFieldTypes.STATIC);
    onDynamicRecipientsChange('cc', ccAddresses);
  };

  const toggleCcField = () => {
    if (ccEnabled) {
      resetFieldValues('cc');
    }
    setCcEnabled(!ccEnabled);
  };

  const toggleBccField = () => {
    if (bccEnabled) {
      resetFieldValues('bcc');
    }
    setBccEnabled(!bccEnabled);
  };

  const resetFieldValues = (field) => {
    if (field === 'cc') {
      onDynamicRecipientsChange('ccType', Constants.RecipientFieldTypes.STATIC);
      onDynamicRecipientsChange('cc', []);
    } else {
      onDynamicRecipientsChange('bccType', Constants.RecipientFieldTypes.STATIC);
      onDynamicRecipientsChange('bcc', []);
    }
  };

  const changeBccTab = (tabName) => {
    setActiveBccTab(tabName.toLowerCase());
    if (tabName.toLowerCase() === 'static') {
      onDynamicRecipientsChange('bccType', Constants.RecipientFieldTypes.STATIC);
    } else {
      const dynamicBccType =
        startingBccType && startingBccType !== Constants.RecipientFieldTypes.STATIC
          ? startingBccType
          : Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
      onDynamicRecipientsChange('bccType', dynamicBccType);
    }
  };

  const onDynamicBccTypeChange = (option) => {
    onDynamicRecipientsChange('bccType', option.value);
  };

  const onStaticBccChange = (values) => {
    if (!values) {
      onDynamicRecipientsChange('bcc', []);
      setBccErrorMsg('');
      setFormHasError(false);
      return;
    }
    const bccAddresses = values.map((value) => value.value);
    checkValidEmails(bccAddresses, 'bcc');
    onDynamicRecipientsChange('bccType', Constants.RecipientFieldTypes.STATIC);
    onDynamicRecipientsChange('bcc', bccAddresses);
  };

  let inputOptions = [];
  if (selectedContentInputs) {
    inputOptions = selectedContentInputs.map((input) => ({
      label: input.name,
      value: input.name,
      source_type: input.source_type,
    }));
  }
  inputOptions.push({ label: 'None', value: 'None' });

  const bulkedInputList = selectedContentInputs.filter((input) => input.name === recipients.bulkedInput);
  const startingInputValue = bulkedInputList.length
    ? { label: recipients.bulkedInput, value: recipients.bulkedInput, source_type: bulkedInputList[0].source_type }
    : recipients.bulkedInput === 'None'
    ? { label: 'None', value: 'None' }
    : null;

  const onInputChange = (option) => {
    onDynamicRecipientsChange('bulkedInput', option.value);
  };

  const dynamicRecipientsOptions = recipientDynamicContentList.map((recipientDynamicContent) => ({
    label: recipientDynamicContent.name,
    value: recipientDynamicContent.id.toString(),
  }));

  const startingRecipientsValue = dynamicRecipientsName
    ? { label: dynamicRecipientsName, value: recipients.dynamicRecipients }
    : null;

  let startingDynamicCcTypeValue =
    recipients.ccType === Constants.RecipientFieldTypes.DYNAMIC_BULKED
      ? dynamicCcTypeOptions[0]
      : dynamicCcTypeOptions[1];
  let startingDynamicBccTypeValue =
    recipients.bccType === Constants.RecipientFieldTypes.DYNAMIC_BULKED
      ? dynamicCcTypeOptions[0]
      : dynamicCcTypeOptions[1];

  const getDynamicCcOptions = (field) => {
    const fieldType = field === 'cc' ? recipients.ccType : recipients.bccType;
    if (fieldType === Constants.RecipientFieldTypes.DYNAMIC_BULKED) {
      const recipientContentMatchingInput = recipientDynamicContentList.filter((recipientDynamicContent) => {
        const inputList = Object.values(recipientDynamicContent.parameters);
        return inputList.some((input) => input.name === recipients.bulkedInput);
      });
      return recipientContentMatchingInput.map((recipientDynamicContent) => ({
        label: recipientDynamicContent.name,
        value: recipientDynamicContent.id,
      }));
    } else if (
      fieldType === Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED ||
      fieldType === Constants.RecipientFieldTypes.STATIC
    ) {
      if (fieldType === Constants.RecipientFieldTypes.STATIC) {
        if (field === 'cc') {
          onDynamicCcTypeChange(dynamicCcTypeOptions[1]);
        } else {
          onDynamicBccTypeChange(dynamicCcTypeOptions[1]);
        }
      }
      const recipientContentWithoutMatchingInput = recipientDynamicContentList.filter((recipientDynamicContent) => {
        const inputList = Object.values(recipientDynamicContent.parameters);
        return !inputList.some((input) => input.name === recipients.bulkedInput);
      });
      return recipientContentWithoutMatchingInput.map((recipientDynamicContent) => ({
        label: recipientDynamicContent.name,
        value: recipientDynamicContent.id,
      }));
    }
  };

  const getStartingDynamicCcValue = () => {
    let startingDynamicCcValue;
    if (recipients.ccType !== Constants.RecipientFieldTypes.STATIC && typeof recipients.cc === 'number') {
      const currentOptions = getDynamicCcOptions('cc');
      startingDynamicCcValue =
        currentOptions.filter((content) => content.value === recipients.cc)[0] || currentOptions[0];
    }

    return startingDynamicCcValue;
  };

  let startingStaticCcValue = [];
  if (Array.isArray(recipients.cc)) {
    startingStaticCcValue = recipients.cc.map((address) => ({ label: address, value: address }));
  }

  const getStartingDynamicBccValue = () => {
    let startingDynamicBccValue;
    if (recipients.bccType !== Constants.RecipientFieldTypes.STATIC && typeof recipients.bcc === 'number') {
      const currentOptions = getDynamicCcOptions('bcc');
      startingDynamicBccValue =
        currentOptions.filter((content) => content.value === recipients.bcc)[0] || currentOptions[0];
    }

    return startingDynamicBccValue;
  };

  let startingStaticBccValue = [];
  if (Array.isArray(recipients.bcc)) {
    startingStaticBccValue = recipients.bcc.map((address) => ({ label: address, value: address }));
  }

  const chevronName = showField ? 'chevron_down' : 'chevron_right';
  const headerClass = showField ? '' : 'bottom-radius';

  const statusColor =
    recipients.dynamicRecipients && (!showInputField || recipients.bulkedInput) ? 'green-500' : 'red-500';

  const ccDisabled = !recipients.dynamicRecipients || (showInputField && !recipients.bulkedInput?.length);
  let ccDisabledTooltip = '';
  if (ccDisabled && recipients.dynamicRecipients) {
    ccDisabledTooltip = "Please select the input you'd like to personalize on first.";
  } else if (ccDisabled && !recipients.dynamicRecipients) {
    ccDisabledTooltip = 'Please select your Recipients list first.';
  }

  const MenuListFooter = ({ onClick }) => {
    return (
      <span className="select-dropdown-footer" onClick={onClick}>
        <IconPill iconName="plus" iconTheme="regular" size="xs" theme="square" />
        Create New Dynamic Content
      </span>
    );
  };

  MenuListFooter.propTypes = {
    onClick: PropTypes.func,
  };

  const MenuList = (props) => {
    const { MenuListFooter = null } = props.selectProps.components;

    return (
      <components.MenuList {...props}>
        {props.children}
        {MenuListFooter}
      </components.MenuList>
    );
  };

  MenuList.propTypes = {
    selectProps: PropTypes.object,
    children: PropTypes.array,
  };

  return (
    <>
      <div className={`email-form-field-header ${headerClass}`} onClick={toggleExpandField}>
        <div className="email-form-field-header-left">
          <Indicator color={statusColor} />
          <span className="ml-2">Recipients</span>
        </div>
        <Icon name={chevronName} size={16} theme="filled"></Icon>
      </div>
      {showField && (
        <div className="email-settings-collapsible-field">
          <div className="email-form-sub-field">
            <p className="my-2">To</p>
            <Select
              value={startingRecipientsValue}
              reactSelectRef={selectRef}
              classNamePrefix="matik-select"
              onChange={onRecipientsFieldsChange}
              options={dynamicRecipientsOptions}
              placeholder="Select Dynamic Recipients"
              isSearchable={true}
              openMenuOnClick={false}
              formatOptionLabel={formatRecipientOptionLabel}
              name="dynamic-recipients"
              componentsToAdd={{ MenuList, MenuListFooter: <MenuListFooter onClick={addRecipientContentOnClick} /> }}
            />
          </div>
          {!showInputField && dynamicContentIsLoading && (
            <div className="email-form-sub-field">
              <Skeleton height="2rem" />
            </div>
          )}
          {showInputField && (
            <div className="email-form-sub-field">
              <div
                style={{ width: 'fit-content' }}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="If you select an input, each recipient’s email will be personalized based on the value of that input."
              >
                <p className="email-form-help-text my-2">Select the input to use to personalize your email</p>
              </div>
              <Select
                classNamePrefix="matik-select"
                value={startingInputValue}
                onChange={onInputChange}
                options={inputOptions}
                placeholder="Select Input"
                isSearchable={true}
                openMenuOnClick={false}
                formatOptionLabel={formatInputOptionLabel}
                name="bulked-input"
              />
              {inputErrorMsg && <span className="help is-danger">{inputErrorMsg}</span>}
            </div>
          )}
          <div className="email-form-sub-field">
            <div data-tooltip-content={ccDisabledTooltip} data-tooltip-id="matik-tooltip">
              <div className="email-form-sub-field-header">
                <ToggleSwitchWithLabel
                  size="m"
                  switchState={ccEnabled}
                  onClick={toggleCcField}
                  label="CC"
                  disabled={ccDisabled}
                />
              </div>
            </div>
            {ccEnabled && (
              <>
                <div className="email-form-tabs my-2">
                  <Tabs>
                    <TabItem label="Dynamic" onClick={changeCcTab} isActive={activeCcTab === 'dynamic'} />
                    <TabItem label="Static" onClick={changeCcTab} isActive={activeCcTab === 'static'} />
                  </Tabs>
                </div>
                {activeCcTab === 'dynamic' ? (
                  <>
                    <Select
                      value={startingDynamicCcTypeValue}
                      isDisabled={recipients.bulkedInput === 'None' || !recipients.bulkedInput}
                      classNamePrefix="matik-select"
                      onChange={onDynamicCcTypeChange}
                      options={dynamicCcTypeOptions}
                      isSearchable={false}
                      openMenuOnClick={true}
                      componentsToAdd={{ Menu: MenuNoSearch }}
                      name="cc-type"
                    />
                    <div className="my-2"></div>
                    <Select
                      reactSelectRef={selectRef}
                      value={getStartingDynamicCcValue()}
                      classNamePrefix="matik-select"
                      onChange={onRecipientsFieldsChange}
                      options={getDynamicCcOptions('cc')}
                      placeholder="Select Dynamic Recipients"
                      openMenuOnClick={false}
                      formatOptionLabel={formatRecipientOptionLabel}
                      name="dynamic-cc"
                      componentsToAdd={{
                        MenuList,
                        MenuListFooter: <MenuListFooter onClick={addRecipientContentOnClick} />,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <CreatableInputOnly
                      value={startingStaticCcValue}
                      onChange={onStaticCcChange}
                      classNamePrefix="matik-select"
                      placeholder="Enter CC addresses separated by tab"
                      isMulti={true}
                      name="static-cc"
                    />
                    {ccErrorMsg && <span className="help is-danger">{ccErrorMsg}</span>}
                  </>
                )}
              </>
            )}
          </div>
          <div className="email-form-sub-field">
            <div data-tooltip-content={ccDisabledTooltip} data-tooltip-id="matik-tooltip">
              <div className="email-form-sub-field-header">
                <ToggleSwitchWithLabel
                  size="m"
                  switchState={bccEnabled}
                  onClick={toggleBccField}
                  label="BCC"
                  disabled={ccDisabled}
                />
              </div>
            </div>
            {bccEnabled && (
              <>
                <div className="email-form-tabs my-2">
                  <Tabs>
                    <TabItem label="Dynamic" onClick={changeBccTab} isActive={activeBccTab === 'dynamic'} />
                    <TabItem label="Static" onClick={changeBccTab} isActive={activeBccTab === 'static'} />
                  </Tabs>
                </div>
                {activeBccTab === 'dynamic' ? (
                  <>
                    <Select
                      value={startingDynamicBccTypeValue}
                      isDisabled={recipients.bulkedInput === 'None' || !recipients.bulkedInput}
                      classNamePrefix="matik-select"
                      onChange={onDynamicBccTypeChange}
                      options={dynamicCcTypeOptions}
                      isSearchable={false}
                      openMenuOnClick={true}
                      componentsToAdd={{ Menu: MenuNoSearch }}
                      name="bcc-type"
                    />
                    <div className="my-2"></div>
                    <Select
                      reactSelectRef={selectRef}
                      value={getStartingDynamicBccValue()}
                      classNamePrefix="matik-select"
                      onChange={onRecipientsFieldsChange}
                      options={getDynamicCcOptions('bcc')}
                      placeholder="Select Dynamic Recipients"
                      openMenuOnClick={false}
                      formatOptionLabel={formatRecipientOptionLabel}
                      name="dynamic-bcc"
                      componentsToAdd={{
                        MenuList,
                        MenuListFooter: <MenuListFooter onClick={addRecipientContentOnClick} />,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <CreatableInputOnly
                      value={startingStaticBccValue}
                      onChange={onStaticBccChange}
                      classNamePrefix="matik-select"
                      placeholder="Enter BCC addresses separated by tab"
                      isMulti={true}
                    />
                    {bccErrorMsg && <span className="help is-danger">{bccErrorMsg}</span>}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default EmailRecipientFormField;

EmailRecipientFormField.propTypes = {
  recipients: PropTypes.object,
  allDynamicContent: PropTypes.object,
  dynamicRecipientsName: PropTypes.string,
  setShowDynamicRecipientsField: PropTypes.func,
  onDynamicRecipientsChange: PropTypes.func,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  setFormHasError: PropTypes.func,
  inputErrorMsg: PropTypes.string,
  startingBccType: PropTypes.string,
  startingCcType: PropTypes.string,
};
