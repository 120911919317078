import Constants from 'components/Constants';

export function mapLibrariesStateToProps(state, props) {
  const { libraries } = state;

  const { isFetching, librariesById } = libraries;
  let filteredLibrariesById = {};
  if (props.source_type) {
    const powerpointTypes = [
      Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT,
      Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365,
    ];
    for (let libId in librariesById) {
      let library = librariesById[libId];
      const isSameSourceType = library.source_type === props.source_type;
      const isBothPowerPointTypes =
        powerpointTypes.includes(library.source_type) && powerpointTypes.includes(props.source_type);
      const isValidSourceType = isSameSourceType || isBothPowerPointTypes;
      const isNotSubTemplate = !library.is_sub_template;

      if (isValidSourceType && isNotSubTemplate) {
        filteredLibrariesById[libId] = library;
      }
    }
  } else {
    for (let libId in librariesById) {
      let library = librariesById[libId];
      if (!library.is_sub_template) {
        filteredLibrariesById[libId] = library;
      }
    }
  }

  return {
    isFetching,
    librariesById: filteredLibrariesById,
  };
}
