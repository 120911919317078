import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import { Link, useRouteMatch } from 'react-router-dom';
import { filter } from 'lodash';
import CustomSearchList from '../search/CustomSearchList';
import EmailStatusCell from 'components/shared/presentations/EmailStatusCell';
import utils from 'lib/utils';
import API from 'lib/api';
import Constants from 'components/Constants';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { createColumnHelper } from '@tanstack/react-table';

function RecipientsPanel({ run, fetchCsvData }) {
  const [filteredPresentations, setFilteredPresentations] = useState([]);
  const [fullPresentationList, setFullPresentationList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const match = useRouteMatch();

  useEffect(() => {
    if (run?.id) {
      API.fetchContent(
        `/bulk_presentations/${run.id}/presentations/`,
        () => setIsLoading(true),
        onContentFetch,
        0,
        Constants.PANEL_PAGE_SIZE,
      );
    }
  }, [run?.id]);

  const fetchItems = (page, sort = null) => {
    const offset = page * Constants.PANEL_PAGE_SIZE;
    paginateContent(offset, Constants.PANEL_PAGE_SIZE, sort);
  };

  const paginateContent = (offset, limit = Constants.PANEL_PAGE_SIZE, sort = null) => {
    API.fetchContent(
      `/bulk_presentations/${run.id}/presentations/`,
      () => setIsLoading(true),
      onContentFetch,
      offset,
      limit,
      sort,
    );
  };

  const onContentFetch = (presentations, pagination) => {
    const emailPresentations = presentations.filter(
      (presentation) => presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL,
    );
    setFullPresentationList(emailPresentations);
    // setting state is async so just in case it's slow, pass the email presentations manually to searchRecpiients
    searchRecipients(searchValue, emailPresentations);
    setPagination(pagination);
    setIsLoading(false);
  };

  const emptyInfo = {
    emptyImageUrl: null,
    emptyTitle: 'No emails to display',
  };

  const recipientCell = (cellProps) => (
    <TextOverflowWithTooltip className="text-overflow-ellipsis" text={cellProps.getValue()} />
  );

  const deliveryStatusCell = (cellProps) => {
    let warnings = [];
    let errors = [];
    if (
      cellProps.row.original.status === 'done' ||
      cellProps.row.original.status === 'error' ||
      cellProps.row.original.status === 'failed condition'
    ) {
      warnings = filter(cellProps.row.original.logs, { level: Constants.LOG_LEVEL.warning });
      errors = filter(cellProps.row.original.logs, { level: Constants.LOG_LEVEL.error });
    }

    return <EmailStatusCell presentation={cellProps.row.original} warnings={warnings} errors={errors} leftMargin="" />;
  };

  const openStatusCell = (cellProps) => {
    return cellProps.getValue() > 0 ? (
      <span className="presentation-status-cell">
        <div className="indicator done mrs"></div>
        Opened
      </span>
    ) : (
      '-'
    );
  };

  const clickedStatusCell = (cellProps) => {
    return cellProps.getValue() > 0 ? (
      <span className="presentation-status-cell">
        <div className="indicator done mrs"></div>
        Clicked
      </span>
    ) : (
      '-'
    );
  };

  const unsubscribeStatusCell = (cellProps) => {
    return cellProps.getValue() === true ? (
      <span className="presentation-status-cell">
        <div className="indicator error mrs"></div>
        Unsubscribed
      </span>
    ) : (
      '-'
    );
  };

  const spamStatusCell = (cellProps) => {
    return cellProps.getValue() === true ? (
      <span className="presentation-status-cell">
        <div className="indicator error mrs"></div>
        Reported Spam
      </span>
    ) : (
      '-'
    );
  };

  const openPresentationCell = (cellProps) => {
    if (cellProps.row.original.deleted) {
      return null;
    }
    const linkUrl = match.path.includes('create')
      ? `/create/presentations/${cellProps.getValue()}`
      : `/presentations/${cellProps.getValue()}`;
    return (
      <Link target="_blank" to={linkUrl} className="mll button small square pan is-secondary">
        <Icon name="open" size={16} theme="regular" />
      </Link>
    );
  };

  const onSearchChange = (e) => {
    const searchString = e.target.value;
    searchRecipients(searchString);
  };

  const searchRecipients = (searchString, emailPresentations = []) => {
    const presentationsToSearch = fullPresentationList.length ? fullPresentationList : emailPresentations;
    setSearchValue(searchString);
    if (searchString.length) {
      setFilteredPresentations(
        presentationsToSearch.filter((presentation) =>
          presentation.sendgrid_data?.recipient?.toLowerCase().includes(searchString.toLowerCase()),
        ),
      );
    } else {
      setFilteredPresentations(presentationsToSearch);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.recipient || '-', {
      id: 'recipient',
      header: utils.tableHeader('Recipient'),
      cell: recipientCell,
    }),
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.delivery_status || '-', {
      id: 'delivery_status',
      header: utils.tableHeader('Delivery Status'),
      cell: deliveryStatusCell,
      meta: { className: 'overflow-visible' },
    }),
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.opens || '-', {
      id: 'open_status',
      header: utils.tableHeader('Open Status'),
      cell: openStatusCell,
    }),
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.clicks || '-', {
      id: 'click_status',
      header: utils.tableHeader('Clicked Status'),
      cell: clickedStatusCell,
    }),
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.unsubscribe, {
      id: 'unsubscribe_status',
      header: utils.tableHeader('Unsubscribe Status'),
      cell: unsubscribeStatusCell,
    }),
    columnHelper.accessor((presentation) => presentation.sendgrid_data?.spam_report, {
      id: 'spam_status',
      header: utils.tableHeader('Spam Report Status'),
      cell: spamStatusCell,
    }),
    columnHelper.accessor((presentation) => presentation.id, {
      id: 'open_presentation',
      cell: openPresentationCell,
    }),
  ];

  return (
    <div className="analytics-panel">
      <div className="analytics-panel-title">
        Recipients
        <button className="button is-secondary" type="button" onClick={() => fetchCsvData(run.id)}>
          <Icon name="arrow_download" size={20} theme="regular" />
          <span className="ml-2">Download CSV</span>
        </button>
      </div>
      <div className="mlm">
        <CustomSearchList
          isLoading={isLoading}
          presentations={filteredPresentations}
          elements={filteredPresentations}
          emptyInfo={emptyInfo}
          pagination={pagination}
          searchValue={searchValue}
          columns={columns}
          onSearchChange={onSearchChange}
          fetchItems={fetchItems}
        />
      </div>
    </div>
  );
}

RecipientsPanel.propTypes = {
  run: PropTypes.object,
  fetchCsvData: PropTypes.func,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  entityType: PropTypes.string,
};

export default RecipientsPanel;
