import React from 'react';
import PropTypes from 'prop-types';
import MatikSelect from './MatikSelect';
import Icon from 'components/lib/Icon';

export const storageProviderPropType = PropTypes.shape({
  name: PropTypes.string,
  iconName: PropTypes.string,
});

export const StorageProviders = {
  OFFICE_365: {
    name: 'Office 365',
    iconName: 'office_365_logo',
    value: 'microsoft',
  },
  GOOGLE_DRIVE: {
    name: 'Google Drive',
    iconName: 'google_drive_logo',
    value: 'google',
  },
};

const MatikSelectLogoGenerator = ({ name, iconName }) => {
  return (
    <div className="flex items-center justify-between space-x-2 truncate text-ellipsis">
      <Icon name={iconName} size={16} />
      <div className="w-full flex flex-wrap truncate text-ellipsis">
        <div className="w-full">{name}</div>
      </div>
    </div>
  );
};

MatikSelectLogoGenerator.propTypes = {
  ...storageProviderPropType.propTypes,
};

const MatikStorageProviderSelect = ({ value, onChange, isEnabled = true }) => {
  const selectedValue = Object.values(StorageProviders).find((option) => {
    return option.value === value.source_type;
  });
  const storageProvidersWithLogo = Object.values(StorageProviders).map((option) => {
    const label = <MatikSelectLogoGenerator name={option.name} iconName={option.iconName} />;
    return {
      label,
      value: option,
    };
  });
  return (
    <div className="relative inline-block">
      <MatikSelect
        options={storageProvidersWithLogo}
        value={selectedValue}
        onChange={(option) => onChange(option.value)}
        isSearchBoxShown={false}
        isEnabled={isEnabled}
      />
    </div>
  );
};

MatikStorageProviderSelect.propTypes = {
  value: PropTypes.shape({
    source_type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default MatikStorageProviderSelect;
