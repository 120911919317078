import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import matik_logo_black from 'images/matik_logo_black.svg';
import EmailNarrativeCard from 'components/producer/templates/EmailNarrativeCard';
import PresentationNarrativeCard from 'components/producer/templates/PresentationNarrativeCard';
import ModalTemplateLibrary from 'components/producer/templates/ModalTemplateLibrary';
import { UserContext } from 'components/UserContext';
import DocumentNarrativeCard from 'components/producer/templates/DocumentNarrativeCard';

const AddNarrativeModal = ({
  onEmailNarrativeClick,
  onTemplateAdd,
  templates,
  allTemplatesCount,
  onPresentationNarrativeClick,
  onDocumentNarrativeClick,
  show,
  onClose,
}) => {
  const userContext = useContext(UserContext);
  const { addDocsTemplate, enableMicrosoftWord } = useFlags();
  const emailEnabled = userContext.user?.enterprise?.enterprise_settings?.matik_mail_enabled;

  const headerImage = <img src={matik_logo_black} alt="matik logo" style={{ maxWidth: '100px' }} />;
  let noAccessTemplateCount = templates ? allTemplatesCount - templates.length : 0;
  return (
    <FullScreenModal show={show} onClose={onClose} showDefaultFooter={false} headerCenter={headerImage}>
      <div className="add-template-body">
        <h3>Select your template format</h3>
        <div className="add-template-cards">
          {emailEnabled && <EmailNarrativeCard onEmailNarrativeClick={onEmailNarrativeClick} user={userContext.user} />}
          <PresentationNarrativeCard onPresentationNarrativeClick={onPresentationNarrativeClick} />
          {addDocsTemplate && (
            <DocumentNarrativeCard
              onTemplateAdd={onTemplateAdd}
              isWordEnabled={enableMicrosoftWord}
              onDocumentNarrativeClick={onDocumentNarrativeClick}
            />
          )}
        </div>
        <ModalTemplateLibrary noAccessTemplateCount={noAccessTemplateCount} />
      </div>
    </FullScreenModal>
  );
};

AddNarrativeModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onPresentationNarrativeClick: PropTypes.func,
  onEmailNarrativeClick: PropTypes.func,
  onDocumentNarrativeClick: PropTypes.func,
  templates: PropTypes.array,
  allTemplatesCount: PropTypes.number,
};

export default AddNarrativeModal;
