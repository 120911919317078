import { React, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { each, isEqual } from 'lodash';
// libs
import AccessManager from 'lib/AccessManager';
import dynamicContent from 'lib/dynamicContent';
import InputsList from 'lib/inputsList';
import API from 'lib/api';
// components
import AccessModal from 'components/shared/AccessModal';
import EndUserTemplateHeader from 'components/consumer/EndUserTemplateHeader';
import { MConfirm } from 'components/shared/Alerts';
import SubTemplateModal from 'components/consumer/SubTemplateModal';
import TemplateSlides from 'components/consumer/TemplateSlides';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import TestQueryHandlingInputsForm, {
  findTemplateContentTagsAndInputs,
} from 'components/consumer/TestQueryHandlingInputsForm';
import DataSourceLoggedIn from 'components/consumer/DataSourceLoggedIn';
// contexts
import { EndUserCreateContext } from 'components/consumer/EndUserCreateContext';
import { UserContext } from 'components/UserContext';
import { PresentationContext } from 'components/shared/presentations/PresentationContext';
import { closeModal } from 'redux/ui/action';
import { useTemplateContent, useTemplateMutator } from 'lib/hooks/useTemplate';

function SlidesPresentationCreator({
  template,
  onPresentationCreate,
  accessesByTemplateId,
  userDataSources,
  onTemplateUpdateWithServerCall,
  onUserDataSourceAdd,
  hideHeader,
}) {
  const userContext = useContext(UserContext);
  const presentationContext = useContext(PresentationContext);

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const { deleteSubTemplate, createSubTemplate } = useTemplateMutator();
  const { data: templateContent } = useTemplateContent(template?.id, template?.deleted);

  const getPresentationSlides = (slidesToAdd) => {
    const slidesCopy = template ? [...template.slides] : [];
    for (let slideToAdd of slidesToAdd) {
      slidesCopy.splice(slideToAdd.idx, 0, slideToAdd.slide);
    }
    return slidesCopy;
  };

  // initializations
  let initialSelectedState = {};
  let initialInputValues = {};
  let initialAddedSlides = [];

  if (presentationContext?.regenerate && presentationContext?.presentationToRegenerate) {
    initialInputValues = presentationContext.getInputValues();
    // TODO: pipe in the added slides data from the backend and implement presentationContext function
    // to populate initialAddedSlides
    // https://matik.atlassian.net/browse/MPD-5606
    initialAddedSlides = [];
    initialSelectedState = presentationContext.getSelectedSlides(getPresentationSlides(initialAddedSlides));
  } else {
    // Select all slides
    if (template?.slides) {
      for (let i = 0; i < template?.slides.length; i++) {
        initialSelectedState[i + 1] = true;
      }
    }
    // Seed input values from querystring
    const params = new URLSearchParams(location.search);
    for (let param of params.entries()) {
      initialInputValues[param[0]] = { value: param[1] };
    }
  }

  const [addedSlides, setAddedSlides] = useState(initialAddedSlides);
  const [highlightedSlideNums, setHighlightedSlideNums] = useState([]);
  // TODO (Rishi): Figure out 1) Where the redirect that wipes out the url params is coming from and
  // 2) Why that matters given that we should have mounted and initialized the grandchild PresentationInputsForm
  // before that re-rendered and changed this value.
  const [inputValues] = useState(initialInputValues);
  const [selectedSlidesBySlideNum, setSelectedSlidesBySlideNum] = useState(initialSelectedState);

  const presentationSlides = getPresentationSlides(addedSlides);

  const allDynamicContent = {};
  if (templateContent) {
    dynamicContent.setAllDynamicContentByName(templateContent, allDynamicContent);
  }

  let orderedInputs;
  let bodyMatchingInputs = {};
  if (template) {
    const { inputs } = findTemplateContentTagsAndInputs(
      template,
      templateContent,
      addedSlides,
      allDynamicContent,
      selectedSlidesBySlideNum,
    );
    bodyMatchingInputs = inputs;
    let inputsList = new InputsList(bodyMatchingInputs, template.params_order);
    orderedInputs = inputsList.getSortedList();
  }

  const handlePresentationCreate = (...args) => {
    setAddedSlides([]);
    onPresentationCreate(...args);
  };

  const updateSelectedSlidesOnAdd = (slideCount, insertionCount, insertionIdx) => {
    const updatedSelectedSlidesBySlideNum = {};
    for (let i = 0; i < slideCount; i++) {
      let num = i + 1;
      const selected = !!selectedSlidesBySlideNum[num];
      if (i >= insertionIdx) {
        num += insertionCount;
      }
      updatedSelectedSlidesBySlideNum[num] = selected;
    }
    for (let i = 1; i <= insertionCount; i++) {
      updatedSelectedSlidesBySlideNum[insertionIdx + i] = true;
    }
    setSelectedSlidesBySlideNum(updatedSelectedSlidesBySlideNum);
  };

  const addSlideFromTemplateToCurrentTemplate = (slidesToInsert, idx) => {
    const newAddedSlides = [...addedSlides];
    // Add the slides in ascending order since they get added to the same index.
    slidesToInsert = slidesToInsert.reverse();
    for (let slideToInsert of slidesToInsert) {
      const addSlideObject = {
        template: slideToInsert.template,
        slide: {
          ...slideToInsert.slide,
          isAdded: true,
          template: slideToInsert.template,
          isBulk: false,
        },
        idx,
      };
      newAddedSlides.push(addSlideObject);
    }
    setAddedSlides(newAddedSlides);
    updateSelectedSlidesOnAdd(presentationSlides.length, slidesToInsert.length, idx);
  };

  const updateSelectedSlidesOnRemove = (removalIdx) => {
    const updatedSelectedSlidesBySlideNum = Object.assign({}, selectedSlidesBySlideNum);
    const removeNum = removalIdx + 1;
    for (const key of Object.keys(updatedSelectedSlidesBySlideNum)) {
      const num = parseInt(key);
      if (num === removeNum) {
        delete updatedSelectedSlidesBySlideNum[num];
      } else if (num > removeNum) {
        updatedSelectedSlidesBySlideNum[num - 1] = updatedSelectedSlidesBySlideNum[num];
        delete updatedSelectedSlidesBySlideNum[num];
      }
    }
    setSelectedSlidesBySlideNum(updatedSelectedSlidesBySlideNum);
  };

  const removeSlideFromTemplate = (slideId, templateId) => {
    const updatedAddedSlides = [];
    let removalIdx = -1;
    each(addedSlides, (addedSlideInfo) => {
      if (addedSlideInfo.slide.id !== slideId || addedSlideInfo.template.id !== templateId) {
        updatedAddedSlides.push(addedSlideInfo);
      } else {
        removalIdx = addedSlideInfo.idx;
      }
    });
    updateSelectedSlidesOnRemove(removalIdx);
    setAddedSlides(updatedAddedSlides);
  };

  const toggleAllSlides = (val) => {
    const updatedSelectedSlidesBySlideNum = {};
    for (let num = 1; num <= presentationSlides.length; num++) {
      updatedSelectedSlidesBySlideNum[num] = !!val;
    }
    setSelectedSlidesBySlideNum(updatedSelectedSlidesBySlideNum);
  };

  const toggleSlideNumSelect = (slideNum, selected) => {
    setSelectedSlidesBySlideNum({ ...selectedSlidesBySlideNum, [slideNum]: selected });
  };

  const inputValue = {
    addedSlides: addedSlides,
    addSlideFromTemplateToCurrentTemplate: addSlideFromTemplateToCurrentTemplate,
    highlightedSlideNums: highlightedSlideNums,
    highlightSlideNums: (slideIds) =>
      setHighlightedSlideNums((prevState) => {
        if (isEqual(prevState.sort(), slideIds?.sort())) {
          // Important: react uses object equality to determine if the state has actually changed, so we need
          // to pass prevState back to prevent endless re-rendering
          return prevState;
        } else {
          return slideIds || [];
        }
      }),
    removeSlideFromTemplate: removeSlideFromTemplate,
    toggleAllSlides: toggleAllSlides,
    selectedSlidesBySlideNum: selectedSlidesBySlideNum,
    toggleSlideNumSelect: toggleSlideNumSelect,
  };

  let isAllSelected = true;
  let isAllDeselected = true;
  let selectedCount = 0;
  for (let slideNum in selectedSlidesBySlideNum) {
    if (!selectedSlidesBySlideNum[slideNum]) {
      isAllSelected = false;
    }
    if (selectedSlidesBySlideNum[slideNum]) {
      isAllDeselected = false;
      selectedCount++;
    }
  }

  const accessObj = new AccessManager(template?.id, accessesByTemplateId, userContext.user);
  const canEdit = accessObj.can('edit');

  const handleDeleteSubtemplate = () => {
    MConfirm('Delete Sub-template?', 'Are you sure you want to delete this sub-template?', 'warning', (confirmed) => {
      if (confirmed) {
        deleteSubTemplate(template.id)
          .then(() => {
            history.push('/create/templates');
          })
          .catch(API.defaultError);
      }
    });
  };

  const handleSubTemplateCreate = (data) => {
    return createSubTemplate(template.id, data)
      .then((subTemplate) => {
        dispatch(closeModal());
        history.push(`/create/templates/${subTemplate.id}`);
      })
      .catch(API.defaultError);
  };

  let selectedSlideIdxs = [];
  if (selectedSlidesBySlideNum) {
    for (const num of Object.keys(selectedSlidesBySlideNum)) {
      if (selectedSlidesBySlideNum[num]) {
        selectedSlideIdxs.push(num - 1);
      }
    }
  }
  if (selectedSlideIdxs.length === 0) {
    selectedSlideIdxs = [...presentationSlides.keys()];
  }

  return (
    <>
      {template && (
        <EndUserCreateContext.Provider value={inputValue}>
          {!hideHeader && (
            <EndUserTemplateHeader
              template={template}
              orderedInputs={orderedInputs}
              onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
              canEdit={canEdit}
              isAllSelected={isAllSelected}
              isAllDeselected={isAllDeselected}
              selectedCount={selectedCount}
              accessesByTemplateId={accessesByTemplateId}
              deleteSubTemplate={handleDeleteSubtemplate}
              toggleAllSlides={toggleAllSlides}
            />
          )}
          <div className="consumer-template-main">
            <div className="create-sidebar">
              <DataSourceLoggedIn
                template={template}
                userDataSources={userDataSources}
                onUserDataSourceAdd={onUserDataSourceAdd}
              >
                <TestQueryHandlingInputsForm
                  addedSlides={addedSlides}
                  selectedSlidesBySlideNum={selectedSlidesBySlideNum}
                  template={template}
                  allDynamicContent={allDynamicContent}
                  inputValues={inputValues}
                  onPresentationCreate={handlePresentationCreate}
                />
              </DataSourceLoggedIn>
            </div>
            <div className="create-templates template-slides">
              <TemplateSlides
                currentTemplate={template}
                slides={presentationSlides}
                status={{ status: 'success' }}
                inputs={bodyMatchingInputs}
              />
            </div>
            <SubTemplateModal
              key={template.name}
              addedSlides={addedSlides}
              onSubTemplateCreate={handleSubTemplateCreate}
              slideIdxs={selectedSlideIdxs}
              template={template}
            />
          </div>
          <AccessModal accesses={accessObj} item={template} show={ui.modal?.name === 'accessModal'} />
        </EndUserCreateContext.Provider>
      )}
    </>
  );
}

SlidesPresentationCreator.propTypes = {
  template: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  userDataSources: PropTypes.object,
  match: PropTypes.any,
  accessesByTemplateId: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  hideHeader: PropTypes.bool,
};

export default WithLoadingAndEmpty(SlidesPresentationCreator);
