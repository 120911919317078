import React from 'react';
import PropTypes from 'prop-types';
import MatikSelect from './MatikSelect';
import UserAvatar from 'react-user-avatar';
import Constants from 'components/Constants';
import { isEqual } from 'lodash';

export const avatarPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
});

export const groupPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
});

const MatikSelectAvatarGenerator = ({ name, photoUrl, description }) => {
  return (
    <div className="flex items-center justify-between space-x-2 truncate text-ellipsis">
      <UserAvatar
        name={name}
        src={photoUrl}
        colors={Constants.AVATAR_COLORS}
        size={20}
        className="has-text-white is-uppercase"
      />
      <div className="w-full flex flex-wrap truncate text-ellipsis">
        <div className="w-full">{name}</div>
        <div className="w-full text-grey-600 truncate text-ellipsis">{description}</div>
      </div>
    </div>
  );
};

MatikSelectAvatarGenerator.propTypes = {
  ...avatarPropType.propTypes,
};

const MatikGroupSelect = ({ options, value, onChange, isEnabled = true }) => {
  const optionsWithAvatar = options?.map((option) => {
    // TODO (kay): update MatikSelect and this portion later
    // https://gitlab.com/matik-io/matik-app/-/merge_requests/5882#note_2102885519
    const label =
      option.id === value.id ? (
        <MatikSelectAvatarGenerator name={option.name} photoUrl={option?.photoUrl} description={''} />
      ) : (
        <MatikSelectAvatarGenerator name={option.name} photoUrl={option?.photoUrl} description={option?.description} />
      );
    return {
      label,
      equals: (groupA, groupB) => isEqual(groupA, groupB),
      filter: (query, group) => group.name.toLowerCase().includes(query.toLowerCase()),
      value: option,
    };
  });
  return (
    <div className="relative inline-block">
      <MatikSelect
        options={optionsWithAvatar}
        value={value}
        onChange={(option) => onChange(option.value)}
        isEnabled={isEnabled}
      />
    </div>
  );
};

MatikGroupSelect.propTypes = {
  options: PropTypes.arrayOf(groupPropType),
  value: groupPropType,
  onChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default MatikGroupSelect;
