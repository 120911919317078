import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'lib/api';
import { useAllDynamicContentById } from './useDynamicContent';

/** Fetch the dynamic content that refers to the given input
 */
export function useDynamicContentReferers(inputId) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parameters', 'dynamic_content', inputId],
    queryFn: () => {
      return API.get(
        `/parameters/${inputId}/dynamic_content/`,
        (response) => response.data,
        (err) => {
          API.defaultError(err);
        },
      );
    },
    enabled: !!inputId,
  });

  const { isLoading: isContentLoading, dynamicContentById } = useAllDynamicContentById(!!inputId);

  let hydratedContent = null;
  if (data && dynamicContentById) {
    hydratedContent = data.map((ref) => dynamicContentById[ref.id] || ref);
  }

  const invalidateAll = () => {
    queryClient.invalidateQueries({ queryKey: ['parameters', 'dynamic_content'] });
  };

  return {
    isPending: isLoading || isContentLoading,
    data: hydratedContent,
    isError,
    error,
    invalidateAll,
  };
}
