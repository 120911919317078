import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Form } from 'react-bulma-components';
import WYSIWYGEditor from '../../../shared/WYSIWYGEditor';
import InputWithError from '../../../shared/InputWithError';
import ItemTagMultiSelect from '../../../shared/ItemTagMultiSelect';
import ExplainerButton from '../../../shared/ExplainerButton';
import { UserContext } from '../../../UserContext';

DynamicContentFormMetadata.propTypes = {
  description: PropTypes.string,
  isReadOnly: PropTypes.bool,
  itemTags: PropTypes.arrayOf(PropTypes.object),
  nameError: PropTypes.string,
  name: PropTypes.string,
  query: PropTypes.object,
  onChange: PropTypes.func,
  updateEditor: PropTypes.func,
  updateItemTags: PropTypes.func,
  formRef: PropTypes.object,
  contentOwner: PropTypes.object,
};

function DynamicContentFormMetadata({
  nameError,
  name,
  isReadOnly,
  itemTags,
  onChange,
  query,
  description,
  updateItemTags,
  updateEditor,
  formRef,
  contentOwner,
}) {
  const setDescription = (description) => {
    onChange({ target: { name: 'description', value: description }, preventDefault: () => {} });
  };
  const tooltip = isReadOnly
    ? `You do not have edit access to this dynamic content. You can ask the owner ${
        contentOwner?.name ? '(' + contentOwner.name + ')' : ''
      } to grant you access.`
    : '';

  const userContext = useContext(UserContext);

  return (
    <Box className="is-shadowless pal mtm has-light-gray-border dc-form-main">
      <Form.Field className="pbl mbn">
        <Form.Label htmlFor="dynamic-content-name">Name</Form.Label>
        <Form.Control>
          <InputWithError
            error={nameError}
            type="text"
            name="name"
            value={name.replace(/[ .:|{}(),]/g, '_')}
            onChange={onChange}
            disabled={isReadOnly}
            placeholder="Dynamic content name"
            className="long-input"
            aria-label="Dynamic content name"
            id="dynamic-content-name"
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={tooltip}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field className="pbl mbn">
        <Form.Label htmlFor="dynamic-content-desc">Description</Form.Label>
        <Form.Control data-tooltip-id="matik-tooltip" data-tooltip-content={tooltip}>
          <WYSIWYGEditor
            name="description"
            className="long-input"
            placeholder="Dynamic content description"
            id="dynamic-content-desc"
            ariaLabel="Dynamic content description"
            readOnly={isReadOnly}
            updateEditor={updateEditor}
            value={description}
          />
          {!isReadOnly && !userContext.user?.enterprise?.enterprise_settings?.disable_all_ai && (
            <div className="query-explainer-container">
              <ExplainerButton
                onGenerate={setDescription}
                query={query}
                name={name || 'New Dynamic Content'}
                queryUse="dynamic_content"
              />
            </div>
          )}
        </Form.Control>
      </Form.Field>
      <Form.Field className="pbs mbn">
        <Form.Label htmlFor="dynamic-content-tags">Tags</Form.Label>
        <Form.Control data-tooltip-id="matik-tooltip" data-tooltip-content={tooltip}>
          <ItemTagMultiSelect
            itemTags={itemTags}
            updateItemTags={updateItemTags}
            disabled={isReadOnly}
            formRef={formRef}
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
}

export default DynamicContentFormMetadata;
