import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, Form, Container, Button, Level } from 'react-bulma-components';
import { withRouter, Link } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import FileViewer from './FileViewer';
import MatikConfetti from '../shared/MatikConfetti';
import API from '../../lib/api';
import utils from '../../lib/utils';
import WithLoadingAndEmpty from '../shared/WithLoadingAndEmpty';
import google_slides_icon from '../../images/google_slides_icon.svg';
import google_docs_icon from '../../images/google_docs_icon_20x20.svg';
import ppt_icon from '../../images/ppt_icon.svg';
import word_icon from 'images/microsoft_word_icon_24x24.svg';
import { isEmpty } from 'lodash';
import pdf from '../../lib/pdf';
import PdfDownloadButton from './PdfDownloadButton';
import { UserContext } from '../UserContext';
import Constants from '../Constants';
import paperclip from '../../images/paperclip.svg';
import Banner from 'components/shared/Banner';
import Icon from '../lib/Icon';
import useTemplate from 'lib/hooks/useTemplate';
import conditional_generation_failed from 'images/conditional_generation_failed.svg';

class GeneratedPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameUpdating: false,
      isSendingToHighspot: false,
      presentationName: '',
      activeTab: 'email',
      bannerActive: false,
    };
  }

  componentDidMount() {
    if (!this.props.presentations || this.props.presentations.length === 0) {
      this.props.fetchPresentation(this.props.match.params.presentation_id);
    } else {
      this.setPresentationName();
    }
  }

  componentDidUpdate() {
    if (this.props.presentations.length > 0 && !this.state.presentationName && this.state.presentationName !== '') {
      this.setPresentationName();
    }
  }

  setPresentationName = () => {
    const selectedPresentation = this.props.selectPresentation(this.props.match.params.presentation_id);
    this.setState({ presentationName: selectedPresentation.name });
    if (selectedPresentation.status === 'failed condition') {
      this.setState({ bannerActive: true });
    }
  };

  render() {
    if (this.props.presentations.length === 0) {
      return null;
    }
    const presentation = this.props.selectPresentation(this.props.match.params.presentation_id);
    const attachedPresentation = this.props.selectPresentation(presentation.attached_presentation_id);
    const template = this.props.template;
    const attachedTemplate = this.props.attachedTemplate;

    let openButtonText;
    let openButtonIconSrc;
    let singularTemplateType;
    let templateType;
    switch (template.source_type) {
      case Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES:
        openButtonText = 'Open in Slides';
        openButtonIconSrc = google_slides_icon;
        singularTemplateType = 'a presentation';
        templateType = 'presentation';
        break;
      case Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS:
        openButtonText = 'Open in Docs';
        openButtonIconSrc = google_docs_icon;
        singularTemplateType = 'a document';
        templateType = 'document';
        break;
      case Constants.TEMPLATE_SOURCE_TYPES.EMAIL:
        singularTemplateType = 'an email';
        templateType = 'email';
        break;
      case Constants.TEMPLATE_SOURCE_TYPES.WORD_365:
        openButtonText = 'Open in Microsoft 365';
        openButtonIconSrc = word_icon;
        singularTemplateType = 'a document';
        templateType = 'document';
        break;
      case Constants.TEMPLATE_SOURCE_TYPES.WORD:
        openButtonText = 'Download Word Document';
        openButtonIconSrc = word_icon;
        singularTemplateType = 'a document';
        templateType = 'document';
        break;
      case Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365:
        openButtonText = 'Open in Microsoft 365';
        openButtonIconSrc = ppt_icon;
        singularTemplateType = 'a presentation';
        templateType = 'presentation';
        break;
      default:
        openButtonText = 'Download PowerPoint';
        openButtonIconSrc = ppt_icon;
        singularTemplateType = 'a presentation';
        templateType = 'presentation';
        break;
    }
    const recipientText = presentation?.presentation_file?.to_email
      ? 'to ' + presentation.presentation_file.to_email
      : '';
    const cc_emails = presentation.presentation_file?.cc_emails?.length
      ? presentation.presentation_file.cc_emails.join(', ')
      : '';
    const bcc_emails = presentation.presentation_file?.bcc_emails?.length
      ? presentation.presentation_file.bcc_emails.join(', ')
      : '';

    let title;
    let subtitle;

    let hasHighspotIntegration = false;
    const integrations = this.context.user?.enterprise.integrations ? this.context.user?.enterprise.integrations : [];
    for (let integration of integrations) {
      if (integration.name === 'highspot') {
        hasHighspotIntegration = true;
        break;
      }
    }
    const presentationLink =
      this.props.match.path.indexOf('create') !== -1
        ? `/create/presentations/${presentation.id}`
        : `/presentations/${presentation.id}`;
    const enterpriseSettings = this.context.user?.enterprise?.enterprise_settings;
    const showHighspotButton = enterpriseSettings?.highspot_spot_location && hasHighspotIntegration;

    const emailClass = this.state.activeTab === 'email' ? 'is-active' : '';
    const attachmentClass = this.state.activeTab === 'attachment' ? 'is-active' : '';

    if (this.props.isSchedule) {
      title = 'Scheduled Generation Preview';
      subtitle = (
        <p className="preview-subtitle">
          {`We generated ${singularTemplateType} preview for you. Please check for any errors or inconsistencies before confirming
          the schedule.`}
        </p>
      );
    } else if (this.props.isBulk && presentation.is_test_slide) {
      title = utils.capitalizeString(templateType) + ' Preview';
      subtitle = (
        <p>
          Here is a preview of your email based on the inputs you provided. Click “Send Test Email” to have a copy sent
          to you.
        </p>
      );
    } else if (this.props.isBulk) {
      title = utils.capitalizeString(templateType) + ' Preview';
      subtitle = (
        <p className="preview-subtitle">
          {`We generated ${singularTemplateType} preview for you. Please check if everything is fine before you proceed with generating all the ${templateType}s.`}
        </p>
      );
    } else {
      title = 'Your Presentation is Ready!';
      subtitle = (
        <Form.Field kind="group" className="is-grouped-centered">
          {presentation.status !== 'failed condition' && (
            <Form.Control>
              {presentation.presentation_type == Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ? (
                <button className="is-secondary button" onClick={() => this.downloadPPT(presentation)}>
                  <img src={openButtonIconSrc} alt="Open presentation" />
                  {openButtonText}
                </button>
              ) : (
                <a
                  href={presentation.presentation_file.url}
                  className="is-secondary button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={openButtonIconSrc} alt="Open presentation" />
                  {openButtonText}
                </a>
              )}
            </Form.Control>
          )}
          <Form.Control>
            {!isEmpty(presentation.pdf) && (
              <PdfDownloadButton onClick={() => pdf.downloadPdf(presentation)} text="Download PDF" />
            )}
          </Form.Control>
          <Form.Control>
            <Link to={presentationLink} className="is-secondary button">
              View Presentation
            </Link>
          </Form.Control>
          {this.props.flags.highspotIntegration && showHighspotButton && (
            <Form.Control>
              <Button id="hubspot-button" category="secondary" onClick={(e) => this.sendToHighspot(e, presentation.id)}>
                Send to Highspot
              </Button>
            </Form.Control>
          )}
        </Form.Field>
      );
    }

    const conditionsBannerText =
      templateType === 'email' ? 'Send conditions not met. ' : 'Generation conditions not met. ';
    const conditionsBannerSublineText =
      templateType === 'email'
        ? `This email ${recipientText} won't be sent because it doesn't meet your specified send conditions.`
        : `This ${templateType} wasn't generated because it doesn't meet your specified generation conditions.`;
    return (
      <Container className="complete-container">
        <MatikConfetti />
        {this.state.bannerActive && (
          <Banner
            className="mts mbm partial-width"
            text={conditionsBannerText}
            color="message-info"
            sublineText={conditionsBannerSublineText}
            bannerType="info"
            alignment="center"
          />
        )}
        <Heading size={3}>{title}</Heading>
        {subtitle}
        <div className="generated-file-wrapper">
          {template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? (
            <div>
              <Level.Item>
                <Button onClick={this.toggleTab} className={`email-preview-tab ${emailClass}`}>
                  <Icon name="email" size={20} theme="regular" />
                  Email
                </Button>
                <Button
                  name="attachment"
                  onClick={this.toggleTab}
                  className={`email-preview-tab ${attachmentClass}`}
                  disabled={!template.attached_template_id}
                >
                  <img className="email-preview-tab-icon" src={paperclip} />
                  Attachment
                </Button>
              </Level.Item>
              <Level.Item className="email-preview-header text-left">
                <p className="-indent-8 pl-8">{`From: ${presentation.presentation_file?.from_name} <${presentation.presentation_file?.from_email}>`}</p>
                <p className="-indent-8 pl-8">{`Reply-to: ${presentation.presentation_file?.reply_email}`}</p>
                <p className="-indent-8 pl-8">{`Subject: ${presentation.presentation_file?.parsed_subject}`}</p>
                <p className="-indent-8 pl-8">{`To: ${presentation.presentation_file?.to_email}`}</p>
                <p className="-indent-8 pl-8">{`Cc: ${cc_emails}`}</p>
                <p className="-indent-8 pl-8">{`Bcc: ${bcc_emails}`}</p>
              </Level.Item>
            </div>
          ) : null}
          {presentation.status === 'failed condition' &&
          presentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? (
            <div className="flex flex-col justify-center items-center my-6">
              <img src={conditional_generation_failed} />
              <p className="text-base font-semibold">{`${utils.capitalizeString(templateType)} Not Generated`}</p>
              <p className="text-sm text-grey-600 text-center">{`This ${templateType} was not generated because the specified conditions were not met.`}</p>
            </div>
          ) : (
            <FileViewer
              template={this.state.activeTab === 'attachment' ? attachedTemplate : template}
              presentation={this.state.activeTab === 'attachment' ? attachedPresentation : presentation}
              frameClass={templateType}
            />
          )}
        </div>
        {!this.props.isBulk && presentation.presentation_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
          <Box className="is-shadowless rename-container has-light-gray-border">
            <Heading size={5}>Customize your presentation&apos;s name</Heading>
            <Form.Field kind="group">
              <Form.Control className="is-expanded">
                <input
                  type="text"
                  className="input"
                  name="presentation_name"
                  value={this.state.presentationName}
                  onChange={this.onNameChange}
                />
              </Form.Control>
              <Form.Control>
                <button
                  className={`is-secondary button ${
                    this.state.isNameUpdating || this.state.isSendingToHighspot ? 'is-loading' : ''
                  }`}
                  onClick={this.onUpdateClick}
                >
                  Update Name
                </button>
              </Form.Control>
            </Form.Field>
          </Box>
        )}
      </Container>
    );
  }

  downloadPPT = async (presentation) => {
    const response = await fetch(presentation.presentation_file.url);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url; // eslint-disable-line
    link.download = presentation.presentation_file.original_filename;
    link.click();
  };

  onNameChange = (e) => {
    const presentationName = e.target.value;
    this.setState({ presentationName });
  };

  toggleTab = (e) => {
    this.setState({ activeTab: e.target.name });
  };

  onUpdateClick = () => {
    const presentation = this.props.selectPresentation(this.props.match.params.presentation_id);
    const data = {
      name: this.state.presentationName,
    };
    this.setState({ isNameUpdating: true });
    API.put(
      `/presentations/${presentation.id}/`,
      data,
      () => {
        this.setState({ isNameUpdating: false });
        utils.notify('Successfully updated presentation name.');
      },
      (err) => {
        this.setState({ isNameUpdating: false });
        API.defaultError(err);
      },
    );
  };

  sendToHighspot = (e, presentation_id) => {
    e.preventDefault();
    this.setState({ isSendingToHighspot: true });
    API.post(
      '/enterprise_integrations/highspot/send_presentation_to_spot/',
      { presentation_id },
      () => {
        this.setState({ isSendingToHighspot: false });
        utils.notify('Sent to spot');
      },
      (err) => {
        this.setState({ isSendingToHighspot: false });
        API.defaultError(err);
      },
    );
  };
}

GeneratedPresentation.contextType = UserContext;

GeneratedPresentation.propTypes = {
  fetchPresentation: PropTypes.func,
  flags: PropTypes.object,
  isBulk: PropTypes.bool,
  isSchedule: PropTypes.bool,
  match: PropTypes.object,
  presentations: PropTypes.array,
  selectPresentation: PropTypes.func,
  emptyInfo: PropTypes.object,
  isLoading: PropTypes.bool,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
};

const GeneratedPresentationWrapper = (props) => {
  const { data: template } = useTemplate(props.match?.params?.template_id);
  const { data: attachedTemplate } = useTemplate(template?.attached_template_id);

  // isPending with WithLoadingAndEmpty

  return <GeneratedPresentation {...props} template={template} attachedTemplate={attachedTemplate} />;
};
GeneratedPresentationWrapper.propTypes = {
  match: PropTypes.object,
};

export default withLDConsumer()(withRouter(WithLoadingAndEmpty(GeneratedPresentationWrapper)));
